import { render, staticRenderFns } from "./SubjectAssignDetail.vue?vue&type=template&id=c64e2bb6&"
import script from "../../controllers/subject-assign/subject-assign-detail.js?vue&type=script&lang=js&"
export * from "../../controllers/subject-assign/subject-assign-detail.js?vue&type=script&lang=js&"
import style0 from "./SubjectAssignDetail.vue?vue&type=style&index=0&id=c64e2bb6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports