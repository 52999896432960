import { render, staticRenderFns } from "./DriverDetail.vue?vue&type=template&id=5bc89d3d&"
import script from "../../controllers/driver/driver-detail.js?vue&type=script&lang=js&"
export * from "../../controllers/driver/driver-detail.js?vue&type=script&lang=js&"
import style0 from "./DriverDetail.vue?vue&type=style&index=0&id=5bc89d3d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports