import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";
import {
    required,maxLength
} from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "gradingEdit",
    components: {
        MainLayout,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            isLoading: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            classData: {},
            remark: '',
            selectedClass: 0,
            selectedYear: 0,
            classArr: [],
            subjectArr: [],
            studentNameArr: [],
            academic_year: '',
            class_id: '',
            timingSelected: 0,
            gradingSelected: 0,
            student_id: 0,
            timing_id: 0,
            grading_id: 0,
            subject_id: -1,
            academicYearArr: null,
            gradingArr: null,
            timingArr: null,
            submitted: false,
            gradingInfo: {},
            gradeData: {},
            firstname_ErrMsg: [],
            msgCount: 0,
            comment_msg: [],
            cmt: 0,
            cmtCount: 0,
            subject_ErrMsg: '',
        };
    },

    validations: {
        class_id: {
            required
        },
        academic_year: {
            required
        },
        timing_id: {
            required
        },
        student_id: {
            required
        },
        grading_id: {
            required
        },
        subject_id: {
            required
        },
        remark: {
            maxLength: maxLength(1000)
        },
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getGrading() {
            axios.get('getGrading')
                .then(res => {
                    this.gradingArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getTiming() {
            axios.get('getTiming')
                .then(res => {
                    this.timingArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getAcademicYear() {
            axios.get('getAcademicYear')
                .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data);
                }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                });
        },
        
        changeEvent(val, fieldName) {
            this.submitted = false;
            this.$set(this, fieldName, val);
            if (fieldName == "academic_year") {
                this.isLoading = true;
                this.class_id = 0;
                this.selectedClass = 0;
                this.student_id = '';
                this.timing_id = '';
                this.subject_ErrMsg = '';
                this.timingSelected = 0;
                this.studentNameArr = [];
                this.firstname_ErrMsg = [];
                this.comment_msg = [];
                this.classArr = [];
                this.gradeData.forEach((value, index) => {
                    this.gradeData[index]['grading'] = 0;
                    this.gradeData[index]['grading_comment'] = '';
                });
                this.getClass(val);
            }
            if (fieldName == "class_id") {
                this.isLoading = true;
                this.subject_id = -1;
                this.student_id = '';
                this.timing_id = '';
                this.subject_ErrMsg = '';
                this.timingSelected = 0;
                this.studentNameArr = [];
                this.firstname_ErrMsg = [];
                this.comment_msg = [];
                this.subjectArr = [];
                this.gradeData.forEach((value, index) => {
                    this.gradeData[index]['grading'] = 0;
                    this.gradeData[index]['grading_comment'] = '';
                });
                this.getStudentName(val);
                this.getSubjectByClassId(val);
            }
        },

    changeEventSubject(val, fieldName, index) {
            if (fieldName == 'Please select Grade.' && val != null) {
                this.firstname_ErrMsg[index] = '';
            }
        },

        getGradingDetail() {
            this.isLoading = true;
            axios.get("/grading/detail/" + this.$route.params.student_id, {
                params: {
                    campus_id: store.state.user.campus_id,
                    time: this.$route.params.time
                }
            })
                .then(response => {
                    this.isLoading = true;
                    if (response.data[0] != undefined) {
                        this.gradingInfo = response.data[0];
                        this.gradeData = response.data;
                        this.selectedYear = this.gradingInfo.academic_year;
                        this.timingSelected = this.gradingInfo.time;
                        this.student_id = this.gradingInfo.student_id;
                        this.subject_id = this.gradingInfo.subject_id;
                        this.selectedClass = this.gradingInfo.class_id;
                        this.class_id = this.gradingInfo.class_id;
                        this.timing_id = this.gradingInfo.time;
                        this.academic_year = this.gradingInfo.academic_year;
                        this.gradingSelected = this.gradingInfo.grading;              
                        this.getClass(this.selectedYear);
                        this.getSubjectByClassId(this.selectedClass);
                        this.getStudentName(this.selectedClass);
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    console.log("Grading Detail API Error", error);
                    this.isLoading = false;
                });
        },

        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch(error => {                    
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getStudentName($classId) {
            axios.get("student/getStudentName/" + $classId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Student Name'
                    }];
                    this.studentNameArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                       this.$store.commit('logout');
                       window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getSubjectByClassId($classId) {
            this.isLoading = true;
            axios
                .get("/class/subjectByClassId/" + $classId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then((response) => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'ကျောင်းလုပ်ငန်း'
                    }];
                    this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {                       
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject API Error", error);
                });
        },

        confirmUpdate() {
            this.msgCount = 0;
            this.cmtCount = 0;
            this.submitted = true;
            this.isLoading = true;
            if (this.$v.$invalid || this.class_id == '' || this.class_id == 0 || this.academic_year == '' || this.academic_year == 0 || this.timing_id == '' || this.timing_id == 0  || this.student_id == '' || this.student_id == 0) {
                if (this.gradeData.length > 0) {
                    this.gradeData.forEach((value, index) => {
                        this.cmt = this.gradeData[index]['grading_comment'] ? this.gradeData[index]['grading_comment'].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        if (this.gradeData[index]['grading'] == undefined || this.gradeData[index]['grading'] == 0) {
                            this.firstname_ErrMsg[index] = 'Please select Grade.';
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = '';
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }

                if (this.msgCount > 0 || this.cmtCount > 0 || this.subject_ErrMsg != '') {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = false;
                return;
            } else {
                if (this.gradeData.length > 0) {
                    this.gradeData.forEach((value, index) => {
                        this.cmt = this.gradeData[index]['grading_comment'] ? this.gradeData[index]['grading_comment'].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        if (this.gradeData[index]['grading'] == undefined || this.gradeData[index]['grading'] == 0) {
                            this.firstname_ErrMsg[index] = 'Please select Grade.';
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = "";
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }
                if (this.msgCount > 0 || this.cmtCount > 0) {        
                    this.isLoading = false;
                    return;
                }
            }
            this.update();
        },

        update() {
            var request = {
                academic_year: this.academic_year,
                class_id: this.class_id,
                timing_id: this.timing_id,
                student_id: this.student_id,
                campus_id: this.getUser.campus_id,
                created_id: this.getUser.id,
                gradeData: this.gradeData,
                old_time: this.$route.params.time,
            }           

            axios.post("/grading/update/" + this.$route.params.student_id, request, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    if (response.data.status == 200) {
                        this.$notification.success("Grading Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "gradingList"
                        });
                    }
                    if (response.data.status == 201) {
                        this.isLoading = false;
                        this.$notification.error("Grading Data Already Exist", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {                       
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    console.log("Grading Edit API Error", error.errors);
                    this.isLoading = false;
                });
        },

        showList() {
            this.$router.push({
                path: "/grading/list"
            });
        },
    },

    mounted() {
        // this.isLoading = true;
        this.getAcademicYear();
        this.getTiming();
        this.getGrading();
        this.getGradingDetail();
    },
};
