import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    mapGetters
} from "vuex";

export default {
    name: "SubjectList",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            academicYearArr: null,
            selectedGrade: 0,
            selectedYear: 0,
            subject_id: 0,
            grade_id: 0,
            gradeArr: [],
            subjectArr: [],
            subjectMasterData: {},
        };
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: { 
        getAcademicYear() {
        axios.get('getAcademicYear')
            .then(res => {
            let $defaultSelectOption = [{
                'id': 0,
                'text': 'Select'
            }];
            this.academicYearArr = $defaultSelectOption.concat(res.data);
            }).catch((error) => {
            if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
            }
            this.isLoading = false;
            });
        },
        
        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
            if (fieldName == "grade_id") {
                this.subjectArr = [];
                this.subject_id = 0;
                this.getSubjectBygradeId(val);
            }
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        reset() {
            this.subject_id = 0;
            this.grade_id = 0;
        },

        getAllGrade() {
            axios.get("subject/gradeData", {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                }).then((response) => {
                    let $defaultSelectOption = [{
                        'id': '0',
                        'text': 'Select Grade'
                    }];
                    this.gradeArr = $defaultSelectOption.concat(response.data.gradeArr[0]);
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getAllSubject(page = 1) {
            axios.get('subject/list?page=' + page,
                {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                }
               ).then((response) => {                   
                this.subjectMasterData = response.data;
                this.isLoading = false;
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        getSubjectBygradeId($gradeId) {
            axios.get("/subject/subjectByGradeId/" + $gradeId, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            })
            .then((response) => {
                let $defaultSelectOption = [{
                        'id': '0',
                        'text': 'Select Subject'
                    }
                ];
                this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
                this.isLoading = false;
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                console.log("Subject API Error", error);
            });
        },

        showCreate() {
            this.$router.push({
                path: "/subject/create"
            });
        },

        showDetail(grade_id) {
            this.$router.push({
                name: "subjectDetail",
                params: {
                    grade_id: grade_id
                }
            });
        },

        searchSubject(page = 1) {
            this.isLoading = true;
            axios.get('subject/search?page=' + page, {
                params: {
                    grade_id: this.grade_id,
                    subject_id: this.subject_id,
                    campus_id: store.state.user.campus_id
                }
            })
            .then((res) => {
                this.subjectMasterData = res.data;
                this.isLoading = false;
            }).catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        showEdit(grade_id) {
            this.$router.push({
                name: "subjectEdit",
                params: {
                    grade_id: grade_id
                }
            });
        },

        confirmDelete(grade_id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.deleteSubject(grade_id);
            } else {
                console.log("no");
            }
        },

        deleteSubject(grade_id) {
            this.isLoading = true;
            axios.post("/subject/delete/" + grade_id + '/' + store.state.user.campus_id, {
                campus_id: store.state.user.campus_id
            })
                .then(response => {
                    if (response.data.success == true) {
                        this.getAllGrade();
                        this.getAllSubject();
                    } else {
                        this.$notification.error("Subject Data Already Delete", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.getAllGrade();
                        this.getAllSubject();                        
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject Delete Error->", error.response.data);
                });
        },

        excelDownload() {
            axios.get('/subject/excelDownload', {
                params: {
                    grade_id: this.grade_id,
                    subject_id: this.subject_id,
                    campus_id: store.state.user.campus_id
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `subject_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },
    mounted() {
        this.isLoading = true;
        this.getAllGrade();
        this.getAllSubject();
        this.getAcademicYear();
    },
};
