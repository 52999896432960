import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import {
  maxLength,
  minLength,
  required,
  numeric
} from "vuelidate/lib/validators";

export default {
  name: "campusCreate",
  components: {
    MainLayout,
  },

  data() {
    return {
      campusData: {
        name: '',
        ph1: '',
        ph2: '',
        address: '',
        login_id: '',
        campus_id: this.$store.state.user.campus_id
      },
      submitted: false,
      disabled: false,
    };
  },

  validations: {
    campusData: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      ph1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      ph2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      latitude: {
        maxLength: maxLength(20)
      },
      longitude: {
        maxLength: maxLength(20)
      },
      address: {
        required,
        maxLength: maxLength(1000)
      }
    },
  },

  methods: {
    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.campusData.login_id = this.$store.state.user.id;
      this.disabled = true;
      axios.post('campus/create', this.campusData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Campus Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'campusList'
            })
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

};
