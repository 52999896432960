import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import { mapGetters } from "vuex";

export default {
  name: "subjectAssignList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading,
  },

  data() {
    return {
      name:'',
      class_id:'',
      academic_year: '',
      subject: '',
      classArr:[],
      subjectArr: [],
      selectedClass: 0,
      selectedYear: 0,
      selectedSubject: '',
      assignList:{},
      academicYearArr: null,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      search_status: false,
    };
  },


  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);

      if (fieldName == "class_id") {
        // this.isLoading = true;
        this.getSubjectByClassId(val);
      }

      else if (fieldName == "academic_year") {
        // this.isLoading = true;                 
        this.classArr = [];
        this.subjectArr = [];
        this.subject = '';
        this.class_id = '';
        this.selectedClass = '';
        this.selectedSubject = '';
        this.getClass(val);
    }
    },

      getClass($yearId) {
        axios.get("class/classList/" + $yearId, {
          params: {
              campus_id: this.getUser.campus_id
          }
        })
        .then(res => {
            let $defaultSelectOption = [{
                'id': 0,
                'text': 'Select Class'
            }];
            this.classArr = $defaultSelectOption.concat(res.data);
            this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getSubjectByClassId($classId) {
      axios
        .get("/class/subjectByClassId/" + $classId, {
            params: {
                campus_id: this.getUser.campus_id
            }
        })
        .then((response) => {
            let $defaultSelectOption = [
                {
                'id': '0',
                'text': 'Select Subject'
                }];
            this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
            this.subject = '';
            this.selectedSubject = '';
            this.isLoading = false;
        })
        .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            console.log("Subject API Error", error);
        });
  },

    getSubjectAssignList(page = 1) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/subjectTeacher/list?page=" + page , request)
        .then(response => {
          this.search_status = false;
          this.assignList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance List API Error", error);
        });
    },

    search(page = 1) {
      this.isLoading = true;
      var request = {
        params: {
          name: this.name,
          class_id: this.class_id,
          academic_year: this.academic_year,
          subject: this.subject,
          campus_id: this.getUser.campus_id
        }
      }

      axios.get("/subjectTeacher/search/result?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.assignList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject Assign Search API Error", error);
        });
    },

    reset() {
      this.name = '';
      this.selectedClass  = this.selectedYear = 0;
      this.selectedSubject = '';
      this.class_id = '';
      this.academic_year = '';
      this.subject = '';
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteClass(id);
      } else {
        console.log("no");
      }
    },

    deleteClass(id) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      this.isLoading = true;
      axios.delete("/subjectTeacher/delete/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getSubjectAssignList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject Assign Delete Error->", error.response.data);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/subjectAssign/create"
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "subjectAssignEdit",
        params: {
          id: id
        }
      });
    },

    showDetail(id) {
      this.$router.push({
        name: "subjectAssignDetail",
        params: {
          id: id
        }
      });
    },

    excelDownload() {
      axios.get('/subjectTeacher/excelDownload', {
        params: {
          name: this.name,
          class_id: this.class_id,
          academic_year: this.academic_year,
          subject: this.subject,
          campus_id: this.getUser.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `subject_assign_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getSubjectAssignList();
    this.getAcademicYear();
  },
};
