import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";

export default {
  name: "timetableDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      grade_id: 0,
      class_name: "",
      academic_year: 0,
      timeTableInfo: {},
      day: [
        'Mon', 'Tue', 'Wed', 'Thu', 'Fri'
      ],
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getTimeTableDetail() {
      axios.get("/timetable/detail/" + this.$route.params.id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(response => {
          this.timeTableInfo = response.data.timeTableList;
          this.grade_id = response.data.gradeId;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Class Detail API Error", error);
        });
    },

    getTimeTableClassName() {
      axios.get("/timetable/className/" + this.$route.params.id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(response => {
          this.class_name = response.data.className.class_name;
          this.academic_year = response.data.academicYear.academic_year;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Class Name API Error", error);
        });
    },
     showList() {
       this.$router.push({
         path: "/timetable/list"
       });
     },
  },
  mounted() {
     this.isLoading = true;
     this.getTimeTableDetail();
     this.getTimeTableClassName();
  },
};
