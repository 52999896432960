import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";

export default {
  name: "classList",
  components: {
    MainLayout,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading,
    CONFIG
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      classList: {},
      academicYearArr: null,
      gradeArr: [],
      grade_id: '',
      academic_year: '',
      section: '',
      selectedGrade: 0,
      selectedYear: 0,
      search_status: false
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    searchClass(page = 1) {
      this.isLoading = true;
      var request = {
        params: {
          grade_id: this.grade_id,
          academic_year: this.academic_year,
          section: this.section,
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/class/search/result?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.classList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class Search API Error", error);
        });
    },

    reset() {
      this.selectedGrade = this.selectedYear = 0;
      this.grade_id = '';
      this.academic_year = '';
      this.section = '';
    },

    getClassList(page = 1) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/class/list?page=" + page , request)
        .then(response => {
          this.search_status = false;
          this.classList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class List API Error", error);
        });
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grade", request).then((response) => {
          let $defaultSelectOption = [{
            "id": 0,
            "text": "All"
          }];
          this.gradeArr = $defaultSelectOption.concat(response.data[0]);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      this.checkClass(id);
    },

    checkClass(id) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/class/check/" + id, request)
        .then(response => {
          if (response.data.status == 'alreadyuse') {
            alert(response.data.message);
            return;
          }
          if (response.data.success == true) {
            if (window.confirm("Are you sure you want to delete?")) {
              this.deleteClass(id);
            } else {
              console.log("no");
            }
          }
        })
    },

    deleteClass(id) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.delete("/class/delete/" + id, request)
        .then(response => {         
          if (response.data.success == true) {
            this.isLoading = true;
            this.getClassList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class Delete Error->", error.response.data);
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    showCreate() {
      this.$router.push({
        path: "/class/create"
      });
    },

    showEdit(classId) {
      this.$router.push({
        name: "classEdit",
        params: {
          id: classId
        }
      });
    },

    showDetail(classId) {
      this.$router.push({
        name: "classDetail",
        params: {
          id: classId
        }
      });
    },

    excelDownload() {
      axios.get('/class/excelDownload', {
        params: {
          grade_id: this.grade_id,
          academic_year: this.academic_year,
          section: this.section,
          campus_id: this.getUser.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `class_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },
  mounted() {
    this.isLoading = true;
    this.getAllGrade();
    this.getClassList();
    this.getAcademicYear();
  },
};
