import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import {
  required,
  minLength,
  maxLength,
  sameAs,
  email
} from "vuelidate/lib/validators";
import axios from "axios";
import store from '@/store'

export default {
  name: "adminCreate",
  components: {
    MainLayout,
    Select2Multiple,
  },

  data() {
    return {
      campusName: [],
      adminData: {
        campus_id: this.$store.state.user.campus_id,
        admin_campus_id: '',
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: '',
      },
      adminRole: null,
      submitted: false,
      adminNo: store.state.user.admin_no,
      campusId: store.state.user.campus_master.campus_code,
      disabled: false,
    };
  },

  validations: {
    adminData: {
      admin_campus_id: {
        required
      },
      name: {
        required,
        maxLength: maxLength(100)
      },
      email: {
        maxLength: maxLength(255),
        email
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password')
      },
      role: {
        required
      }
    },
  },

  methods: {
    getAdminRole() {
      axios.get('getAdminRole')
      .then(res => {
        this.adminRole = res.data;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    campusNameAction() {
      if (this.adminNo == '0001') {
        axios.get('campus/campusName', {
          params: {
            admin_id: this.adminNo
          }
        })
        .then(res => {
          this.campusName = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
      }
      else {
        this.campusName = this.$store.state.user.campus_master.campus_name;
        this.adminData.admin_campus_id = this.$store.state.user.campus_master.id;
      }
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid || this.adminData.role == 0 || this.adminData.role == '') {
        return;
      }
      this.disabled = true;
      axios.post('admin/create', this.adminData)
      .then(res => {
        if (res.data.success == true) {
          this.$notification.success("Admin Added Successfully with admin Code: " + res.data[0].admin_no, {
            timer: 20,
            position: "bottomCenter"
          });
          this.$router.push({
            name: "adminList"
          });
        } else {
          this.disabled = false;
          this.$notification.error(res.data.message, {
            timer: 3,
            position: "bottomCenter",
          });
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        this.disabled = false;
        console.log("Admin Create API Error", error.errors);
      });
    }
  },

  mounted() {
    this.getAdminRole();
    this.campusNameAction();
  },
};
