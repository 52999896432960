import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";

export default {
  name: "StudentProfile",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      currMonth: new Date().toLocaleString([], {
        month: 'long',
      }),
      studentInfo: {},
      parentInfo: {},
      latestNoticeInfo: {},
      timetableInfo: {},
      gradingInfo: {},
      attendanceInfo: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      academicYearArr: null,
      religionArr: null,
      annualFeeArr: null,
      monthlyFeeArr: null,
      gradeArr: [],
      subjectArr: [],
      grade_name: '',
      isAdmin: true,
      day: null,
    };
  },

  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },

  methods: {
    getWeekDays() {
      axios.get('getWeekDays')
        .then(res => {
          this.day = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getAnnualFee() {
      axios.get('getAnnualFee')
        .then(res => {
          this.annualFeeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getMonthlyFee() {
      axios.get('getMonthlyFee')
        .then(res => {
          this.monthlyFeeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getStudentDetail() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }

      if (this.$route.params.leavedStatus == 'active') {
        axios.get("/student/profile/" + this.$route.params.id, request)
          .then(response => {
            if (response.data) {
              this.studentInfo = response.data;

              this.parentInfo = response.data.trashed_parent;
              this.getLatestNotice();
              this.getTimetable(this.studentInfo.trashed_class_student.class_id);
              this.getSubject(this.studentInfo.grade);
              this.getGrading();
              this.getAttendanceInfo(this.studentInfo.trashed_class_student.class_id);
              this.getUser.role == 1 ? this.isAdmin = true : this.isAdmin = false;
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            console.log("Active Student Detail API Error", error);
          });
      } else if (this.$route.params.leavedStatus == 'leaved') {
        axios.get("/student/leavedProfile/" + this.$route.params.id, request)
          .then(response => {
            if (response.data) {
              this.studentInfo = response.data;
              this.parentInfo = response.data.trashed_parent;
              this.getLatestNotice();
              this.getTimetable(this.studentInfo.trashed_class_student.class_id);
              this.getSubject(this.studentInfo.grade);
              this.getGrading();
              this.getAttendanceInfo(this.studentInfo.trashed_class_student.class_id);
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }              
            this.isLoading = false;
            console.log("Leaved Student Detail API Error", error);
          });
      }
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/gradeList", request).then((response) => {
          this.gradeArr = response.data[0];
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log('Grade API error', error);
        });
    },

    getLatestNotice() {
      var request = {
        params: {
          class_id: this.studentInfo.trashed_class_student.class_id,
          student_id: this.$route.params.id,
          campus_id: this.getUser.campus_id
        }
      }

      axios.get("/notice/latest" , request)
        .then(response => {
          this.latestNoticeInfo = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }            
          this.isLoading = false;
          console.log("Latest Notice API Error", error);
        });
    },

    getGrading() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grading/list/" + this.$route.params.id , request)
        .then(response => {
          this.gradingInfo = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }           
          this.isLoading = false;
          console.log("Grading API Error", error);
        });
    },

    getAttendanceInfo($classId) {
      var request = {
        params: {
          student_id: this.$route.params.id,
          campus_id: this.getUser.campus_id,
          class_id: $classId
        }
      }
      axios.get("/studentAttendance/info", request)
      .then(response => {
        this.attendanceInfo = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Student Attendance API Error", error);
      });
    },

    getTimetable($classId) {
      axios.get("/timetable/detail/" + $classId, {
        params: {
          campus_id: this.getUser.campus_id
        }
      })
        .then(response => {
          this.timetableInfo = response.data.timeTableList;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Timetable API Error", error);
        });
    },

    getSubject($gradeId) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/timetable/subjectBygrade/" + $gradeId, request)
        .then(response => {
          this.subjectArr = response.data[0];
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'ကျောင်းလုပ်ငန်း'
          }];
          this.subjectArr = this.subjectArr.concat($defaultSelectOption);
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }            
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },

    confirmDelete(id, parentId) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteStudent(id, parentId);
      }
    },

    deleteStudent(id, parentId) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.delete("/student/delete/" + id + '/' + parentId, request)
        .then(response => {
          if (response.data.success == true) {
            this.showList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Delete Error->", error);
        });
    },

    confirmActivate(id, parentId) {
      if (window.confirm("Are you sure you want to activate?")) {
        this.activateStudent(id, parentId);
      }
    },

    activateStudent(id, parentId) {
      axios.delete("/student/activate/" + id + '/' + parentId)
        .then(response => {
          if (response.data.success == true) {
            this.showList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Activate Error->", error);
        });
    },

    showList() {
      this.$router.push({
        path: "/student/list"
      });
    },

    back() {
      this.$router.go(-1);
    },

    showNoticeList() {
      this.$router.push({
        path: "/notice/list"
      });
    },

    showAddPayment() {
      this.$router.push({
        name: "paymentCreate"
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "studentEdit",
        params: {
          id: id
        }
      });
    },

    showAttendanceDetail() {
      this.$router.push({
        name: "attendanceStudentDetail"
      });
    },

    showEditToPass(id) {
      this.$router.push({
        name: "studentPass",
        params: {
          id: id
        }
      });
    },

    showEditToFail(id) {
      this.$router.push({
        name: "studentFail",
        params: {
          id: id
        }
      });
    },

    downloadPdfForQrCode() {     
      this.gradeArr.forEach((value) => {
        if (value.id == this.studentInfo.grade) {
          this.grade_name = value.text;
        }
      });
      axios.get("/student/downloadPdf/" + this.$route.params.id, {
          params: {
            campus_id: this.getUser.campus_id,
            student_name: this.studentInfo.name,
            father_name: this.parentInfo.father_name,
            class_name: this.studentInfo.trashed_class_student.trashed_class.section,
            grade_name: this.grade_name
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob' // responseType is a sibling of headers, not a child
        })
        .then(response => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var currentDate = new Date();
            const fileName = `qrcode_${this.studentInfo.name}_${currentDate.getFullYear()}${(currentDate.getMonth() + 1) > 9 ? '' : '0'}${currentDate.getMonth()+1}${(currentDate.getDate()) > 9 ? '' : '0'}${currentDate.getDate()}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })     
    },
    downloadPdfForStudentInfo() {
      axios.get("/student/downloadPdfForStudentInfo/" + this.$route.params.id + '/' + this.studentInfo.trashed_class_student.academic_year + '/' + this.getUser.campus_id, {
          params: {
            campus_id: this.getUser.campus_id
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob' // responseType is a sibling of headers, not a child
        })
        .then(response => {
          if (response.status == 200) {
            var currentDate = new Date();
            const fileName = `reportcard_${this.studentInfo.name}_${currentDate.getFullYear()}${(currentDate.getMonth() + 1) > 9 ? '' : '0'}${currentDate.getMonth()+1}${(currentDate.getDate()) > 9 ? '' : '0'}${currentDate.getDate()}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    }
  },

  mounted() {
    this.isLoading = true;
    this.getWeekDays();
    this.getAllGrade();
    this.getStudentDetail();
    this.getAnnualFee();
    this.getMonthlyFee();
    this.getReligion();
    this.getAcademicYear();
  },
};
