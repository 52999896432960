import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";

export default {
  name: "classDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      classInfo: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      gradeArr: [],
      subjectAndTeacherArr: {},
      academicYearArr: null,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getClassDetail() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/class/detail/" + this.$route.params.id, request)
        .then(response => {
          this.classInfo = response.data;
          this.getSubjectBySubjectIds(this.classInfo.subject_id);
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Class Detail API Error", error);
        });
    },
    
    getSubjectBySubjectIds($subjectIds) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id,
          subject_ids: $subjectIds,
          class_id: this.$route.params.id
        }
      }
      axios.get("/subject/getSubjectAndTeacherBySubjectIdsAndClass", request)
        .then(response => {
          this.subjectAndTeacherArr = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/gradeList", request).then((response) => {
          this.gradeArr = response.data[0];
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    showList() {
      this.$router.push({
        path: "/class/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAllGrade();
    this.getClassDetail();
    this.getAcademicYear();
  },
};
