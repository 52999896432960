import Vuex from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: [],
        campus: [],
        studentNo: '',
    },

    mutations: {
        storeUser(state, userData) {
            state.user = userData;
        },
        storeToken(state, userToken) {
            state.user.token = userToken;
        },
        showCampus(state, campusData) {
            state.campus = campusData;
        },
        logout(state) {
            state.user = [];
        },
        setStudentNo(state, studentNo) {
            state.studentNo = studentNo;
        },
    },

    actions: {
        login({
            commit
        }, credentials) {
            return axios.post("login", credentials).then(({
                data
            }) => {
                commit("storeUser", data.account);
                commit("storeToken", data.token);
            });
        },
        async campusAction({
            commit
        }) {
            let res = await axios.get('campus/getAll');
            commit('showCampus', res.data);
        },
        loginByBtn({ commit }, credentials) {
            return axios.post('admin/login', credentials)
            .then( ({ data }) => {
                commit("storeUser", data.data);
                commit("storeToken", data.token);
            });
        },
    },

    getters: {
        getUser(state) {
            return state.user;
        },

        studentNo: (state) => {
            if (state.studentNo) {
                return state.studentNo;
            } else {
                return null;
            }
        },
    },

    plugins: [createPersistedState()],

})
