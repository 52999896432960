import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters } from "vuex";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "TeacherProfile",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      teacherData: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      religionArr: null,
      academicYearArr: null,
      academic_year_a:'',
      hasSubject: false,
      subjectArr:[],
      attendanceInfo: {},
      courseInfo: {},
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTeacherDetail() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/teacher/detail/" + this.$route.params.id, request)
        .then((response) => {
          this.teacherData = response.data;
          this.selectedTeacherType = this.teacher_type =
            this.teacherData.teacher_type;
          this.teacherData.religion != null
            ? (this.selectedReligion = this.religion =
                this.teacherData.religion)
            : null;
          this.getCourseInfo();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Detail API Error", error);
        });
    },

    getSubjectNameList() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/timetable/subjectNameList/" + this.$route.params.id, request)
        .then(response => {
          this.subjectArr = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },

    getAttendanceInfo() {
      var request = {
        params: {
          teacher_id: this.$route.params.id,
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/teacherAttendance/info", request)
      .then(response => {
        this.attendanceInfo = response.data;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Attendance API Error", error);
      });
    },

    getCourseInfo() {
      var request = {
        params: {
          teacher_id: this.$route.params.id,
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/teacher/course/" + this.$route.params.id, request)
      .then(response => {
        if (response.data.length > 0) {
          this.hasSubject = true;
          this.courseInfo = response.data;
        }
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Course API Error", error);
      });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteTeacher(id);
      }
    },

    deleteTeacher(id) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.delete("/teacher/delete/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.showList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Teacher Delete Error->", error);
        });
    },

    confirmActivate(id) {
      if (window.confirm("Are you sure you want to activate?")) {
        this.activateTeacher(id);
      }
    },

    activateTeacher(id) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.delete("/teacher/activate/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.showList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Teacher Activate Error->", error);
        });
    },

    showList() {
      this.$router.push({
        path: "/teacher/list",
      });
    },

    back() {
      this.$router.go(-1);
    },

    showEdit(id) {
      this.$router.push({
        name: "teacherEdit",
        params: {
          id: id,
        },
      });
    },

    downloadPdfForQrCode() {
      axios.get("/teacher/downloadPdf/" + this.$route.params.id, {
          params: {
            campus_id: this.getUser.campus_id,
            t_name: this.teacherData.name,
            t_father_name: this.teacherData.father_name
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob' // responseType is a sibling of headers, not a child
        })
        .then(response => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
             var currentDate = new Date();
             const fileName = `qrcode_${this.teacherData.name}_${currentDate.getFullYear()}${(currentDate.getMonth() + 1) > 9 ? '' : '0'}${currentDate.getMonth()+1}${(currentDate.getDate()) > 9 ? '' : '0'}${currentDate.getDate()}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        })
    }
  },
  mounted() {
    this.isLoading = true;
    this.getSubjectNameList();
    this.getAttendanceInfo();
    this.getTeacherDetail();
    this.getReligion();
    this.getAcademicYear();
  },
};
