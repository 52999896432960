import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex";

export default {
    name: "SubjectDetail",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_EXIST,
            isLoading: false,
            search_status: false,
            tmpResult: false,
            academicYearArr: null,
            selectedGrade: 0,
            gradeArr: [],
            grade_id: 0,
            remark: '',
            old_grade_id: 0,
            grade_name: '',
            inputData: {
                subject_id: 0,
                grade_id: '',
                subject_name: '',
                description: '',
            },
            hidden: false,
            subjectInfo: []
        };
    },

    validations: {
        inputData: {
            grade_id: {
                required
            },
            subject_name: {
                required
            },
            description: {
                maxLength: maxLength(100)
            },
        }
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getAcademicYear() {
            axios.get('getAcademicYear')
                .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data);
                }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                });
        },
        
        getAllGrade() {
            this.tmpResult = false;
            axios.get("subject/gradeData", {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                }).then((response) => {
                    let $defaultSelectOption = [{
                        "id": 0,
                        "text": "Select Grade"
                    }];
                    this.gradeArr = $defaultSelectOption.concat(response.data.gradeArr[0]);
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getAllSubjectByGradeId() {
            this.isLoading = true;
            axios.get("subject/get-subject", {
                    params: {
                        campus_id: store.state.user.campus_id,
                        grade_id: this.$route.params.grade_id
                    }
                }).then((response) => {
                    if (response.data[0] != undefined) {
                        this.subjectInfo = response.data;
                        this.inputData.grade_id = this.subjectInfo[0].grade_id;
                        this.grade_name = this.subjectInfo[0].grade_name;                        
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        showList() {
            this.$router.push({
                path: "/subject/list"
            });
        },
    },
    mounted() {
        this.getAllGrade();
        this.getAllSubjectByGradeId();
        this.getAcademicYear();
    },
};
