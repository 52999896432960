import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import upLoad from '@/assets/img/upload_photo3.jpg';
import store from "@/store";
import {
  required,
  maxLength,
  integer
} from "vuelidate/lib/validators";

export default {
  name: "inOutcomeEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      inoutData: {
        title: '',
        academic_year: '',
        payment_method: '',
        payment_date: '',
        received_amt: '',
        paid_amt: '',
        receiver: '',
        payer: '',
        payment_img: '',
        remark: '',
        removeStatus: false
      },
      year: '',
      status: '',
      academicYearArr: null,
      paymentMethodArr: null,
      isLoading: false,
      submitted: false,
      upLoad,
      ssImage: '',
      campusId: store.state.user.campus_id,
      receivedAmtValidate: false,
      receiverValidate: false,
      paidValidate: false,
      payerValidate: false,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      received_amt: '',
      paid_amt: '',
    };
  },

  validations: {
    inoutData: {
      title: {
        required,
        maxLength: maxLength(100)
      },
      payment_method: {
        required
      },
      payment_date: {
        required
      },
      receiver: {
        maxLength: maxLength(100)
      },
      payer: {
        maxLength: maxLength(100)
      },
      academic_year: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      }
    },
    received_amt: {
      integer,
      maxLength: maxLength(9)
    },
    paid_amt: {
      integer,
      maxLength: maxLength(9)
    }
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    removeImg() {
      this.ssImage = '';
      document.getElementById('image').value = '';
      if (this.inoutData.img_path != null) {
        this.inoutData.removeStatus = true;
        this.inoutData.img_path = '';
      }
    },

    getDetail() {
      this.status = this.$route.params.status;
      var request = {
        params: {
          status : this.status,
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get(`inoutcome/detail/${this.$route.params.id}`, request)
        .then(res => {
          if (res.data) {
            this.inoutData = res.data;
            if (this.inoutData.received_amt != null) {
              this.received_amt = this.inoutData.received_amt;
            } else {
              this.paid_amt = this.inoutData.paid_amt;
            }
          }
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("InOutCome Detail API Error", error);
        })
    },

    imgUpload(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return null;
      } else {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = (e) => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.ssImage = e.target.result;
            }
          }
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid ||this.inoutData.academic_year == '' || this.inoutData.academic_year == 0) {
        return;
      }

      if(this.status == 'income') {
        if (this.received_amt == '') {
          this.receivedAmtValidate = true;
        } else this.receivedAmtValidate = false;
        if (!this.inoutData.receiver) {
          this.receiverValidate = true;
        } else this.receiverValidate = false;
      } else {
        if (!this.paid_amt) {
          this.paidValidate = true;
        } else this.paidValidate = false;
        if (!this.inoutData.payer) {
          this.payerValidate = true;
        } else this.payerValidate = false;
      }
      if(this.receivedAmtValidate == true || this.receiverValidate == true || this.paidValidate == true || this.payerValidate == true) {
        return;
      }

      if (this.ssImage) {
        this.inoutData.payment_img = this.ssImage;
        this.inoutData.removeStatus = false;
      }
      this.inoutData.updated_id = this.$store.state.user.id;
      this.inoutData.status = this.status;
      if (this.received_amt != '') {
        this.inoutData.received_amt = this.received_amt;
      } else {
        this.inoutData.paid_amt = this.paid_amt;
      }
      this.disabled = true;
      axios.post(`inoutcome/update/${this.$route.params.id}`, this.inoutData)
      .then(res => {
        if (res.data) {
          this.$notification.success("InOutCome Updated Successfully", {
            timer: 3,
            position: "bottomCenter"
          });
          this.$router.push({
            name: "inOutcomeList"
          });
        }
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    }
  },
  
  mounted() {
    this.isLoading = true;
    this.getDetail();
    this.getPaymentMethod();
    this.getAcademicYear();
  },
};
