import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { mapGetters } from "vuex";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "subjectAssignList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      disabled: false,
      teacher: "",
      grade: "",
      section: "",
      subject: "",
      subjectTeacherInfo: {},
      teacherArr: [],
      gradeArr: [],
      sectionArr: [],
      subjectArr:[],
      selectedTeacher: 0,
      selectedGrade:0,
      selectedSection:0,
      selectedSubject: "",
      noDataMsg: CONFIG.NO_DATA_EXIST,
      section_exist: '',
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    changeEvent(val, fieldName) {
        this.$set(this, fieldName, val);

        if(fieldName == 'subject' && val.length == 0){
            this.$set(this, fieldName, 0);
        }
        
        if (fieldName == "grade") {
          // this.isLoading = true;
          this.getSection(val, 0);
          this.getSubject(val, 0);
        }

        if (fieldName == "section") {
          this.section_exist = '';
        }
      },

    getAllTeacher() {
        var request = {
          params: {
            campus_id: this.getUser.campus_id
          }
        }
        axios
          .get("/teacher/getallTeacher", request)
          .then((response) => {
            let $defaultSelectOption = [
              {
                id: 0,
                text: "Select Teacher Name",
              },
            ];
            this.teacherArr = $defaultSelectOption.concat(response.data[0]);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
          });
      },
  
      getAllGrade() {
        var request = {
          params: {
            campus_id: this.getUser.campus_id
          }
        }
        axios
          .get("/grade", request)
          .then((response) => {
            let $defaultSelectOption = [
              {
                id: 0,
                text: "Select",
              },
            ];
            this.gradeArr = $defaultSelectOption.concat(response.data[0]);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
          });
      },

      getSubjectTeacherData () {
        var request = {
          params: {
            id: this.$route.params.id,
            campus_id: this.getUser.campus_id
          }
        }
        
        axios.get("/subjectTeacher/detail", request)
          .then(response => {
            this.subjectTeacherInfo = response.data;
            this.selectedTeacher = this.teacher = this.subjectTeacherInfo.teacher_id;
            this.selectedGrade = this.grade = this.subjectTeacherInfo.trashed_section.grade_id;
            this.selectedSection = this.section = this.subjectTeacherInfo.class_id;
            this.selectedSubject = this.subject = this.subjectTeacherInfo.subject_id;
            this.getSection(this.grade, this.selectedSection);
            this.getSubject(this.grade, this.selectedSubject);
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            console.log("Subject Assign Detail API Error", error);
          });
    },

    getSection($gradeId, $selectedId) {
        var request = {
          params: {
            campus_id: this.getUser.campus_id
          }
        }
  
        axios
          .get("/class/getSection/" + $gradeId, request)
          .then((response) => {
            if (response.data[0].length > 0) {
              this.sectionArr = [
                {
                  id: 0,
                  text: "Choose Section",
                },
              ].concat(response.data[0]);
              this.selectedSection = this.section = $selectedId;
            } else {
              this.sectionArr = [
                {
                  id: 0,
                  text: "Choose Section",
                },
              ];
              this.selectedSection = this.section = 0;
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            console.log("Section API Error", error);
          });
      },

      getSubject($gradeId, $selectedId) {
        var request = {
          params: {
            campus_id: this.getUser.campus_id
          }
        }
        axios
            .get("/timetable/subjectBygrade/" + $gradeId, request)
            .then((response) => {
                let $defaultSelectOption = [{
                  'id': 0,
                  'text': 'Select Subject'
                }];
                this.subjectArr = $defaultSelectOption.concat(response.data[0]);
                this.selectedSubject = this.subject = $selectedId;
                this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.isLoading = false;
              console.log("Subject API Error", error);
            });
      },

    confirmUpdate(){
      this.submitted = true;
      if (this.teacher == 0 ||
        this.teacher == "" ||
        this.subject == 0 ||
        this.subject == "" ||
        this.section == 0 ||
        this.section == "" ) {
        return;
      }
      this.update();
    },

    update() {
      this.disabled = true;
      var request = {
        id: this.$route.params.id,
        teacher: this.teacher,
        grade: this.grade,
        section: this.section,
        subject: this.subject,
        campus_id: this.getUser.campus_id,
        updated_id: this.getUser.id
      }

      axios.post("/subjectTeacher/update", request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.status == 204) {
            this.submitted = false;
            this.disabled = false;
            this.getSection(this.grade, 0);
            this.section_exist = "Section doesn't exist. Please choose other section.";
            return;
          }
          if (response.data.success == true) {
            this.$notification.success("Subject Assign Updated Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          console.log("Subject Assign Update API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/subjectAssign/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAllTeacher();
    this.getAllGrade();
    this.getSubjectTeacherData();
  },
};
