import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import store from '../../store';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CONFIG from "@/assets/js/config.js";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import {
  mapGetters
} from "vuex";

export default {
  name: "timetableList",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
    AdvancedLaravelVuePaginate,
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      search_status: false,
      selectedClass: 0,
      selectedYear: 0,
      classArr: [],
      academicYearArr: null,
      timeTableList: {},
      class_id: "",
      academic_year: "",
      year: '',
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
      changeEvent(val, fieldName) {
        this.$set(this, fieldName, val);
        if (fieldName == "academic_year") {
          this.class_id = '';
          this.getClass(val);
        }
      },
      showCreate() {
        this.$router.push({
          path: "/timetable/create"
        });
      },

      getClass($yearId) {
        axios.get("class/classList/" + $yearId, {
            params: {
              campus_id: store.state.user.campus_id
            }
          })
          .then(res => {
            let $defaultSelectOption = [{
              'id': 0,
              'text': 'Select Class'
            }];
            this.search_status = false;
            this.isLoading = false;
            this.classArr = $defaultSelectOption.concat(res.data);
          }).catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
          });
      },

      selectEvent({
        id
      }, fieldName) {
        id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
      },

      getTimeTableList(page = 1) {
        var request = {
          params: {
            campus_id: this.getUser.campus_id
          }
        }
        axios.get("/timetable/list?page=" + page, request)
          .then(response => {
            this.search_status = false;
            this.timeTableList = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            console.log("TimeTable List API Error", error);
          });
      },

      reset() {
        this.selectedClass = 0;
        this.class_id = '';
        this.selectedYear = this.academic_year = '';
        this.classArr = [];
      },

      searchTimeTable (page = 1) {
        this.isLoading = true;
        var request = {
          params: {
            academic_year: this.academic_year,
            class_id: this.class_id,
            campus_id: this.getUser.campus_id
          },
        };
        axios
          .get("/timetable/search?page=" + page, request)
          .then((response) => {
            this.search_status = true;
            this.timeTableList = response.data.timeTableList;
            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            console.log("Time Table Search API Error", error);
          });
      },

      showDetail(id) {
        this.$router.push({
          name: "timetableDetail",
          params: {
            id: id
          }
        });
      },

      showEdit(id) {
        this.$router.push({
          name: "timetableEdit",
          params: {
            id: id
          }
        });
      },

      confirmDelete(id) {
        if (window.confirm("Are you sure you want to delete?")) {
          this.deleteTimeTable(id);
        } else {
          console.log("no");
        }
      },

      deleteTimeTable(id) {
        this.isLoading = true;
        axios.delete("/timetable/delete/" + id, {
          params: {
            campus_id: store.state.user.campus_id
          }
        })
        .then(response => {
          if (response.data.success == true) {            
            this.getTimeTableList();
          } else {
              this.$notification.error("Time Table Data Already Delete", {
                timer: 3,
                position: "bottomCenter"
              });
              this.$router.push({
                name: "timetableList"
              });
              this.getTimeTableList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("TimeTable Delete Error->", error.response.data);
        });
      },

    excelDownload() {
      axios.get('/timetable/excelDownload', {
        params: {
          academic_year: this.academic_year,
          class_id: this.class_id,
          campus_id: this.getUser.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `timetable_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getTimeTableList();
    this.getAcademicYear();
  },
};
