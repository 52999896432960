import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "dailyFerryList",
  components: {
    MainLayout,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    flatPickr,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      searchData: {},
      dFData: {},
      routeArr: null,
      total: null,
      isLoading: false,
      search_status: false,
      currentDate: null
    };
  },

  methods: {
    getDailyFerry(page = 1) {
      axios.get('dailyFerry/list?page=' + page, { params: { campus_id: this.$store.state.user.campus_id } })
        .then(res => {
          if (res.data) {
            this.dFData = res.data;
            this.total = res.data.data.length;
            this.currentDate = new Date().toISOString().slice(0, 10);
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getRoute() {
      axios.get('route/getRouteForRoutePoint', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.routeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = {}
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('dailyFerry/search?page=' + page, {
        params: {
          route_id: this.searchData.route_id,
          date: this.searchData.date,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.isLoading = false;
          if (res.data) {
            this.dFData = res.data;
            this.total = res.data.data.length;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      axios.get('/dailyFerry/excelDownload', {
        params: {
          route_id: this.searchData.route_id,
          date: this.searchData.date,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `daily_ferry_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.delete(`dailyFerry/delete/${id}`)
        .then(res => {
          if (res.data.success == true)
            this.getDailyFerry();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

  },

  mounted() {   
    this.isLoading = true;
    this.getDailyFerry();
    this.getRoute();
  },
};
