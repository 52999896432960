import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    mapGetters
} from "vuex";

export default {
    name: "GradeDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_EXIST,
            isLoading: false,
            grade_name: '',
            campus_name: '',
            grade_id: '',
            gradeData: {}
        };
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getGradeById() {
            axios.get('grade/detail/' + this.$route.params.id,  {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                }).then((response) => {
                    if (response.data[0] != undefined) {
                        this.grade_id = response.data[0].grade_id;
                        this.grade_name = response.data[0].grade_name;
                        this.campus_name = response.data[0].campus_name;
                        this.gradeData = response.data[0];
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        showList() {
            this.$router.push({
                path: "/grade/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getGradeById();
    },
};
