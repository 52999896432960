import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import store from '../../store';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";

import {
  required
} from "vuelidate/lib/validators";


export default {
  name: "timetableEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      submitted: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      grade_id: 0,
      selectedClass: 0,
      selectedYear: 0,
      msgCount: 0,
      count: 0,
      isLoading: false,
      academic_year: 0,
      class_id: 0,
      timeTableInfo: {},
      subjectArr: [],
      classArr: [],
      class_name_required: '',
      academic_year_required: '',
      subjectCount: [
        1, 2, 3, 4, 5, 6, 7
      ],
      subjectCount1: [
        1, 2, 3, 4, 5, 6
      ],
       day: [
         'Mon', 'Tue', 'Wed', 'Thu', 'Fri'
       ],
      academicYearArr: null,
      firstname_ErrMsg: [
        [],
        [],
        [],
        [],
        [],
      ],
      subject: [{
          id: 0,
          subject1: 0,
          subject2: 0,
          subject3: 0,
          subject4: 0,
          subject5: 0,
          subject6: 0,
          subject7: 0
        },
        {
          id: 0,
          subject1: 0,
          subject2: 0,
          subject3: 0,
          subject4: 0,
          subject5: 0,
          subject6: 0,
          subject7: 0
        },
        {
          id: 0,
          subject1: 0,
          subject2: 0,
          subject3: 0,
          subject4: 0,
          subject5: 0,
          subject6: 0,
          subject7: 0
        },
        {
          id: 0,
          subject1: 0,
          subject2: 0,
          subject3: 0,
          subject4: 0,
          subject5: 0,
          subject6: 0,
          subject7: 0
        },
        {
          subject1: 0,
          subject2: 0,
          subject3: 0,
          subject4: 0,
          subject5: 0,
          subject6: 0,
          subject7: 0
        },
      ],
      section_exist: '',
    };
  },

  validations: {
    class_id: {
      required
    },
    academic_year: {
      required
    },
    subject: {
      $each: {
        subject1: {
          required
        },
        subject2: {
          required
        },
        subject3: {
          required
        },
        subject4: {
          required
        },
        subject5: {
          required
        },
        subject6: {
          required
        },
        subject7: {
          required
        }
      }
    }
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getAcademicYearJson() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      this.class_name_required = '';
      this.academic_year_required = '';
      this.grade_id = val;
      if (this.grade_id <= 3) {
        this.count = this.subjectCount1.length;
      } else {
        this.count = this.subjectCount.length;
      }
      this.subject.forEach((value, index) => {
        this.subject[index]['subject1'] = 0;
        this.subject[index]['subject2'] = 0;
        this.subject[index]['subject3'] = 0;
        this.subject[index]['subject4'] = 0;
        this.subject[index]['subject5'] = 0;
        this.subject[index]['subject6'] = 0;
        this.subject[index]['subject7'] = 0;
      });

      for (var i = 0; i < this.subject.length; i++) {
        for (var j = 0; j < this.count; j++) {
          this.firstname_ErrMsg[i][j] = '';
        }
      }

      if (fieldName == "academic_year") {
        this.isLoading = true;
        this.subjectArr = [];
        this.classArr = [];
        this.class_id = 0;        
        this.selectedClass = 0;
        this.getClass(val);
      }
      if (fieldName == "class_id") {
        this.section_exist = '';
        this.isLoading = true;
        this.subjectArr = [];
        if (val == 0) {
          this.isLoading = false;
          return true;
        } 
        this.getTimeTableDetail(val, 'changeEvent');
      }
    },

    changeEventSubject(val, index, index1) {
      if (val != null) {
        this.firstname_ErrMsg[index][index1] = '';
      }
    },
    getTimeTableDetail($classId, $mode) {
      axios.get("/timetable/detail/" + this.$route.params.id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(response => {
          this.grade_id = response.data.gradeId;
          this.subject = response.data.timeTableList;          
          if ($mode == 'changeEvent') {
            this.subject.forEach((value, index) => {
              this.subject[index]['subject1'] = 0;
              this.subject[index]['subject2'] = 0;
              this.subject[index]['subject3'] = 0;
              this.subject[index]['subject4'] = 0;
              this.subject[index]['subject5'] = 0;
              this.subject[index]['subject6'] = 0;
              this.subject[index]['subject7'] = 0;
            });
            this.getSubjectByClassId($classId);
          } else {
            this.subject.forEach((value, index) => {
              this.subject[index]['subject1'] = value.subject1;
              this.subject[index]['subject2'] = value.subject2;
              this.subject[index]['subject3'] = value.subject3;
              this.subject[index]['subject4'] = value.subject4;
              this.subject[index]['subject5'] = value.subject5;
              this.subject[index]['subject6'] = value.subject6;
              this.subject[index]['subject7'] = value.subject7;
            });
            this.getSubjectByClassId(this.$route.params.id);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("TimeTable Detail API Error", error);
          this.isLoading = false;
        });
    },

    getAcademicYear() {
      axios.get("/timetable/academicYear/" + this.$route.params.id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(response => {
          this.selectedYear = response.data.academic_year;
          this.academic_year = response.data.academic_year
          this.selectedClass = this.$route.params.id;
          this.class_id = this.$route.params.id;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Academic Year API Error", error);
        });
    },

    getClassName() {
      axios.get("timetable/classInfo/" + this.$route.params.id, {
          params: {
            campus_id: store.state.user.campus_id
          }
        })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Class Name API Error", error);
        });
    },
    // getSubject() {
    //   axios
    //     .get("/class/subjectByClassId/" + this.$route.params.id, {
    //       params: {
    //         campus_id: store.state.user.campus_id
    //       }
    //     })
    //     .then((response) => {
    //       this.subjectArr = response.data.subjectArr[0];
    //       this.grade_id = response.data.gradeId;
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       console.log("Subject API Error", error);
    //     });
    // },

    getSubjectByClassId($classId) {
      axios
        .get("/class/subjectByClassId/" + $classId, {
          params: {
            campus_id: store.state.user.campus_id
          }
        })
        .then((response) => {
          if(response.data.status == 200){
            let $defaultSelectOption = [{
              'id': 0,
              'text': 'Select Subject'
            }];
            this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
            this.grade_id = response.data.gradeId;
            this.isLoading = false;
          }          
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },
    getClass($yearId) {
      axios.get("class/classList/" + $yearId, {
          params: {
            campus_id: store.state.user.campus_id
          }
        })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          this.subjectArr = {};
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    timeTableUpdate() {
      this.isLoading = true;
      this.msgCount = 0;
      this.submitted = true;
      if (this.grade_id <= 3) {
        this.count = this.subjectCount1.length;
      } else {
        this.count = this.subjectCount.length;
      }
      if (this.class_id == '' || this.class_id == 0 || this.academic_year == '' || this.academic_year == 0) {
        for (var i = 0; i < this.subject.length; i++) {
            if (this.subject[i]['subject1'] == undefined || this.subject[i]['subject1'] == 0) {
              this.firstname_ErrMsg[i][0] = 'Please select subject.';
              this.msgCount++;
            } else {
              this.firstname_ErrMsg[i][0] = '';
            }
            if (this.subject[i]['subject2'] == undefined || this.subject[i]['subject2'] == 0) {
              this.firstname_ErrMsg[i][1] = 'Please select subject.';
              this.msgCount++;
            } else {
              this.firstname_ErrMsg[i][1] = '';
            }
            if (this.subject[i]['subject3'] == undefined || this.subject[i]['subject3'] == 0) {
              this.firstname_ErrMsg[i][2] = 'Please select subject.';
              this.msgCount++;
            } else {
              this.firstname_ErrMsg[i][2] = '';
            }
            if (this.subject[i]['subject4'] == undefined || this.subject[i]['subject4'] == 0) {
              this.firstname_ErrMsg[i][3] = 'Please select subject.';
              this.msgCount++;
            } else {
              this.firstname_ErrMsg[i][3] = '';
            }
            if (this.subject[i]['subject5'] == undefined || this.subject[i]['subject5'] == 0) {
              this.firstname_ErrMsg[i][4] = 'Please select subject.';
              this.msgCount++;
            } else {
              this.firstname_ErrMsg[i][4] = '';
            }
            if (this.subject[i]['subject6'] == undefined || this.subject[i]['subject6'] == 0) {
              this.firstname_ErrMsg[i][5] = 'Please select subject.';
              this.msgCount++;
            } else {
              this.firstname_ErrMsg[i][5] = '';
            }
            if (this.grade_id > 3) {
              if ((this.subject[i]['subject7'] == undefined || this.subject[m]['subject7'] == 0)) {
                this.firstname_ErrMsg[i][6] = 'Please select subject.';
                this.msgCount++;
              } else {
                this.firstname_ErrMsg[i][6] = '';
              }
            }           
          
        }
        if (this.class_id == '' || this.class_id == 0) {
          this.class_name_required = 'Class Name is required.';
        }
        if (this.academic_year == '' || this.academic_year == 0) {
          this.academic_year_required = 'Academic Year is required.';
        }
        if (this.msgCount > 0) {
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        return;
      } else {
        for (var m = 0; m < this.subject.length; m++) {
           if (this.subject[m]['subject1'] == undefined || this.subject[m]['subject1'] == 0) {
             this.firstname_ErrMsg[m][0] = 'Please select subject.';
             this.msgCount++;
           } else {
             this.firstname_ErrMsg[m][0] = '';
           }
           if (this.subject[m]['subject2'] == undefined || this.subject[m]['subject2'] == 0) {
             this.firstname_ErrMsg[m][1] = 'Please select subject.';
             this.msgCount++;
           } else {
             this.firstname_ErrMsg[m][1] = '';
           }
           if (this.subject[m]['subject3'] == undefined || this.subject[m]['subject3'] == 0) {
             this.firstname_ErrMsg[m][2] = 'Please select subject.';
             this.msgCount++;
           } else {
             this.firstname_ErrMsg[m][2] = '';
           }
           if (this.subject[m]['subject4'] == undefined || this.subject[m]['subject4'] == 0) {
             this.firstname_ErrMsg[m][3] = 'Please select subject.';
             this.msgCount++;
           } else {
             this.firstname_ErrMsg[m][3] = '';
           }
           if (this.subject[m]['subject5'] == undefined || this.subject[m]['subject5'] == 0) {
             this.firstname_ErrMsg[m][4] = 'Please select subject.';
             this.msgCount++;
           } else {
             this.firstname_ErrMsg[m][4] = '';
           }
           if (this.subject[m]['subject6'] == undefined || this.subject[m]['subject6'] == 0) {
             this.firstname_ErrMsg[m][5] = 'Please select subject.';
             this.msgCount++;
           } else {
             this.firstname_ErrMsg[m][5] = '';
           }
           if ((this.subject[m]['subject7'] == undefined || this.subject[m]['subject7'] == 0) && this.grade_id > 3) {
             this.firstname_ErrMsg[m][6] = 'Please select subject.';
             this.msgCount++;
           } else {
             this.firstname_ErrMsg[m][6] = '';
           }
          }
        }
        if (this.msgCount > 0) {
          this.isLoading = false;
          return;
        }
      this.update();
    },

    update() {
      var request = {
        old_class_id: this.$route.params.id,
        class_id: this.class_id,
        academic_year: this.academic_year,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id,
        subject: this.subject,
      }

      axios.post("/timetable/update", request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.status == 204) {
            this.submitted = false;
            this.getClassName();
            this.subject.forEach((value, index) => {
              this.subject[index]['subject1'] = 0;
              this.subject[index]['subject2'] = 0;
              this.subject[index]['subject3'] = 0;
              this.subject[index]['subject4'] = 0;
              this.subject[index]['subject5'] = 0;
              this.subject[index]['subject6'] = 0;
              this.subject[index]['subject7'] = 0;
            });
            this.section_exist = "Section doesn't exist. Please choose other section.";
            return;
          }
          if (response.data.status == 200) {
              this.$notification.success("Time Table Update Successfully", {
                timer: 3,
                position: "bottomCenter",
              });
              this.showList();
          }
          if (response.data.status == 201) {
            this.$notification.error("Class Name Already Exist", {
              timer: 3,
              position: "bottomCenter"
            });
            this.isLoading = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Time Table Update API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/timetable/list"
      });
    },
  },
  mounted() { 
    this.isLoading = true;
    this.getAcademicYear();
    this.getClassName();
    this.getTimeTableDetail(this.$route.params.id, "");
    this.getAcademicYearJson();
  },
};
