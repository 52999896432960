import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  numeric,
  minLength,
  maxLength,
  required
} from "vuelidate/lib/validators";

export default {
  name: "campusEdit",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      campus: {},
      isLoading: false,
      submitted: false,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    campus: {
      campus_name: {
        required,
        maxLength: maxLength(100)
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      address: {
        required,
        maxLength: maxLength(1000)
      },
      latitude: {
        maxLength: maxLength(20)
      },
      longitude: {
        maxLength: maxLength(20)
      },
    },
  },

  methods: {
    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.campus.login_id = this.$store.state.user.id;
      this.campus.campus_id = this.$store.state.user.campus_id;
      this.disabled = true;
      axios.post(`campus/update/${this.$route.params.id}`, this.campus)
      .then(res => {
        if (res.data) {
          this.$notification.success("Campus Updated Successfully", {
            timer: 3,
            position: "bottomCenter"
          });
          this.$router.push({
            name: "campusList"
          });
        }
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      })
    }
  },

  mounted() {
    this.isLoading = true;
    let id = this.$route.params.id;
    var request = {
      params: {
        campus_id: this.$store.state.user.campus_id
      }
    }
    axios.get(`campus/detail/${id}`, request)
      .then(res => {
        if (res.data) {
          this.campus = res.data;
        }
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Campus Detail API Error", error);
      })
  },
};
