import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";
import {
    mapGetters
} from "vuex";

export default {
    name: "gradingDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            gradingInfo: {},
            academicYearArr: null,
            gradingArr: null,
            timingArr: null,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            gradeData: {},
        };
    },

    computed: {
        ...mapGetters(["getUser"]),
    },

    methods: {
        getGrading() {
            axios.get('getGrading')
                .then(res => {
                    this.gradingArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getTiming() {
            axios.get('getTiming')
                .then(res => {
                    this.timingArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getAcademicYear() {
            axios.get('getAcademicYear')
                .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data);
                }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                });
        },
        
        getGradingDetail() {
            axios.get("/grading/detail/" + this.$route.params.student_id, {
                params: {
                    campus_id: store.state.user.campus_id,
                    time: this.$route.params.time
                }
            })
            .then(response => {
                if (response.data[0] != undefined) {
                    this.gradingInfo = response.data[0];
                    this.gradeData = response.data;                    
                }
                this.isLoading = false;
            })
            .catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                console.log("Grading Detail API Error", error);
            });
        },
        showList() {
            this.$router.push({
                path: "/grading/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getAcademicYear();
        this.getTiming();
        this.getGrading();
        this.getGradingDetail();
    },
};
