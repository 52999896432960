<template>
  <nav>
      <div class="container">
          <div class="nav">
              <a class="logo">
                  <div class="logo-img">
                    <router-link :to="{ name: 'dashboard' }">
                        <img src="@/assets/img/bib_logo.png" alt="BIB Institute">
                    </router-link>
                  </div>
                  <div class="logo-text">
                      <router-link :to="{ name: 'dashboard' }">
                        <p>BIB Institute</p>
                      </router-link>
                  </div>
              </a>
              <div class="top-nav align-center">
                  <div class="dropdown">
                      <a class="dropdown-menu"><i class="fa-solid fa-user"></i>{{ this.$store.state.user.name | adminDisplayName }}
                          <span><i class="fa-solid fa-caret-down"></i></span>
                      </a>
                      <ul class="dropdown-item dropdown_menu--animated dropdown_animation">
                        <li v-if="id != '0001'">
                          <router-link :to="{ name: 'school', params: { id: this.$store.state.user.campus_id }}">
                            <a>Campus Info</a>
                          </router-link>
                        </li>
                        <li v-if="id == '0001'">
                          <router-link :to="{ name: 'campusList' }">
                              <a>Campus</a>
                          </router-link>
                        </li>
                        <li v-if="id == '0001'">
                          <router-link :to="{ name: 'adminList' }">
                              <a>Admin</a>
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'updatePassword' }">
                            <a>Update Password</a>
                          </router-link>
                        </li>
                        <li class="border">
                            <a @click="logoutUser" class="btn-cursor"><i class="fa-solid fa-right-from-bracket"></i> Logout</a>
                        </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="nav-menu" v-if="id != '0001'">
              <ul class="nav-menu-list">
                  <li class="dropdown" :class="{ active: $route.name == 'dashboard' }">
                    <router-link :to="{ name: 'dashboard' }">
                      <a class="dd-hover"><i class="fa-solid fa-house"></i>Dashboard</a>
                    </router-link>
                  </li>
                  <li class="dropdown" :class="{ active: $route.name == 'adminList' }" v-if="role == 1">
                    <router-link :to="{ name: 'adminList' }">
                      <a class="dd-hover"><i class="fa-solid fa-users-gear"></i>Admins</a>
                    </router-link>
                  </li>
                  <li class="dropdown" :class="{ active: $route.name == 'classList' || $route.name == 'timetableList' || $route.name == 'gradeList' || $route.name == 'subjectList'}">
                      <a class="dropdown-menu"><i class="fa-solid
                                  fa-chalkboard-user"></i>Grade</a>
                      <ul class="dropdown-item dropdown_menu--animated dropdown_animation">                          
                          <li>
                            <router-link :to="{ name: 'gradeList' }">
                              <a>Grade List</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'classList' }">
                              <a> Class List</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'subjectList' }">
                              <a> Subject List</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'timetableList' }">
                              <a>TimeTable List</a>
                            </router-link>
                          </li>
                      </ul>
                  </li>
                  <li class="dropdown"  :class="{ active: $route.name == 'teacherList' || $route.name == 'attendanceTeacherList' || $route.name == 'subjectTeacher'}">
                      <a class="dropdown-menu"><i class="fa-solid
                                  fa-people-roof"></i>Teachers</a>
                      <ul class="dropdown-item dropdown_menu--animated dropdown_animation">
                          <li>
                            <router-link :to="{ name: 'teacherList' }">
                              <a>Teacher List</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'subjectAssignList' }">
                              <a>Subject Assign List</a>
                            </router-link>
                          </li>                         
                          <li>
                            <router-link :to="{ name: 'attendanceTeacherList' }">
                                <a>Attendance List</a>
                            </router-link>
                          </li>
                      </ul>
                  </li>
                  <li class="dropdown" :class="{ active: $route.name == 'studentList' || $route.name == 'attendanceStudentList' || $route.name == 'gradingList'}">
                      <a class="dropdown-menu"><i class="fa-solid
                                  fa-people-line"></i>Students</a>
                      <ul class="dropdown-item dropdown_menu--animated dropdown_animation">
                          <li>
                            <router-link :to="{ name: 'studentList' }">
                              <a> Student List</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'gradingList' }">
                              <a> Grading List</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'attendanceStudentList' }">
                              <a>Attendance List</a>
                            </router-link>
                          </li>
                      </ul>
                  </li>
                  <li class="dropdown" :class="{ active: $route.name == 'paymentList' || $route.name == 'inOutcomeList'|| $route.name == 'itemList'}" v-if="role == 1">
                      <a class="dropdown-menu"><i class="fa-solid
                                  fa-coins"></i>Finance</a>
                      <ul class="dropdown-item dropdown_menu--animated dropdown_animation">
                        <li>
                            <router-link :to="{ name: 'itemList' }">
                              <a>Items</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'paymentList' }">
                              <a>Enroll Payments</a>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'inOutcomeList' }">
                              <a>Income/Outcome</a>
                            </router-link>
                          </li>
                      </ul>
                  </li>
                  <li class="dropdown" :class="{ active: $route.name == 'driverList' || $route.name == 'ferryList' || $route.name == 'routeList' || $route.name == 'dailyFerryList' || $route.name == 'ferryPickupList'}">
                      <a class="dropdown-menu"><i class="fas fa-bus-alt"></i>Ferry</a>
                      <ul class="dropdown-item dropdown_menu--animated dropdown_animation">
                          <li><router-link :to="{ name: 'driverList' }"><a>Driver List</a></router-link></li>
                          <li><router-link :to="{ name: 'ferryList' }"><a>Ferry List</a></router-link></li>
                          <li><router-link :to="{ name: 'routeList' }"><a>Route List</a></router-link></li>
                          <li><router-link :to="{ name: 'ferryPickupList' }"><a>Ferry Pickup List</a></router-link></li>
                          <li><router-link :to="{ name: 'dailyFerryList' }"><a>Daily Ferry List</a></router-link></li>
                      </ul>
                  </li>
              </ul>
              <div class="dropdown" :class="{ active: $route.name == 'noticeList'}">
                <router-link :to="{ name: 'noticeList' }">
                  <span><i class="fa-solid fa-bullhorn"></i>Notices</span>
                </router-link>
              </div>
          </div>
      </div>
    </nav>
</template>


<script>
import store from '@/store';
import axios from 'axios';
export default {
  name: "HeaderPage",
  data() { 
    return {
      role: store.state.user.role,
      id: store.state.user.admin_no,
      campusCode: store.state.user.campus_master.campus_code 
    }
  },
  methods:{
    logoutUser(){
      axios.delete('/logout')
        .then(res => {
          if (res.data.success == true) {
            this.$store.commit('logout');
            this.$router.push({name: 'login'});
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    }
  },

  mounted() {
  
  }
}
</script>


