import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    mapGetters
} from "vuex";

export default {
    name: "GradeList",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            gradeArr: null,
            selectedGrade: 0,
            selectedYear: 0,
            subject_id: 0,
            grade_id: 0,
            gradeMasterData: {},
        };
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getGradeName() {
            axios.get('getGradeName')
                .then(res => {
                    this.gradeArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        reset() {
            this.subject_id = 0;
            this.grade_id = 0;
        },

        getAllGrade(page = 1) {
            axios.get('grade/list?page=' + page, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                }).then((response) => {
                    this.gradeMasterData = response.data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {                        
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;      
                });
        },

        showCreate() {
            this.$router.push({
                path: "/grade/create"
            });
        },

        showDetail(id) {
            this.$router.push({
                name: "gradeDetail",
                params: {
                    id: id
                }
            });
        },

        searchGrade(page = 1) {
            this.isLoading = true;
            axios.get('grade/search?page=' + page, {
                    params: {
                        grade_id: this.grade_id,
                        campus_id: store.state.user.campus_id
                    }
                })
                .then((res) => {
                    this.gradeMasterData = res.data;
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {                       
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showEdit(id) {
            this.$router.push({
                name: "gradeEdit",
                params: {
                    id: id
                }
            });
        },

        confirmDelete(grade_id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.deleteGrade(grade_id);
            } else {
                console.log("no");
            }
        },

        deleteGrade(grade_id) {
            this.isLoading = true;
            axios.delete("/grade/delete/" + grade_id, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            })
                .then(response => {
                    if (response.data.success == true) {
                        this.getAllGrade();
                    } else {
                        this.$notification.error("Grade data is already deleted.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.getAllGrade();
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Grade Delete Error->", error.response.data);
                });
        },

        excelDownload() {
            axios.get('/grade/excelDownload', {
                params: {
                    grade_id: this.grade_id,
                    campus_id: store.state.user.campus_id
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `grade_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }).catch(error => {
                    if (error.response.status == 401) {
                         this.$store.commit('logout');
                         window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.isLoading = true;
        this.getGradeName();
        this.getAllGrade();
    },
};
