import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import CONFIG from "@/assets/js/config.js";
import axios from 'axios';
import store from '@/store';

export default {
  name: "paymentList",
  components: {
    MainLayout,
    Loading,
    CONFIG,
    flatPickr,
    AdvancedLaravelVuePaginate,
    Select2Multiple,
  },

  data() {
    return {
      paymentData: {},
      campusId: store.state.user.campus_id,
      paymentMethodArr: null,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      searchData: [],
      academicYearArr: null,
      payMethod: [],
      itemArr: [],
    };
  },

  methods: {
    getItem() {
      axios.get('item/getItemForPayment')
        .then(res => {
          this.itemArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getPayment(page = 1) {
      axios.get('payment/list?page=' + page, { params: { campus_id: this.campusId } })
        .then(res => {
          if (res.data) {
            this.paymentData = res.data;
          }
          this.isLoading = false;
          for (let y = 0; y < this.paymentData.data.length; y++) {
            let method = this.paymentData.data[y].payment_methods;
            let methodArray = method.split(',');
            for (let index = 0; index < this.paymentMethodArr.length; index++) {
              for (let i = 0; i < methodArray.length; i++) {
                if (this.paymentMethodArr[index].id == methodArray[i]) {
                  methodArray[i] = ' ' + this.paymentMethodArr[index].text;
                  this.payMethod.push(methodArray[i]);
                }
              }
            }
            this.paymentData.data[y].payment_methods = String(this.payMethod);
            this.payMethod = [];
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.post(`payment/delete/${id}` + '/' + store.state.user.campus_id)
      .then(res => {
        this.isLoading = false;
        if (res.data)
          this.getPayment();
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    searching(page = 1) {
      this.isLoading = true;
      if (this.searchData.p_method == 0) {
        this.searchData.p_method = undefined;
      }
      axios.get('payment/search?page=' + page, {
        params: {
          stuName : this.searchData.stuName,
          fatherName: this.searchData.fatherName,
          item_id: this.searchData.item_id,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt,
          p_method: this.searchData.p_method,
          startDate: this.searchData.startDate,
          endDate: this.searchData.endDate,
          acaYear: this.searchData.acaYear,
          campus_id: this.campusId
        }
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data) {
            this.paymentData = res.data;
          }
          for (let y = 0; y < this.paymentData.data.length; y++) {
            let method = this.paymentData.data[y].payment_methods;
            let methodArray = method.split(',');
            for (let index = 0; index < this.paymentMethodArr.length; index++) {
              for (let i = 0; i < methodArray.length; i++) {
                if (this.paymentMethodArr[index].id == methodArray[i]) {
                  methodArray[i] = this.paymentMethodArr[index].text;
                  this.payMethod.push(methodArray[i]);
                }
              }
            }
            this.paymentData.data[y].payment_methods = String(this.payMethod);
            this.payMethod = [];
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = [];
    },

    excelDownload() {
      axios.get('/payment/excelDownload', {
        params: {
          stuName: this.searchData.stuName,
          fatherName: this.searchData.fatherName,
          item_id: this.searchData.item_id,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt,
          p_method: this.searchData.p_method,
          startDate: this.searchData.startDate,
          endDate: this.searchData.endDate,
          acaYear: this.searchData.acaYear,
          campus_id: this.campusId
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          let date = new Date();
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `payment_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getPayment();
    this.getItem();
    this.getPaymentMethod();
    this.getAcademicYear();
  },
};
