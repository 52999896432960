import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import {
  required,
  maxLength,
  minLength,
  email,
  numeric
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";
import store from "@/store";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';

const convertNumberService = new ConvertNumService();

export default {
  name: "StudentFail",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config : { 
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1)+ '-' + (new Date().getDate() -1)
      },
      isLoading: false,
      disabled: false,
      pwd_change: false,
      studentData: {},
      parentData: {
        password:'',
        confirm_password:''
      },
      dob: '',
      gradeArr: [],
      grade: '',
      religion: '',
      section: '',
      student_image: '',
      annual_fee: '',
      monthly_fee: '',
      selectedGrade: 0,
      selectedSection: 0,
      selectedReligion: 0,
      selectedAnnualFee: 0,
      selectedMonthlyFee: 0,
      religionArr: null,
      annualFeeArr: null,
      monthlyFeeArr: null,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      sectionArr: [{
        id: 0,
        text: 'Choose Section'
      }],
      submitted: false,
      noSectionMsg:'',
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,

      father_nrc_number: "",
      fatherNrcKey: "",
      fatherSelectedNrcName: "",
      fatherSelectedNrcNaing: "",
      fatherDistrictList: [],
      fatherNaingList: [],

      mother_nrc_number: "",
      motherNrcKey: "",
      motherSelectedNrcName: "",
      motherSelectedNrcNaing: "",
      motherDistrictList: [],
      motherNaingList: [],

      nrcName: [],
    };
  },

  validations: {
    grade: {
      required
    },
    religion: {
      required
    },
    section: {
      required
    },
    studentData: {
      c_student_no: {},
      name: {
        required,
        maxLength: maxLength(100)
      },
      dob: {
        required,
        maxLength: maxLength(100)
      },
      gender: {
        required
      },
      nationality: {
        maxLength: maxLength(100)
      },
      weight: {
        maxLength: maxLength(20)
      },
      height: {
        maxLength: maxLength(20)
      },
      sibling: {
        numeric,
        maxLength: maxLength(2)
      },
      hobby: {
        maxLength: maxLength(1000)
      },
      allergic_food: {
        maxLength: maxLength(1000)
      },
      special_topic: {
        maxLength: maxLength(1000)
      },
      blood: {
        maxLength: maxLength(20)
      },
      previous_school: {
        maxLength: maxLength(200)
      },
    },
    parentData: {
      father_name: {
        required,
        maxLength: maxLength(50)
      },
      father_job: {
        maxLength: maxLength(200)
      },
      mother_name: {
        required,
        maxLength: maxLength(50)
      },
      mother_job: {
        maxLength: maxLength(200)
      },
      father_nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      mother_nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      email: {
        maxLength: maxLength(50),
        email
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      address: {
        required,
        maxLength: maxLength(1000)
      },
    }
  },

  computed: {
    ...mapGetters([
      'getUser',
      'studentNo'
    ]),
  },

  methods: {
    getAnnualFee() {
      axios.get('getAnnualFee')
        .then(res => {
          this.annualFeeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getMonthlyFee() {
      axios.get('getMonthlyFee')
        .then(res => {
          this.monthlyFeeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcCity() {
      axios.get('getNrcCity')
        .then(res => {
          this.nrcno = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcNaing() {
      axios.get('getNrcNaing')
        .then(res => {
          this.naingJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcTownship() {
      axios.get('getNrcTownship')
        .then(res => {
          this.nrcNameJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    onProfileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = e => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.student_image = e.target.result;
            }
          };
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg() {
      this.student_image = '';
      document.getElementById('studentImage').value = '';
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);

      if (fieldName == 'grade') {
        // this.isLoading = true;
        var request = {
          params: {
            campus_id: this.getUser.campus_id,
            academic_year: parseInt(this.studentData.class_student.academic_year) + 1
          }
        }

        if (val == 0) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.selectedSection = this.section = 0;
          this.noSectionMsg = '';
          this.studentData.c_student_no = '';
          this.isLoading = false;
        } else {
          this.getSection(val, request, 0);
          this.getStudentNo(val, request);
        }
      }
    },

    changePassword() {
      this.pwd_change = true;
    },

    getSection(gradeId, request, selectedClassId) {
      axios.get("/class/getSection/" + gradeId, request).then((response) => {
        if (response.data[0].length > 0) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }].concat(response.data[0]);
          this.selectedSection = this.section = selectedClassId;
          this.noSectionMsg = '';
          this.isLoading = false;
        } else {
          this.noSectionMsg = 'Please create class to show section.';
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.selectedSection = this.section = 0;
          this.isLoading = false;
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getStudentNo(gradeId, request) {
      axios.get("/student/getStudentNo/" + gradeId, request).then((response) => {
        this.studentData.c_student_no = response.data;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getStudentEdit() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id,
          result: 'notSet'
        }
      }

      axios.get("/student/detail/" + this.$route.params.id, request)
        .then(response => {
          if(response.data) {
            this.studentData = response.data;
            this.student_image = this.studentData.profile_path;
            this.parentData = response.data.parent;
            this.selectedGrade = this.grade = this.studentData.grade;
            this.selectedReligion = this.religion = this.studentData.religion;
            this.studentData.annual_fee != null ? this.selectedAnnualFee = this.annual_fee = this.studentData.annual_fee : this.selectedAnnualFee = this.annual_fee = 0;
            this.studentData.monthly_fee != null ? this.selectedMonthlyFee = this.monthly_fee = this.studentData.monthly_fee : this.selectedMonthlyFee = this.monthly_fee = 0;
  
            var nrcfirst = this.parentData.father_nrc_number.split('/');
            var nrcsecond = nrcfirst[1].split('(');
            var nrcthird = nrcsecond[1].split(')');

            var convertNumberService = new ConvertNumService();
            var nrckeyEng = convertNumberService.convertMm2Eng(nrcfirst[0]);
            this.fatherNrcKey = nrcfirst[0]; 

            this.fatherSelectedNrcName = nrcsecond[0];
            this.fatherDistrictList = this.changeNrcName(nrckeyEng);
            this.fatherNaingList = this.naingJson;
            this.fatherSelectedNrcNaing = '(' + nrcthird[0] + ')';
            this.parentData.father_nrc_number = nrcthird[1];
  
            var mnrcfirst = this.parentData.mother_nrc_number.split('/');
            var mnrcsecond = mnrcfirst[1].split('(');
            var mnrcthird = mnrcsecond[1].split(')');

            var mnrckeyEng = convertNumberService.convertMm2Eng(mnrcfirst[0]);
            this.motherNrcKey = mnrcfirst[0]; 

            this.motherSelectedNrcName = mnrcsecond[0];
            this.motherDistrictList = this.changeNrcName(mnrckeyEng);
  
            this.motherNaingList = this.naingJson;
            this.motherSelectedNrcNaing = '(' + mnrcthird[0] + ')';
            this.parentData.mother_nrc_number = mnrcthird[1];
  
            var request = {
              params: {
                campus_id: this.getUser.campus_id,
                academic_year: parseInt(this.studentData.class_student.academic_year) + 1
              }
            }
            
            this.getSection(this.grade, request, 0);
            this.getStudentNo(this.grade, request);
            store.commit("setStudentNo", this.studentData.c_student_no);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Detail API Error", error);
        });
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grade", request).then((response) => {
          let $defaultSelectOption = [{
            "id": 0,
            "text": "Select"
          }];
          this.gradeArr = $defaultSelectOption.concat(response.data[0]);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    nrcOnChange(event, option) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);

      if (optionValue) {
          this.$set(this, option + 'NrcKey', optionText)
      } else {
          this.$set(this, option + 'NrcKey', optionValue)
      }
      this.$set(this, option + 'SelectedNrcName', "");
      this.$set(this, option + 'DistrictList', this.changeNrcName(optionValue));
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event, op) {
      var optionValue = event.target.value;
      if(!optionValue){
        if (op == 'father') {
          this.fatherSelectedNrcNaing = "";
          this.parentData.father_nrc_number = "";
        } else {
          this.motherSelectedNrcNaing = ""
          this.parentData.mother_nrc_number = ""
        }
      }
      op == 'father' ? this.fatherNaingList = this.naingJson : this.motherNaingList = this.naingJson;
    },

    mergeNRC(op) {
      if(op == 'father') {
        return convertNumberService.convertEng2Mm(
            this.fatherNrcKey +
            "/" +
            this.fatherSelectedNrcName +
            this.fatherSelectedNrcNaing +
            this.parentData.father_nrc_number
        );
      } else {
        return convertNumberService.convertEng2Mm(
          this.motherNrcKey +
          "/" +
          this.motherSelectedNrcName +
          this.motherSelectedNrcNaing +
          this.parentData.mother_nrc_number
      );
      }
    },

    confirmRegister() {
      this.submitted = true;
      if (this.$v.$invalid || this.grade == 0 || this.grade == '' || this.section == 0 || this.section == '' || this.religion == 0 || this.religion == '') {
        return;
      } else if(this.pwd_change) {
        if (this.parentData.password == '' || this.parentData.password == undefined || this.parentData.confirm_password == '' || this.parentData.confirm_password == undefined) {
          return;
        } else if (this.parentData.password.length < 8 || this.parentData.password.length >20 || this.parentData.confirm_password.length < 8 || this.parentData.confirm_password.length >20) {
          return;
        } else if(this.parentData.password != this.parentData.confirm_password) {
          this.$notification.error("Password and confirm password do not match.", {
            timer: 3,
            position: "bottomCenter",
          });
          return;
        }
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        update_student_id: this.$route.params.id,
        parent_id: this.parentData.id,
        name: this.studentData.name,
        student_image: this.student_image,
        student_image_old: this.studentData.profile_img,
        c_student_no: this.studentData.c_student_no,
        dob: this.studentData.dob,
        gender: this.studentData.gender,
        result: 0,
        nationality: this.studentData.nationality,
        grade: this.grade,
        religion: this.religion,
        section: this.section,
        academic_year: parseInt(this.studentData.class_student.academic_year) + 1,
        weight: this.studentData.weight,
        blood: this.studentData.blood,
        height: this.studentData.height,
        sibling: this.studentData.sibling,
        hobby: this.studentData.hobby,
        allergic_food: this.studentData.allergic_food,
        annual_fee: this.annual_fee,
        monthly_fee: this.monthly_fee,
        previous_school: this.studentData.previous_school,
        special_topic: this.studentData.special_topic,
        father_name: this.parentData.father_name,
        father_job: this.parentData.father_job,
        mother_name: this.parentData.mother_name,
        mother_job: this.parentData.mother_job,
        father_nrc_number: this.mergeNRC('father'),
        mother_nrc_number: this.mergeNRC('mother'),
        password: this.parentData.password,
        email: this.parentData.email,
        phone1: this.parentData.phone1,
        phone2: this.parentData.phone2,
        address: this.parentData.address,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id
      }

      axios.post("/student/comingYear/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Setting Student Fail Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "studentList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.disabled = false;
          console.log("Setting Student Fail API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/student/list"
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAllGrade();
    this.getAnnualFee();
    this.getMonthlyFee();
    this.getReligion();
    this.getNrcCity();
    this.getNrcNaing();
    this.getNrcTownship();
    this.getStudentEdit();
  },
};
