import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength,
} from "vuelidate/lib/validators";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "ferryCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      ferryData: {
        ferry_no: '',
        status: 0,
        total_seats: null,
        ferry_img: ''
      },
      ferry_img: '',
      submitted: false,
      inputError: null,
      disabled: false,
    };
  },

  validations: {
    ferryData: {
      ferry_no: {
        required,
        maxLength: maxLength(10)
      },
      total_seats: {
        required
      }
    },
  },

  computed: {
    isValidInput() {
      return this.ferryData.total_seats <= 44 && this.ferryData.total_seats > 0;
    },
  },

  methods: {
    checkLength(event) {
      if (event.target.value.length >= 7) {
        event.preventDefault();
      }
    },

    validateInput() {
      if (this.isValidInput) {
        this.inputError = null;
      } else {
        this.inputError = 'Please enter a valid number between 1 and 44.';
      }
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img, '');
      document.getElementById(img).value = '';
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.ferryData.ferry_img = this.ferry_img;
      this.ferryData.loginID = this.$store.state.user.id;
      this.ferryData.campus_id = this.$store.state.user.campus_id;
      this.disabled = true;
      axios.post('ferry/create', this.ferryData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Ferry Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: 'ferryList' })
          }
        }).catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },
};
