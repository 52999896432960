import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '@/store';

export default {
  name: "school",
  components: {
    Loading,
    MainLayout,
  },

  data() {
    return {
      campusData: [],
      isLoading: false,
      campusId: store.state.user.campus_id,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    getCampusDetail() {
      var request = {
      params: {
        campus_id: this.campusId
      }
    }
    if (this.$route.params.id == this.campusId) {
      axios
        .get(`campus/detail/${this.$route.params.id}`, request)
        .then((res) => {
          if (res.data) {
            this.campusData = res.data;
          }
          this.isLoading = false;
        })
    } else {
      this.isLoading = false;
      this.campusData = [];
    }
      
    }
  },
  mounted() {
    this.isLoading = true;
    this.getCampusDetail();
  },
};
