import Vue from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex";

export default {
    name: "SubjectCreate",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            tmpResult: false,
            academicYearArr: null,
            selectedGrade: 0,
            gradeArr: [],
            grade_id: '',
            subject: '',
            remark: '',
            old_grade_id: 0,
            inputData: {
                grade_id: '',
                subject: '',
                remark: '',
            },
            subjectInfo: [],
            hidden: false,
            firstname_ErrMsg: '',
            message: false,
            tmp_input: [],
        };
    },

    validations: {
        inputData: {           
            grade_id: {
                required
            },
            subject: {
                required,
                maxLength: maxLength(100)
            },
            remark: {
                maxLength: maxLength(1000)
            },
        }
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getAcademicYear() {
        axios.get('getAcademicYear')
            .then(res => {
            let $defaultSelectOption = [{
                'id': 0,
                'text': 'Select'
            }];
            this.academicYearArr = $defaultSelectOption.concat(res.data);
            }).catch((error) => {
            if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
            }
            this.isLoading = false;
            });
        },
        
        changeEvent(val, fieldName) {    
            this.tmpResult = false;
            this.$set(this, fieldName, val);
            if (this.old_grade_id != val && this.old_grade_id != 0) {
                this.subjectInfo = [];
                this.inputData.subject = '';
                this.inputData.remark = '';
            } else if(val == 0) {
                this.inputData.grade_id = '';
            }
            this.$set(this, fieldName, val);
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        getAllGrade() {
            this.tmpResult = false;
            axios.get("subject/gradeData", {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                }).then((response) => {
                    let $defaultSelectOption = [{
                        "id": 0,
                        "text": "Select Grade"
                    }];
                    this.gradeArr = $defaultSelectOption.concat(response.data.gradeArr[0]);
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        reset() {
            this.resetData();
        },

        showCreate() {
            this.$router.push({
                path: "/subject/create"
            });
        },

        resetEdit() {
            this.resetEditData();
            this.resetData();
            this.hidden = false;
        },

        resetEditData() {
            if (this.inputData.grade_id && this.inputData.subject) {
                this.subjectInfo.push(Vue.util.extend({}, this.inputData));
            }
        },

        resetData() {
            // this.inputData.grade_id = '';
            this.tmp_input = [];
            this.inputData.subject = '';
            this.inputData.remark = '';
        },

        editClone(index) {
            this.hidden = true;
            this.inputData = this.subjectInfo[index];
            this.tmp_input.push(Vue.util.extend({}, this.inputData));
            Vue.delete(this.subjectInfo, index);
        },

        removeClone(index) {
            Vue.delete(this.subjectInfo, index);
            if (this.subjectInfo.length == 0) {
                this.message = false;
            }
        },

        cloneForm() { 
            this.tmp_input = [];
            this.firstname_ErrMsg = '';
            if (this.$v.$invalid) {
                this.tmpResult = true;
                return;
            }
            if (this.subjectInfo.length < 12) {
                this.old_grade_id = this.grade_id;
                this.subjectInfo.push(Vue.util.extend({}, this.inputData));
                this.resetData();               
                this.tmpResult = false;
                if (this.subjectInfo.length > 0) {
                    this.message = true;
                }
            } else {
                alert('Subject can be created at most 12.');
            }
        },

        subjectCreate() {            
            this.isLoading = true;
            if (this.tmp_input.length > 0) {
                this.subjectInfo.push(Vue.util.extend({}, this.inputData));
            }
            this.firstname_ErrMsg = '';
            if (this.subjectInfo.length <= 0) {
                this.firstname_ErrMsg = 'Please Add Subject Data';
                this.isLoading = false;
                return;
            }
            this.tmpResult = false;
            axios.post('subject/create', {
                subjectInfo: this.subjectInfo,
                length: this.subjectInfo.length,
                login_id: store.state.user.id,
                campus_id: store.state.user.campus_id,
                grade_id: this.subjectInfo[0]['grade_id'],
            })
            .then(res => {
                if (res.data.status == 200) {
                    this.$notification.success("Subject Added Successfully", {
                        timer: 3,
                        position: "bottomCenter"
                    });
                    this.$router.push({
                        name: 'subjectList'
                    })
                }
                if (res.data.status == 201) {
                    this.$notification.error("Subject Data Already Added according to Grade", {
                        timer: 3,
                        position: "bottomCenter"
                    });
                    this.isLoading = false;
                } else {
                    this.errors = res.data.errors;
                    this.isLoading = false;
                }
            })
            .catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                this.errors = error.response.data.errors;
            })
        }
    },
    mounted() {
        this.getAllGrade();
        this.getAcademicYear();
    },
};
