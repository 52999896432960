import Vue from 'vue'
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import draggable from 'vuedraggable';
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "RouteDetail",
  components: {
    MainLayout,
    Loading,
    draggable,
    flatPickr,
  },

  data() {
    return {
      routeData: {
        pickup_time: null,
      },
      inputData: {
        pickup_time: null,
      },
      routePoint: [],
      editData: {},
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      submitted: false,
      disabled: false,
      routePoint_status: null,

      configDateTime: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minuteIncrement: 1,
      },
    };
  },

  validations: {
    inputData: {
      latitude: {
        maxLength: maxLength(20)
      },
      longitude: {
        maxLength: maxLength(20)
      },
      address: {
        required,
        maxLength: maxLength(500)
      },
      status: {
        required
      },
    },
  },

  methods: {
    getDetail() {
      axios.get(`route/detail/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.routeData = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    routePointByRoute() {
      axios.get(`routePoint/routePointByRoute/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.routePoint = res.data.data;
            if (!(this.routePoint[0])) {
              console.log(this.routePoint[0]);
            } else {
              this.routePoint_status = 'update';
              this.disabled = false;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.disabled = false;
      if (this.editData.address != null) {
        this.routePoint.push(Vue.util.extend({}, this.editData));
        this.editData = [];
      }
      this.inputData = {};
    },

    cloneForm() {
      this.editData = [];
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.routePoint.push(Vue.util.extend({}, this.inputData));
      this.inputData = {};
      this.submitted = false;
      this.disabled = false;
    },

    removeClone(index) {
      Vue.delete(this.routePoint, index);
    },

    editClone(index) {
      this.disabled = true;
      if (this.inputData.address != null && this.inputData.status != null) {
        this.routePoint.push(Vue.util.extend({}, this.inputData));
      }
      let data = this.routePoint[index];
      this.editData.latitude = data.latitude;
      this.editData.longitude = data.longitude;
      this.editData.address = data.address;
      this.editData.status = data.status;
      this.editData.pickup_time = data.pickup_time;
      if (data.id) {
        this.editData.id = data.id;
      }
      this.inputData = this.routePoint[index];
      Vue.delete(this.routePoint, index);
    },

    createRoutePoint() {
      if (this.editData.latitude != null && this.editData.longitude != null && this.editData.address != null && 
          this.editData.status != null && this.editData.pickup_time != null) {
        this.disabled = true;
      }
      this.disabled = true;
      axios.post('routePoint/create', {
        routePoint: this.routePoint,
        route_id: this.routeData.id,
        loginId: this.$store.state.user.id
      })
        .then(res => {
          if (res.data) {
            this.$notification.success("Route Point Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: 'routeList' })
          }
          this.disabled = false;
        }).catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } 
    },

    delete(id) {
      this.isLoading = true;
      axios.delete(`routePoint/delete/${id}`)
        .then(res => {
          if (res.data.success == true)
            this.routePointByRoute();
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    updateRoutePoint() {
      this.disabled = true;
      axios.post(`routePoint/update/${this.$route.params.id}`, {
        routePoint: this.routePoint,
        route_id: this.routeData.id,
        loginId: this.$store.state.user.id
      })
        .then(res => {
          if (res.data) {
            this.$notification.success("Route Point Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "routeList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
    this.routePointByRoute();
    if (this.routePoint.length == 0) {
      this.disabled = true;
    }
    if (this.inputData.lenght != 0 ) {
      this.disabled = true;
    }
  },
};
