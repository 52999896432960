import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import CONFIG from "@/assets/js/config.js";
import store from '@/store';

export default {
  name: "campusList",
  components: {
    MainLayout,
    Loading,
    AdvancedLaravelVuePaginate,
    CONFIG,
  },

  data() {
    return {
      campus: {},
      removeId: '',
      searchData: {
        name: '',
        phNo: '',
        address: ''
      },
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      campusId: store.state.user.campus_id,
    };
  },

  methods: {
    getCampus(page = 1) {      
      axios.get('campus/getAll?page=' + page, { params: { campus_id: this.campusId } })
        .then(res => {
          if (res.data) {
            this.campus = res.data;
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    searchCampus(page = 1) {
      this.isLoading = true;
      axios.get('campus/search?page=' + page, {
        params: {
          name: this.searchData.name,
          phNo: this.searchData.phNo,
          address: this.searchData.address,
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data) {
            this.campus = res.data;
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      var request = {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.delete(`campus/delete/${id}`, request)
        .then(res => {
          if (res.data) {
            this.getCampus();
            this.isLoading = false;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    reset() {
      this.searchData.name = '';
      this.searchData.phNo = '';
      this.searchData.address = '';
    },

    excelDownload() {
      axios.get('/campus/excelDownload', {
        params: {
          name: this.searchData.name,
          phNo: this.searchData.phNo,
          address: this.searchData.address,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          let date = new Date();
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `campus_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getCampus();
  },

  // created() {
  //   const reloaded = localStorage.getItem('reloaded');
  //   if (reloaded == 'true') {
  //     localStorage.setItem('reloaded', 'false');
  //     location.reload();
  //   }
  // }
};
