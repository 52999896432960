import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
import { required, maxLength, minLength, email, numeric } from "vuelidate/lib/validators";
import CONFIG from "@/assets/js/config.js";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';

const convertNumberService = new ConvertNumService();

export default {
  name: "TeacherEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config : { 
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1)+ '-' + (new Date().getDate() -1)
      },
      isLoading: false,
      submitted: false,
      disabled: false,
      pwd_change: false,
      teacherData: {},
      selectedTeacherType: 0,
      selectedReligion: 0,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      religionArr: null,
      teacherTypeArr: null,
      teacherImage: "",
      nrcFrontImage: "",
      nrcBackImage: "",
      teacherOldImage: "",
      nrcFrontOldImage: "",
      nrcBackOldImage: "",
      teacher_type: "",
      religion: "",
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,
      nrc_number: "",
      nrc_only_number: "",
      nrc: "",
      nrckey: "",
      selectedNrcName: "",
      selectedNrcNaing: "",
      districtList: [],
      naingList: [],
      nrcName: [],
    };
  },

  validations: {
    teacherImage: {},
    teacher_type: {
      required,
    },
    religion: {},
    teacherData: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      dob: {
        required,
        maxLength: maxLength(100),
      },
      gender: {
        required,
      },
      father_name: {
        required,
        maxLength: maxLength(50),
      },
      marital_status: {
        required,
      },
      nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      nationality: {
        required,
        maxLength: maxLength(100),
      },
      edu_background: {
        maxLength: maxLength(1000)
      },
      other_certificate: {
        maxLength: maxLength(1000)
      },
      work_experience: {
        maxLength: maxLength(1000)
      },
      join_date: {
        required,
      },
      password: {},
      confirm_password:{},
      email: {
        maxLength: maxLength(50),
        email
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      contact_address: {
        required,
        maxLength: maxLength(1000)
      },
      permanent_address: {
        maxLength: maxLength(1000)
      },
      ferry_status: {
        required
      },
    },
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcCity() {
      axios.get('getNrcCity')
        .then(res => {
          this.nrcno = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcNaing() {
      axios.get('getNrcNaing')
        .then(res => {
          this.naingJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcTownship() {
      axios.get('getNrcTownship')
        .then(res => {
          this.nrcNameJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTeacherType() {
      axios.get('getTeacherType')
        .then(res => {
          this.teacherTypeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    onChange(event) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
          this.nrckey = optionText;
      } else {
          this.nrckey = optionValue;
      }
      this.selectedNrcName = "";
      this.districtList = this.changeNrcName(optionValue);
    },

    changePassword() {
      this.pwd_change = true;
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event) {
      var optionValue = event.target.value;
      if(!optionValue){
        this.selectedNrcNaing = ""
        this.teacherData.nrc_number = ""
      }
      this.naingList = this.naingJson;
    },

    checkNRC() {
      if (
          this.nrckey !== undefined &&
          this.selectedNrcName !== undefined &&
          this.selectedNrcNaing !== undefined &&
          this.teacherData.nrc_number !== ""
      ) {
          return true;
      } else if (
          this.nrckey == undefined &&
          this.selectedNrcName == undefined &&
          this.selectedNrcNaing == undefined &&
          this.teacherData.nrc_number == ""
      ) {
          return true;
      } else return false;
    },

    mergeNRC() {
      return convertNumberService.convertEng2Mm(
          this.nrckey +
          "/" +
          this.selectedNrcName +
          this.selectedNrcNaing +
          this.teacherData.nrc_number
      );
    },

    getTeacherDetail() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/teacher/edit/" + this.$route.params.id, request)
        .then((response) => {
          if (response.data) {
            this.teacherData = response.data;
            this.teacherImage = this.teacherData.profile_path;
            this.nrcFrontImage = this.teacherData.nrc_front_path;
            this.nrcBackImage = this.teacherData.nrc_back_path;

            var nrcfirst = response.data.nrc_number.split('/');
            var nrcsecond = nrcfirst[1].split('(');
            var nrcthird = nrcsecond[1].split(')');
            var convertNumberService = new ConvertNumService();
            var nrckeyEng = convertNumberService.convertMm2Eng(nrcfirst[0]);
            this.nrckey = nrcfirst[0]; 
            this.selectedNrcName = nrcsecond[0]; 
            this.districtList = this.changeNrcName(nrckeyEng);
            this.naingList = this.naingJson;
            this.selectedNrcNaing = '(' + nrcthird[0] + ')';
            this.teacherData.nrc_number = nrcthird[1];
  
            this.selectedTeacherType = this.teacher_type =
              this.teacherData.teacher_type;
            this.teacherData.religion != null
              ? (this.selectedReligion = this.religion =
                  this.teacherData.religion)
              : null;
          }

          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Detail API Error", error);
        });
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img,'');
      document.getElementById(img).value = '';
    },

    confirmUpdate() {
      this.submitted = true;
      if (
        this.$v.$invalid ||
        this.teacher_type == 0 ||
        this.teacher_type == ""
      ) {
        return;
      } else if(this.pwd_change) {
        if(this.teacherData.password == '' || this.teacherData.password == undefined || this.teacherData.confirm_password == '' || this.teacherData.confirm_password == undefined) {
          return;
        } else if (this.teacherData.password.length < 8 || this.teacherData.password.length >20 || this.teacherData.confirm_password.length < 8 || this.teacherData.confirm_password.length >20) {
          return;
        } else if(this.teacherData.password != this.teacherData.confirm_password) {
          this.$notification.error("Password and confirm password do not match.", {
            timer: 3,
            position: "bottomCenter",
          });
          return;
        }
      }
      this.update();
    },

    update() {
      this.disabled = true;
      var request = {
        name: this.teacherData.name,
        teacher_type: this.teacher_type,
        profileImage: this.teacherImage,
        nrcFrontImage: this.nrcFrontImage,
        nrcBackImage: this.nrcBackImage,

        profileOldImageName: this.teacherData.profile_img,
        nrcFrontOldImageName: this.teacherData.nrc_front_img,
        nrcBackOldImageName: this.teacherData.nrc_back_img,

        religion: this.religion,
        dob: this.teacherData.dob,
        gender: this.teacherData.gender,
        father_name: this.teacherData.father_name,
        marital_status: this.teacherData.marital_status,
        nrc_number: this.mergeNRC(),
        nationality: this.teacherData.nationality,
        education_background: this.teacherData.edu_background,
        other_certificate: this.teacherData.other_certificate,
        work_experience: this.teacherData.work_experience,
        join_date: this.teacherData.join_date,
        password: this.teacherData.password,
        email: this.teacherData.email,
        phone1: this.teacherData.phone1,
        phone2: this.teacherData.phone2,
        contact_address: this.teacherData.contact_address,
        permanent_address: this.teacherData.permanent_address,
        ferry_status: this.teacherData.ferry_status,
        campus_id: this.getUser.campus_id,
        updated_id: this.getUser.id,
      };

      axios
        .post("/teacher/update/" + this.$route.params.id, request, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.success == true) {
            this.$notification.success("Teacher Updated Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled= false;
          this.isLoading = false;
          console.log("Teacher Update API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/teacher/list",
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.isLoading = true;
    this.getReligion();
    this.getNrcCity();
    this.getNrcNaing();
    this.getNrcTownship();
    this.getTeacherType();
    this.getTeacherDetail();
  },
};
