import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import store from '../../store';
import {
    required,maxLength
} from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "gradingCreate",
    components: {
        MainLayout,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            isLoading: false,
            classData: {},
            selectedClass: 0,
            selectedYear: 0,
            classArr: [],
            subjectArr: [],
            studentNameArr: [],
            academic_year: '',
            class_id: '',
            remark: '',
            timingSelected: 0,
            gradingSelected: 0,
            student_id: 0,
            timing_id: 0,
            grading_id: 0,
            subject_id: '',
            academicYearArr: null,
            gradingArr: null,
            timingArr: null,
            submitted: false,
            items: [],
            comment: [],
            firstname_ErrMsg: [],
            subject_ErrMsg: '',
            comment_msg: [],
            msgCount: 0,
            id: 0,
            cmt: 0,
            cmtCount: 0,
        };
    },

    validations: {
        class_id: {
            required
        },
        academic_year: {
            required
        },
        timing_id: {
            required
        },
        student_id: {
            required
        },
        grading_id: {
            required
        },
        subject_id: {
            required
        },
        // remark: {
        //     maxLength: maxLength(1000)
        // },
        items: {
            $each: {
                required
            }
        },
        comment: {
            $each: {
                maxLength: maxLength(1000)
            }
        }
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getGrading() {
            axios.get('getGrading')
                .then(res => {
                    this.gradingArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getTiming() {
            axios.get('getTiming')
                .then(res => {
                    this.timingArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getAcademicYear() {
            axios.get('getAcademicYear')
                .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data);
                }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                });
        },
        
        changeEvent(val, fieldName) {
            this.submitted = false;
            this.$set(this, fieldName, val);
             if (fieldName == "academic_year") {
                this.isLoading = true;                 
                this.classArr = [];
                this.subjectArr = [];
                this.studentNameArr = [];
                this.firstname_ErrMsg= [];
                this.subject_ErrMsg= '';
                this.items = [];
                this.comment = [];
                this.comment_msg= [];
                this.subject_id = -1;
                this.student_id = 0;
                this.class_id = 0;
                this.timing_id= 0;
                this.timingSelected = 0;
                this.selectedClass = 0;
                 this.getClass(val);
             }
             if (fieldName == "class_id") {
                this.isLoading = true;
                this.subjectArr = [];
                this.studentNameArr = [];
                this.firstname_ErrMsg = [];
                this.subject_ErrMsg = '';
                this.items = [];
                this.comment = [];
                this.comment_msg = [];
                this.subject_id = -1;
                this.student_id = 0;
                this.timing_id = 0;
                this.timingSelected = 0;
                this.getStudentName(val);
                this.getSubjectByClassId(val);
             }
        },

        getClass() { 
            var currentDate = new Date();
            const year = currentDate.getFullYear();
            currentDate.getMonth() < 3 ? this.academic_year = year - 1 : this.academic_year = year;
            axios.get("class/classList/" + this.academic_year, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        changeEventSubject(val, fieldName, index) {
            if (fieldName == 'Please select Grade.' && val != null) {
                this.firstname_ErrMsg[index] = '';
            }
        },

        getStudentName($classId) {
            axios.get("student/getStudentName/" + $classId, {
                    params: {
                    campus_id: store.state.user.campus_id
                    }
                 })
                 .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Student Name'
                    }];
                    this.studentNameArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                       this.$store.commit('logout');
                       window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getSubjectByClassId($classId) {
            axios
                .get("/class/subjectByClassId/" + $classId, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then((response) => {
                    if (response.data.subjectArr[0].length > 0) {
                        let $defaultSelectOption = [{
                            'id': 0,
                            'text': 'ကျောင်းလုပ်ငန်း'
                        }];
                        this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);                        
                    } 
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject API Error", error);
                });
        },

        confirmRegister() {
            this.msgCount = 0; 
            this.cmtCount = 0;
            this.id = 0;
            this.submitted = true;
            this.isLoading = true;
            if (this.$v.$invalid || this.class_id == '' || this.class_id == 0 || this.academic_year == '' || this.academic_year == 0 || this.timing_id == '' || this.timing_id == 0 || this.student_id == '' || this.student_id == 0) {
                
                if(this.subjectArr.length > 0) {
                    this.subjectArr.forEach((value, index) => {
                        this.cmt = this.comment[index] ? this.comment[index].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        if (this.items[index] == undefined || this.items[index] == 0) {
                            this.firstname_ErrMsg[index] = 'Please select Grade.';
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = '';
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }
                
                if (this.msgCount > 0 || this.cmtCount > 0 || this.subject_ErrMsg != '') {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = false;
                return;
            } else {
                if (this.subjectArr.length > 0) {
                    this.subjectArr.forEach((value, index) => {
                        this.cmt = this.comment[index] ? this.comment[index].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        if (this.items[index] == undefined || this.items[index] == 0) {
                            this.firstname_ErrMsg[index] = 'Please select Grade.';
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = '';
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }
                if (this.msgCount > 0 || this.cmtCount > 0 || this.subject_ErrMsg != '') {
                    this.isLoading = false;
                    return;
                }
            }
            this.register();
        },

        register() {            
            var request = {
                academic_year: this.academic_year,
                class_id: this.class_id,
                timing_id: this.timing_id,
                student_id: this.student_id,
                grading: this.items,
                gradeComments: this.comment,
                subjects: this.subjectArr,
                // subject_id: this.subject_id,
                // grading_id: this.grading_id,
                // remark: this.remark,
                campus_id: this.getUser.campus_id,
                created_id: this.getUser.id
            }
            
            axios.post("/grading/create", request, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    if (response.data.status == 200) {
                        this.isLoading = false;
                        this.$notification.success("Grading Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "gradingList"
                        });
                    }
                    else if (response.data.status == 201) {
                        this.isLoading = false;
                        this.$notification.error("Grading data is already exist.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    } else {
                        this.isLoading = false;
                        this.$notification.error("something went wrong", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Grading Create API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/grading/list"
            });
        },
    },

    mounted() {
        // this.isLoading = true;
        this.getAcademicYear();
        this.getTiming();
        this.getGrading();
        this.getClass();
    },
};
