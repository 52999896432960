import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    required
} from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex";

export default {
    name: "GradeEdit",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_EXIST,
            isLoading: false,
            search_status: false,
            tmpResult: false,
            selectedGrade: 0,
            gradeArr: null,
            grade_id: 0,
            old_grade_id: 0,
            submitted: false,
            gradeData: {}
        };
    },

    validations: {
        grade_id: {
            required
        },
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getGradeName() {
            axios.get('getGradeName')
                .then(res => {
                    this.gradeArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        changeEvent(val, fieldName) {
            this.submitted = false;
            this.$set(this, fieldName, val);
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        gradeUpdate() {    
            this.submitted = true;
            if (this.$v.$invalid || this.grade_id == '' || this.grade_id == 0) {
                return;
            }
            this.isLoading = true;
            this.gradeArr.forEach((value) => {
                if (value.id == this.grade_id) {
                    this.grade_name = value.text;
                }
            });
            axios.post('grade/update', {
                    login_id: store.state.user.id,
                    campus_id: store.state.user.campus_id,
                    id: this.$route.params.id,
                    grade_id: this.grade_id,
                    old_grade_id: this.old_grade_id,
                    grade_name: this.grade_name
                })
                .then(res => {
                    if (res.data.status == 200) {
                        this.$notification.success("Grade Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: 'gradeList'
                        })
                    }else if (res.data.status == 201) {
                        this.$notification.error("Grade Data Already Exist", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.isLoading = false;
                    } else {
                        this.errors = res.data.errors;
                        this.isLoading = false;
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    this.errors = error.response.data.errors;
                })
        },

        getGradeById() {
            this.isLoading = true;
            axios.get('grade/detail/' + this.$route.params.id, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                }).then((response) => {
                    if (response.data[0] != undefined) {
                        this.grade_id = response.data[0].grade_id;
                        this.selectedGrade = response.data[0].grade_id;
                        this.old_grade_id = response.data[0].grade_id;
                        this.gradeData = response.data[0];
                    }                    
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
    },
    mounted() {        
        this.getGradeName();
        this.getGradeById();
    },
};
