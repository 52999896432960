import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength,
  integer
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "ItemCreate",
  components: {
    MainLayout,
  },

  data() {
    return {
      itemData: {
        p_item_name: null,
        p_item_amount: null,
      },
      submitted: false,
      disabled: false,
    };
  },

  validations: {
    itemData: {
      p_item_name: {
        required,
        maxLength: maxLength(100)
      },
      p_item_amount: {
        required,
        integer,
        maxLength: maxLength(9)
      },
    },
  },

  methods: {
    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.disabled = true;
      this.isLoading = false;
      this.itemData.loginID = this.$store.state.user.id;
      axios.post('item/create', this.itemData)
        .then(res => {
          this.disabled = false;
          if (res.data.success === true) {
            this.$notification.success("Item Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'itemList'
            })
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            })
          }
        }).catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
  },
};
