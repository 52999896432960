import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength,
  minLength,
  email,
  numeric
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
  mapGetters
} from "vuex";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';
import CONFIG from "@/assets/js/config.js";

const convertNumberService = new ConvertNumService();




export default {
  name: "StudentCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config: {
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() - 1)
      },
      studentData: {},
      parentData: {},
      nrcData: {},
      pwdData: {},
      student_image: '',
      c_student_no: '',
      entry_no: '',
      gradeArr: [],
      grade: '',
      religion: '',
      section: '',
      annual_fee: '',
      monthly_fee: '',
      religionArr: null,
      annualFeeArr: null,
      monthlyFeeArr: null,
      sectionArr: [{
        id: 0,
        text: 'Choose Section'
      }],
      selectedSection: 0,
      selectedGrade: 0,
      selectedReligion: 0,
      selectedAnnualFee: 0,
      selectedMonthlyFee: 0,
      submitted: false,
      isLoading: false,
      disabled: false,
      existingParent: false,
      noSectionMsg: '',
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,
      p_minLength: '',
      p_maxLength: '',
      cp_minLength: '',
      cp_maxLength: '',

      father_nrc_number: "",
      fatherNrcKey: "",
      fatherSelectedNrcName: "",
      fatherSelectedNrcNaing: "",
      fatherDistrictList: [],
      fatherNaingList: [],

      mother_nrc_number: "",
      motherNrcKey: "",
      motherSelectedNrcName: "",
      motherSelectedNrcNaing: "",
      motherDistrictList: [],
      motherNaingList: [],

      nrcName: [],
      url: '/class/create?rd_back=student',
      section_exist: '',

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoadingWebcam: false,
      link: '#'
    };
  },

  validations: {
    student_image: {},
    grade: {
      required
    },
    religion: {
      required
    },
    section: {
      required
    },
    studentData: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      dob: {
        required,
        maxLength: maxLength(100)
      },
      gender: {
        required
      },
      nationality: {
        maxLength: maxLength(100)
      },
      weight: {
        maxLength: maxLength(20)
      },
      height: {
        maxLength: maxLength(20)
      },
      sibling: {
        numeric,
        maxLength: maxLength(2)
      },
      hobby: {
        maxLength: maxLength(1000)
      },
      allergic_food: {
        maxLength: maxLength(1000)
      },
      special_topic: {
        maxLength: maxLength(1000)
      },
      blood: {
        maxLength: maxLength(20)
      },
      previous_school: {
        maxLength: maxLength(200)
      }
    },

    parentData: {
      father_name: {
        required,
        maxLength: maxLength(50)
      },
      father_job: {
        maxLength: maxLength(200)
      },
      mother_name: {
        required,
        maxLength: maxLength(50)
      },
      mother_job: {
        maxLength: maxLength(200)
      },
      email: {
        maxLength: maxLength(50),
        email
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      address: {
        required,
        maxLength: maxLength(1000)
      }
    },
    nrcData: {
      father_nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      mother_nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
    },

    pwdData: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
      confirm_password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20)
      },
    }
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoadingWebcam = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingWebcam = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          console.log(error);
          this.isLoadingWebcam = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      this.student_image = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    getAnnualFee() {
      axios.get('getAnnualFee')
        .then(res => {
          this.annualFeeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getMonthlyFee() {
      axios.get('getMonthlyFee')
        .then(res => {
          this.monthlyFeeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcCity() {
      axios.get('getNrcCity')
        .then(res => {
          this.nrcno = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcNaing() {
      axios.get('getNrcNaing')
        .then(res => {
          this.naingJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcTownship() {
      axios.get('getNrcTownship')
        .then(res => {
          this.nrcNameJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    onProfileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = e => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.student_image = e.target.result;
              console.log(this.student_image);
            }
          };
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg() {
      this.student_image = '';
      document.getElementById('studentImage').value = '';
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }

      if (fieldName == 'grade') {
        // this.isLoading = true;        
        if (val == 0) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.selectedSection = this.section = 0;
          this.noSectionMsg = '';
          this.c_student_no = '';
          this.isLoading = false;
        } else {
          this.getSection(val, request);
          this.getStudentNo(val, request);
        }
      }
      if (fieldName == "section") {
        this.section_exist = '';        
      }
    },

    getSection(gradeId, request) {
      axios.get("/class/getSection/" + gradeId, request).then((response) => {
        if (response.data[0].length > 0) {
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }].concat(response.data[0]);
          this.selectedSection = this.section = 0;
          this.noSectionMsg = '';
          this.isLoading = false;
        } else {
          this.noSectionMsg = 'Please create class to show section.';
          this.sectionArr = [{
            "id": 0,
            "text": "Choose Section"
          }];
          this.selectedSection = this.section = 0;
          this.isLoading = false;
        }
      })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getStudentNo(gradeId, request) {
      axios.get("/student/getStudentNo/" + gradeId, request).then((response) => {
        this.c_student_no = response.data;
      })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grade", request).then((response) => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "Select"
        }];
        this.gradeArr = $defaultSelectOption.concat(response.data[0]);
        this.isLoading = false;
      })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    nrcOnChange(event, option) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
        this.$set(this, option + 'NrcKey', optionText)
      } else {
        this.$set(this, option + 'NrcKey', optionValue)
      }
      this.$set(this, option + 'SelectedNrcName', "");
      this.$set(this, option + 'DistrictList', this.changeNrcName(optionValue));
      this.checkNrcFilled();
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event, op) {
      var optionValue = event.target.value;
      if(!optionValue){
        if (op == 'father') {
          this.fatherSelectedNrcNaing = "";
          this.nrcData.father_nrc_number = "";
        } else {
          this.motherSelectedNrcNaing = ""
          this.nrcData.mother_nrc_number = ""
        }
      }
      op == 'father' ? this.fatherNaingList = this.naingJson : this.motherNaingList = this.naingJson;

      this.checkNrcFilled();
    },

    mergeNRC(op) {
      if (op == 'father') {
        return convertNumberService.convertEng2Mm(
          this.fatherNrcKey +
          "/" +
          this.fatherSelectedNrcName +
          this.fatherSelectedNrcNaing +
          this.nrcData.father_nrc_number
        );
      } else {
        return convertNumberService.convertEng2Mm(
          this.motherNrcKey +
          "/" +
          this.motherSelectedNrcName +
          this.motherSelectedNrcNaing +
          this.nrcData.mother_nrc_number
        );
      }
    },

    checkNrcFilled() {
      if (this.$v.nrcData.father_nrc_number.$invalid || this.$v.nrcData.mother_nrc_number.$invalid) {
        return;
      }
      else if (
        this.fatherNrcKey !== '' &&
        this.fatherSelectedNrcName !== '' &&
        this.fatherSelectedNrcNaing !== '' &&
        this.nrcData.father_nrc_number !== undefined &&

        this.motherNrckey !== '' &&
        this.motherSelectedNrcName !== '' &&
        this.motherSelectedNrcNaing !== '' &&
        this.nrcData.mother_nrc_number !== undefined
      ) {
        this.checkParentNrc();
      }
    },

    checkParentNrc() {
      var request = {
        campus_id: this.getUser.campus_id,
        father_nrc_number: this.mergeNRC('father'),
        mother_nrc_number: this.mergeNRC('mother'),
      }

      axios.post("/student/checkParentNrc", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data) {
            this.existingParent = true;
            this.parentData = response.data;
          } else {
            this.existingParent ? this.parentData = {}: null;
          this.existingParent = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Check Parent Nrc API Error", error.errors);
        });
    },

    confirmRegister() {
      this.submitted = true;
      if (this.$v.studentData.$invalid ||
        this.grade == 0 ||
        this.grade == '' ||
        this.section == 0 ||
        this.section == '' ||
        this.religion == 0 ||
        this.religion == '' ||
        this.$v.parentData.$invalid ||
        this.$v.nrcData.$invalid) {
        return;
      } else if (!this.existingParent) {
        if(this.$v.pwdData.$invalid) {
          return;
        } else if(this.pwdData.password != this.pwdData.confirm_password) {
          this.$notification.error("Password and confirm password do not match.", {
            timer: 3,
            position: "bottomCenter",
          });
          return;
        }
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        name: this.studentData.name,
        student_image: this.student_image,
        c_student_no: this.c_student_no,
        entry_no: this.entry_no,
        password: this.pwdData.password,
        dob: this.studentData.dob,
        gender: this.studentData.gender,
        nationality: this.studentData.nationality,
        grade: this.grade,
        religion: this.religion,
        section: this.section,
        weight: this.studentData.weight,
        blood: this.studentData.blood,
        height: this.studentData.height,
        sibling: this.studentData.sibling,
        hobby: this.studentData.hobby,
        allergic_food: this.studentData.allergic_food,
        annual_fee: this.annual_fee,
        monthly_fee: this.monthly_fee,
        previous_school: this.studentData.previous_school,
        special_topic: this.studentData.special_topic,
        father_name: this.parentData.father_name,
        father_job: this.parentData.father_job,
        mother_name: this.parentData.mother_name,
        mother_job: this.parentData.mother_job,
        father_nrc_number: this.mergeNRC('father'),
        mother_nrc_number: this.mergeNRC('mother'),
        email: this.parentData.email,
        phone1: this.parentData.phone1,
        phone2: this.parentData.phone2,
        address: this.parentData.address,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id
      }
      axios.post("/student/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.status == 204) {
            this.submitted = false;
            this.getSection(this.grade, {
              params: {
                campus_id: this.getUser.campus_id
              }
            });
            this.disabled = false;
            this.section_exist = "Section doesn't exist. Please choose other section.";
            return;
          }
          if (response.data.success == true) {
            this.$notification.success("Student Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "studentList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.disabled = false;
          console.log("Student Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/student/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getAllGrade();
    this.getAnnualFee();
    this.getMonthlyFee();
    this.getReligion();
    this.getNrcCity();
    this.getNrcNaing();
    this.getNrcTownship();
  },
};
