import { render, staticRenderFns } from "./CampusList.vue?vue&type=template&id=7951c11f&"
import script from "../../controllers/campus/campus-list.js?vue&type=script&lang=js&"
export * from "../../controllers/campus/campus-list.js?vue&type=script&lang=js&"
import style0 from "./CampusList.vue?vue&type=style&index=0&id=7951c11f&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports