import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from "@/store";

export default {
  name: "paymentDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      paymentData: [],
      campusId: store.state.user.campus_id,
      paymentMethodArr: null,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getDetail() {
      axios.get('payment/detail/' + this.$route.params.id, {
        params: {
          campus_id: store.state.user.campus_id
        }
      }).then(res => {
        if (res.data) {
          this.paymentData = res.data;
        }
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Payment Detail API Error", error);
      })
    },

    pdfDownload(studentName, paymentId) {
      var request;
      if (paymentId != null) {
        request = {
          params: {
            campus_id: this.campusId,
            payment_id: paymentId
          },
          responseType: 'blob'
        }
      } else {
        request = {
          params: {
            campus_id: this.campusId
          },
          responseType: 'blob'
        }
      }
      axios.get("/payment/pdfDownload/" + this.$route.params.id, request, {
          headers: {
            'Content-Type': 'application/pdf',
          }
        })
      .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            let date = new Date();
            const fileName = `invoice_${studentName}_${date.getFullYear()}${(date.getMonth() + 1) > 9 ? '' : '0'}${date.getMonth()+1}${(date.getDate()) > 9 ? '' : '0'}${date.getDate()}.pdf`;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
    this.getPaymentMethod();
  },
};
