import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapGetters
} from "vuex";
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "StudentList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      name: '',
      parentNo: '',
      grade_id: '',
      section_id: '',
      gender: '',
      year: '',
      selectedGrade: 0,
      selectedSection: 0,
      selectedGender: 0,
      selectedYear: 0,
      gradeArr: [],
      sectionArr: [{
        "id": 0,
        "text": "All"
      }],
      studentList: {},
      leavedStudentList: {},
      academicYearArr: null,
      genderArr: [{
          id: 0,
          text: 'All'
        },
        {
          id: 1,
          text: 'Male'
        },
        {
          id: 2,
          text: 'Female'
        }
      ],
      isLoading: false,
      search_status: false,
      leaved_search_status: false,
      submitted: false,
      invalidFile: false,
      disabled: false,
      templatelLink: '',
      studentFile: '',
      studentFileName: '',
      maxGradeId: 0,
      clickDisabled: false,
      checkAll: false,
      nextPageCheckAll: false,
      selectedCb: [],
      searchSelectedCb: [],
      unSelectedCb: [],
      idArray: [],
      passFailDisable: false,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  validations: {
    studentFile: {
      required
    }
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    selectAll() {
      this.selectedCb = [];
      if (!this.checkAll) {
        if(this.search_status) {
          this.searchSelectedCb = this.idArray;
          this.nextPageCheckAll = true;
          this.checkAll = true;
          // this.unSelectedCb = [];
        } else {
          this.selectedCb = this.idArray;
          this.nextPageCheckAll = true;
          this.checkAll = true;
          // this.unSelectedCb = [];
        }
      } else {        
        this.checkAll = false;
        this.nextPageCheckAll = false;
        this.selectedCb = [];
        this.searchSelectedCb = [];
      }
    },

    updateCheckall(e, sId){
      let maxCbTotal = this.studentList.total;
      if (!e.target.checked) {
        this.unSelectedCb = this.unSelectedCb.concat(sId);
        this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
      } else {
        const filtersList = this.unSelectedCb.filter(element => element !== sId);
        this.unSelectedCb = filtersList;
        this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
      }

      // if allcheck is clicked and then single uncheck ( checkAll is false and nexPage is all checked )
      if(!this.nextPageCheckAll) {
        if (this.search_status) {
          if(maxCbTotal == this.searchSelectedCb.length){
            this.checkAll = true;
          } else{
            this.checkAll = false;
          }
        } else {
          if(maxCbTotal == this.selectedCb.length){
            this.checkAll = true;
          } else{
            this.checkAll = false;
          }
        }
      } else {
        if(maxCbTotal == this.studentList.total  - this.unSelectedCb.length){
          this.checkAll = true;
        } else{
          this.checkAll = false;
        }
      }
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grade", request).then((response) => {
          let $defaultSelectOption = [{
            "id": 0,
            "text": "All"
          }];
          this.gradeArr = $defaultSelectOption.concat(response.data[0]);
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getMaxGradeByCampus() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grade/getMaxGradeIdByCampus", request).then((response) => {
          this.maxGradeId = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getStudentList(page = 1) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/student/list?page=" + page, request)
        .then(response => {
          this.search_status = false;
          this.studentList = response.data;
          this.idArray = [];
          this.studentList.data.forEach(e => {
            if(e.status !== 1) {
              this.idArray.push(e['id']);
            }   
            if (e.status === 1) {
              this.unSelectedCb.push(e['id']);
            }
          });

          if (this.checkAll || this.nextPageCheckAll) {
            this.unSelectedCb = new Set(this.unSelectedCb);
            let removedArr = this.idArray.filter((name) => {
              return !this.unSelectedCb.has(name);
            });

            this.selectedCb = this.selectedCb.concat(removedArr);
            this.selectedCb = [ ...new Set(this.selectedCb)] ;
            this.idArray = this.idArray.concat(this.idArray);
            this.idArray = [ ...new Set(this.idArray)];
            this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student List API Error", error);
        });
    },

    getLeavedStudentList(page = 1) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/student/leavedList?page=" + page, request)
        .then(response => {
          this.leaved_search_status = false;
          this.leavedStudentList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Leaved Student Lists API Error", error);
        });
    },

    searchStudent() {
      this.isLoading = true;
      this.searchSelectedCb = [];
      this.checkAll = false;
      this.unSelectedCb = [];
      this.idArray = [];
      this.nextPageCheckAll = false;
      this.searchActiveStudent();
      this.searchLeavedStudent();
    },

    searchActiveStudent(page = 1) {
      var request = {
        params: {
          name: this.name,
          parent_no: this.parentNo,
          grade_id: this.grade_id,
          section_id: this.section_id,
          gender: this.gender,
          year: this.year,
          campus_id: this.getUser.campus_id,
          leavedStatus: false
        }
      }
      axios.get("/student/search/result?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.studentList = response.data;
          this.idArray = [];
          this.studentList.data.forEach(e => {
            if(e.status !== 1) {
              this.idArray.push(e['id']);
            }   
            if (e.status === 1) {
              this.unSelectedCb.push(e['id']);
            }
          });

          if (this.checkAll || this.nextPageCheckAll) {

            this.unSelectedCb = new Set(this.unSelectedCb);
            let removedArr = this.idArray.filter((name) => {
              return !this.unSelectedCb.has(name);
            });

            this.searchSelectedCb = this.searchSelectedCb.concat(removedArr);
            this.searchSelectedCb = [ ...new Set(this.searchSelectedCb)] ;
            this.idArray = this.idArray.concat(this.idArray);
            this.idArray = [ ...new Set(this.idArray)];
            this.unSelectedCb = [ ...new Set(this.unSelectedCb)];
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Active Student Search API Error", error);
        });
    },

    searchLeavedStudent(page = 1) {
      var request = {
        params: {
          name: this.name,
          parent_no: this.parentNo,
          grade_id: this.grade_id,
          section_id: this.section_id,
          gender: this.gender,
          year: this.year,
          campus_id: this.getUser.campus_id,
          leavedStatus: true
        }
      }

      axios.get("/student/search/result?page=" + page, request)
        .then(response => {
          this.leaved_search_status = true;
          this.leavedStudentList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Leaved Student Search API Error", error);
        });
    },

    reset() {
      this.name = '';
      this.parentNo = '';
      this.selectedGrade = this.selectedSection = this.selectedGender = this.selectedYear = 0;
      this.grade_id = '';
      this.section_id = '';
      this.gender = '';
      this.year = '';
    },

    confirmDelete(id, parentId) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteStudent(id, parentId);
      }
    },

    deleteStudent(id, parentId) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.delete("/student/delete/" + id + '/' + parentId, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getStudentList();
            this.getLeavedStudentList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Delete Error->", error);
        });
    },

    confirmActivate(id, parentId) {
      if (window.confirm("Are you sure you want to activate?")) {
        this.activateStudent(id, parentId);
      }
    },

    activateStudent(id, parentId) {
      axios.delete("/student/activate/" + id + '/' + parentId)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getStudentList();
            this.getLeavedStudentList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Activate Error->", error);
        });
    },

    setPassFail(idArr, result) {
      this.passFailDisable = true;
      this.isLoading = true;
      var request = {
        search_status: this.search_status,
        checkAll: this.checkAll,
        nextPageCheckAll : this.nextPageCheckAll,
        unSelectedCb: this.unSelectedCb,
        student_ids: idArr,
        result: result,
        campusMaxGrade: this.maxGradeId,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id
      }

      if (this.search_status) {
        var searchRequest = {
            name: this.name,
            parent_no: this.parentNo,
            grade_id: this.grade_id,
            section_id: this.section_id,
            gender: this.gender,
            year: this.year,
            campus_id: this.getUser.campus_id,
            leavedStatus: false
        }
        request = Object.assign( {}, searchRequest, request );
      }

      axios.post("/student/setPassFail", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.status == 204) {
            this.submitted = false;
            this.getSection(this.grade, {
              params: {
                campus_id: this.getUser.campus_id
              }
            });
            this.passFailDisable = false;
            this.isLoading = false;
            return;
          }
          if (response.data.success == true) {
            this.isLoading = false;
            this.passFailDisable = false;
            this.checkAll = false;
            this.selectedCb = [];
            if(this.search_status) {
              this.searchStudent();
            } else {
              this.getStudentList();
              this.getLeavedStudentList();
            }
            this.checkAll = false;
            this.selectedCb = [];
            this.$notification.success("Student Pass/Fail Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
          } else {
            this.isLoading = false;
            this.passFailDisable = false;
            alert(response.data.msg);
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          this.passFailDisable = false;
          console.log("Student Pass/Fail API Error", error.errors);
        });

    },

    showCreate() {
      this.$router.push({
        path: "/student/create"
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "studentEdit",
        params: {
          id: id
        }
      });
    },

    showDetail(id, leavedStatus) {
      this.$router.push({
        name: "studentProfile",
        params: {
          id: id,
          leavedStatus: leavedStatus
        }
      });
    },

    showEditToPass(id, currentGradeId) {
      if (currentGradeId == this.maxGradeId) {

        if (window.confirm("Are you sure you want to update this student pass?")) {
          this.clickDisabled = true;
          var request = {
            update_student_id: id,
            campus_id: this.getUser.campus_id,
            updated_id: this.getUser.id
          }

          axios.post("/student/finalGrade/pass", request, {
              headers: {
                'Content-Type': 'application/json',
              }
            })
            .then(response => {
              if (response.data.success == true) {
                this.clickDisabled = false;
                this.isLoading = true;
                this.getStudentList();
                this.getLeavedStudentList();
                this.$notification.success("Setting Student Pass Successfully", {
                  timer: 3,
                  position: "bottomCenter"
                });
              } else {
                this.clickDisabled = false;
                this.$notification.error(response.data.message, {
                  timer: 3,
                  position: "bottomCenter"
                });
              }
            })
            .catch(error => {
              if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.clickDisabled = false;
              this.isLoading = false;
              console.log("Setting Student Pass API Error", error.errors);
            });
        }
      } else {
        this.$router.push({
          name: "studentPass",
          params: {
            id: id
          }
        });
      }
    },

    showEditToFail(id) {
      this.$router.push({
        name: "studentFail",
        params: {
          id: id
        }
      });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);

      if (fieldName == 'grade_id') {
        // this.isLoading = true;
        this.sectionArr = [];
        this.section_id = '';
        this.selectedSection = '';
        this.getSection(this.grade_id, this.year);
      }
    },

    getSection($gradeId, $yearId) {
      axios.get("class/sectionList", {
          params: {
            grade_id: $gradeId,
            year_id: $yearId,
            campus_id: this.getUser.campus_id
          }
        })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'All'
          }];
          this.sectionArr = $defaultSelectOption.concat(res.data);
          this.selectedSection = 0;
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "login"
            });
          }
          this.isLoading = false;
        });
    },

    getTemplateLink() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("student/getStudentTemplateLink", request, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        this.templatelLink = res.data;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    chooseFile() {
      this.$refs.fileUploader.value = '';
      document.getElementById('student-upload').click();
    },

    onStudentFileChange() {
      this.invalidFile = false;
      let selectedFile = document.getElementById('student-upload').files;
      if (selectedFile.length) {
        let file = selectedFile[0];
        if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.studentFileName = file.name;
          this.studentFile = file;
        } else {
          this.invalidFile = true;
        }
      }
    },

    importFile() {
      this.submitted = true;
      this.disabled = true;
      if (this.$v.$invalid) {
        this.disabled = false;
        return;
      } else {
        this.isLoading = true;
        let data = new FormData();
        data.append('student_xlsx', this.studentFile);
        data.append('campus_id', this.getUser.campus_id);
        data.append('created_id', this.getUser.id);
        axios.post("student/importStudent", data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          if (response.data.success == true) {
            this.$notification.success(response.data.msg, {
              timer: 3,
              position: "bottomCenter"
            });
            this.disabled = false;
            this.getStudentList();
            this.getLeavedStudentList();
            this.clearFile();
          } else {
            this.isLoading = false;
            this.disabled = false;
            alert(response.data.msg);
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
      }
    },

    clearFile() {
      this.studentFile = '';
      this.studentFileName = '';
      document.getElementById('student-upload').value = '';
      this.submitted = false;
      this.disabled = false;
      this.invalidFile = false;
    },

    excelDownload(status) {
      axios.get('/student/excelDownload', {
        params: {
          name: this.name,
          parent_no: this.parentNo,
          grade_id: this.grade_id,
          section_id: this.section_id,
          gender: this.gender,
          year: this.year,
          campus_id: this.getUser.campus_id,
          status: status
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', status + `_student_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
  },
  
  mounted() {
    this.isLoading = true;
    this.getMaxGradeByCampus();
    this.getTemplateLink();
    this.getAllGrade();
    this.getStudentList();
    this.getLeavedStudentList();
    this.getAcademicYear();
  },
};
