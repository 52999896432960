import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import {
  required,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import {
  mapGetters
} from "vuex"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "classCreate",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      classData: {},
      selectedGrade: 0,
      selectedYear: 0,
      gradeArr: [],
      academic_year: '',
      grade_id: '',
      academicYearArr: null,
      submitted: false,
      disabled: false,
      gradeNotExist: ''
    };
  },

  validations: {
    grade_id: {
      required,
      numeric
    },
    academic_year: {
      required
    },
    classData: {
      section: {
        required,
        maxLength: maxLength(20)
      },
      remark: {
        maxLength: maxLength(1000)
      },
    },
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grade", request).then((response) => {
        let $defaultSelectOption = [{
          "id": 0,
          "text": "Select"
        }];
        this.gradeArr = $defaultSelectOption.concat(response.data[0]);
        this.isLoading = false;
      })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    confirmRegister() {
      this.submitted = true;
      if (this.$v.$invalid || this.grade_id == '' || this.grade_id == 0 || this.academic_year == '' || this.academic_year == 0) {
        return;
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        grade_id: this.grade_id,
        academic_year: this.academic_year,
        section: this.classData.section,
        remark: this.classData.remark,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id
      }

      axios.post("/class/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data) {
            this.$notification.success("Class Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            if (this.$route.query.rd_back == 'student') {
              this.$router.push({
                name: "studentCreate"
              });
            } else {
              this.$router.push({
                name: "classList"
              });
            }
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          console.log("Class Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/class/list"
      });
    },
  },

  mounted() {
    this.getAllGrade();
    this.isLoading = true;
    this.getAcademicYear();
  },
};
