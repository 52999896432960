import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from "advanced-laravel-vue-paginate";
import "advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  mapGetters
} from "vuex";

export default {
  name: "TeacherList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      name: "",
      teacherNo: "",
      gender: "",
      start_date: "",
      end_date: "",
      selectedGender: 0,
      genderArr: [
        {
          id: 0,
          text: "All",
        },
        {
          id: 1,
          text: "Male",
        },
        {
          id: 2,
          text: "Female",
        },
      ],
      activeTeacherList: {},
      leavedTeacherList: {},
      isLoading: false,
      search_status: false,
      leaved_search_status: false,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getActiveTeacherList(page = 1) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/teacher/list?page=" + page, request)
        .then((response) => {
          this.search_status = false;
          this.activeTeacherList = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Active Teacher List API Error", error);
        });
    },

    getLeavedTeacherList(page = 1) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/teacher/leavedList?page=" + page, request)
        .then((response) => {
          this.leaved_search_status = false;
          this.leavedTeacherList = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Leaved Teacher Lists API Error", error);
        });
    },

    searchTeacher() {
      this.isLoading = true;
      this.searchActiveTeacher();
      this.searchLeavedTeacher();
    },

    searchActiveTeacher(page = 1) {
      var request = {
        params: {
          name: this.name,
          teacher_no: this.teacherNo,
          gender: this.gender,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: this.getUser.campus_id,
          leavedStatus: false
        },
      };
      axios
        .get("/teacher/search/result?page=" + page, request)
        .then((response) => {
          this.search_status = true;
          this.activeTeacherList = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Teacher Active Search API Error", error);
        });
    },

    searchLeavedTeacher(page = 1) {
      var request = {
        params: {
          name: this.name,
          teacher_no: this.teacherNo,
          gender: this.gender,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: this.getUser.campus_id,
          leavedStatus: true
        },
      };
      axios
        .get("/teacher/search/result?page=" + page, request)
        .then((response) => {
          this.leaved_search_status = true;
          this.leavedTeacherList = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Leaved Search API Error", error);
        });
    },

    reset() {
      this.name = "";
      this.teacherNo = "";
      this.selectedGender = 0;
      this.gender = "";
      this.start_date = "";
      this.end_date = "";
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteTeacher(id);
      } 
    },

    deleteTeacher(id) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.delete("/teacher/delete/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getActiveTeacherList();
            this.getLeavedTeacherList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Teacher Delete Error->", error);
        });
    },

    confirmActivate(id) {
      if (window.confirm("Are you sure you want to activate?")) {
        this.activateTeacher(id);
      }
    },

    activateTeacher(id) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.delete("/teacher/activate/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getActiveTeacherList();
            this.getLeavedTeacherList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Activate Error->", error);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/teacher/create",
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "teacherEdit",
        params: {
          id: id,
        },
      });
    },

    showDetail(id) {
      this.$router.push({
        name: "teacherProfile",
        params: {
          id: id
        }
      });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    excelDownload(status) {
      axios.get('/teacher/excelDownload', {
        params: {
          name: this.name,
          teacher_no: this.teacherNo,
          gender: this.gender,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: this.getUser.campus_id,
          status: status
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', status + `_teacher_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "login"
            });
          }
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getActiveTeacherList();
    this.getLeavedTeacherList();
  },
};
