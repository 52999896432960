import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "FerryDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      pickupData: [],
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    getDetail() {
      axios.get(`ferryPickup/detail/${this.$route.params.id}`, { 
        params: { 
          campus_id: this.$store.state.user.campus_id, 
          user_type: this.$route.params.status 
        } })
        .then((res) => {
          if (res.data) {
            this.pickupData = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
