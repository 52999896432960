import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/components/HomeIndex";
import Login from "@/components/LoginPage";
import SchoolPage from "@/components/SchoolPage";
import UpdatePassword from "@/components/UpdatePassword";
import AdminList from "@/components/admin/AdminList";
import AdminCreate from "@/components/admin/AdminCreate";
import AdminDetail from "@/components/admin/AdminDetail";
import AdminEdit from "@/components/admin/AdminEdit";
import AttendanceStudentList from "@/components/attendance_student/AttendanceStudentList";
import AttendanceStudentCreate from "@/components/attendance_student/AttendanceStudentCreate";
import AttendanceStudentDetail from "@/components/attendance_student/AttendanceStudentDetail";
import AttendanceStudentEdit from "@/components/attendance_student/AttendanceStudentEdit";
import AttendanceTeacherList from "@/components/attendance_teacher/AttendanceTeacherList";
import AttendanceTeacherCreate from "@/components/attendance_teacher/AttendanceTeacherCreate";
import AttendanceTeacherDetail from "@/components/attendance_teacher/AttendanceTeacherDetail";
import AttendanceTeacherEdit from "@/components/attendance_teacher/AttendanceTeacherEdit";
import CampusList from "@/components/campus/CampusList";
import CampusCreate from "@/components/campus/CampusCreate";
import CampusDetail from "@/components/campus/CampusDetail";
import CampusEdit from "@/components/campus/CampusEdit";
import ClassList from "@/components/class/ClassList";
import ClassCreate from "@/components/class/ClassCreate";
import ClassDetail from "@/components/class/ClassDetail";
import ClassEdit from "@/components/class/ClassEdit";
import InOutcomeList from "@/components/in_outcome/InOutcomeList";
import InOutcomeCreate from "@/components/in_outcome/InOutcomeCreate";
import InOutcomeDetail from "@/components/in_outcome/InOutcomeDetail";
import InOutcomeEdit from "@/components/in_outcome/InOutcomeEdit";
import NoticeList from "@/components/notice/NoticeList";
import NoticeCreate from "@/components/notice/NoticeCreate";
import NoticeDetail from "@/components/notice/NoticeDetail";
import NoticeEdit from "@/components/notice/NoticeEdit";
import ItemList from "@/components/item/ItemList";
import ItemCreate from "@/components/item/ItemCreate";
import ItemDetail from "@/components/item/ItemDetail";
import ItemEdit from "@/components/item/ItemEdit";
import PaymentList from "@/components/payment/PaymentList";
import PaymentCreate from "@/components/payment/PaymentCreate";
import PaymentDetail from "@/components/payment/PaymentDetail";
import PaymentEdit from "@/components/payment/PaymentEdit";
import PaymentCheckout from "@/components/payment/PaymentCheckout";
import StudentList from "@/components/student/StudentList";
import StudentCreate from "@/components/student/StudentCreate";
import StudentProfile from "@/components/student/StudentProfile";
import StudentEdit from "@/components/student/StudentEdit";
import StudentPass from "@/components/student/StudentPass";
import StudentFail from "@/components/student/StudentFail";
import TeacherList from "@/components/teacher/TeacherList";
import TeacherCreate from "@/components/teacher/TeacherCreate";
import TeacherProfile from "@/components/teacher/TeacherProfile";
import TeacherEdit from "@/components/teacher/TeacherEdit";
import SubjectAssignList from "@/components/subject_assign/SubjectAssignList";
import SubjectAssignDetail from "@/components/subject_assign/SubjectAssignDetail";
import SubjectAssignEdit from "@/components/subject_assign/SubjectAssignEdit";
import SubjectAssignCreate from "@/components/subject_assign/SubjectAssignCreate";
import TimetableList from "@/components/timetable/TimetableList";
import TimetableCreate from "@/components/timetable/TimetableCreate";
import TimetableDetail from "@/components/timetable/TimetableDetail";
import TimetableEdit from "@/components/timetable/TimetableEdit";
import GradingList from "@/components/grading/GradingList";
import GradingCreate from "@/components/grading/GradingCreate";
import GradingDetail from "@/components/grading/GradingDetail";
import GradingEdit from "@/components/grading/GradingEdit";
import SubjectList from "@/components/subject/SubjectList";
import SubjectCreate from "@/components/subject/SubjectCreate";
import SubjectEdit from "@/components/subject/SubjectEdit";
import SubjectDetail from "@/components/subject/SubjectDetail";
import GradeList from "@/components/grade/GradeList";
import GradeCreate from "@/components/grade/GradeCreate";
import GradeEdit from "@/components/grade/GradeEdit";
import GradeDetail from "@/components/grade/GradeDetail";
import DriverList from "@/components/driver/DriverList";
import DriverCreate from "@/components/driver/DriverCreate";
import DriverEdit from "@/components/driver/DriverEdit";
import DriverDetail from "@/components/driver/DriverDetail";
import FerryCreate from "@/components/ferry/FerryCreate";
import FerryList from "@/components/ferry/FerryList";
import FerryEdit from "@/components/ferry/FerryEdit";
import FerryDetail from "@/components/ferry/FerryDetail";
import RouteList from "@/components/route/RouteList";
import RouteCreate from "@/components/route/RouteCreate";
import RouteEdit from "@/components/route/RouteEdit";
import RouteDetail from "@/components/route/RouteDetail";
import RoutePointList from "@/components/route_point/RoutePointList";
import RoutePointCreate from "@/components/route_point/RoutePointCreate";
import RoutePointEdit from "@/components/route_point/RoutePointEdit";
import RoutePointDetail from "@/components/route_point/RoutePointDetail";
import DailyFerryList from "@/components/daily_ferry/DailyFerryList";
import DailyFerryCreate from "@/components/daily_ferry/DailyFerryCreate";
import DailyFerryEdit from "@/components/daily_ferry/DailyFerryEdit";
import DailyFerryDetail from "@/components/daily_ferry/DailyFerryDetail";
import NotFound from '@/components/NotFound'; 
import FerryPickupList from "@/components/ferry_pickup/FerryPickupList";
import FerryPickupDetail from "@/components/ferry_pickup/FerryPickupDetail";
import FerryPickupEdit from "@/components/ferry_pickup/FerryPickupEdit";
import store from '@/store/index.js';

Vue.use(VueRouter);

const routes = [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: { restricted: "superAdmin" }
    },

    {
      path: "/login",
      name: "login",
      component: Login,
    },

    {
      path: "/school/:id",
      name: "school",
      component: SchoolPage,
    },

    {
      path: "/updatePassword",
      name: "updatePassword",
      component: UpdatePassword,
    },

    {
      path: "/admin/list",
      name: "adminList",
      component: AdminList,
      meta: { restricted: "staff" }
    },

    {
      path: "/admin/create",
      name: "adminCreate",
      component: AdminCreate,
      meta: { restricted: "staff" }
    },

    {
      path: "/admin/detail/:id",
      name: "adminDetail",
      component: AdminDetail,
      meta: { restricted: "staff" }
    },

    {
      path: "/admin/edit/:id",
      name: "adminEdit",
      component: AdminEdit,
      meta: { restricted: "staff" }
    },

    {
      path: "/attendanceStudent/list",
      name: "attendanceStudentList",
      component: AttendanceStudentList,
    },

    {
      path: "/attendanceStudent/create",
      name: "attendanceStudentCreate",
      component: AttendanceStudentCreate,
    },

    {
      path: "/attendanceStudent/detail/:id",
      name: "attendanceStudentDetail",
      component: AttendanceStudentDetail,
    },

    {
      path: "/attendanceStudent/edit/:id",
      name: "attendanceStudentEdit",
      component: AttendanceStudentEdit,
    },

    {
      path: "/attendanceTeacher/list",
      name: "attendanceTeacherList",
      component: AttendanceTeacherList,
    },

    {
      path: "/attendanceTeacher/create",
      name: "attendanceTeacherCreate",
      component: AttendanceTeacherCreate,
    },

    {
      path: "/attendanceTeacher/detail/:id",
      name: "attendanceTeacherDetail",
      component: AttendanceTeacherDetail,
    },

    {
      path: "/attendanceTeacher/edit/:id",
      name: "attendanceTeacherEdit",
      component: AttendanceTeacherEdit,
    },

    {
      path: "/campus/list",
      name: "campusList",
      component: CampusList,
      meta: { restricted: "admin" }
    },

    {
      path: "/campus/create",
      name: "campusCreate",
      component: CampusCreate,
      meta: { restricted: "admin" }
    },

    {
      path: "/campus/detail/:id",
      name: "campusDetail",
      component: CampusDetail,
      meta: { restricted: "admin" }
    },

    {
      path: "/campus/edit/:id",
      name: "campusEdit",
      component: CampusEdit,
      meta: { restricted: "admin" }
    },

    {
      path: "/class/list",
      name: "classList",
      component: ClassList,
    },

    {
      path: "/class/create",
      name: "classCreate",
      component: ClassCreate,
    },

    {
      path: "/class/detail/:id",
      name: "classDetail",
      component: ClassDetail,
    },

    {
      path: "/class/edit/:id",
      name: "classEdit",
      component: ClassEdit,
    },

    {
      path: "/inOutcome/list",
      name: "inOutcomeList",
      component: InOutcomeList,
      meta: { restricted: "staff" }
    },

    {
      path: "/inOutcome/create",
      name: "inOutcomeCreate",
      component: InOutcomeCreate,
      meta: { restricted: "staff" }
    },

    {
      path: "/inOutcome/detail/:status/:id",
      name: "inOutcomeDetail",
      component: InOutcomeDetail,
      props: true,
      meta: { restricted: "staff" }
    },

    {
      path: "/inOutcome/edit/:status/:id",
      name: "inOutcomeEdit",
      component: InOutcomeEdit,
      meta: { restricted: "staff" }
    },

    {
      path: "/notice/list",
      name: "noticeList",
      component: NoticeList,
    },

    {
      path: "/notice/create",
      name: "noticeCreate",
      component: NoticeCreate,
    },

    {
      path: "/notice/detail/:id",
      name: "noticeDetail",
      component: NoticeDetail,
    },

    {
      path: "/notice/edit/:id",
      name: "noticeEdit",
      component: NoticeEdit,
    },
    {
      path: "/item/list",
      name: "itemList",
      component: ItemList,
      meta: { restricted: "staff" }
    },
    {
      path: "/item/create",
      name: "itemCreate",
      component: ItemCreate,
      meta: { restricted: "staff" }
    },

    {
      path: "/item/detail/:id",
      name: "itemDetail",
      component: ItemDetail,
      meta: { restricted: "staff" }
    },

    {
      path: "/item/edit/:id",
      name: "itemEdit",
      component: ItemEdit,
      meta: { restricted: "staff" }
    },
    {
      path: "/payment/list",
      name: "paymentList",
      component: PaymentList,
      meta: { restricted: "staff" }
    },

    {
      path: "/payment/create",
      name: "paymentCreate",
      component: PaymentCreate,
      meta: { restricted: "staff" }
    },

    {
      path: "/payment/detail/:id",
      name: "paymentDetail",
      component: PaymentDetail,
      meta: { restricted: "staff" }
    },

    {
      path: "/payment/edit/:id",
      name: "paymentEdit",
      component: PaymentEdit,
      meta: { restricted: "staff" }
    },

    {
      path: "/payment/checkout",
      name: "paymentCheckout",
      component: PaymentCheckout,
    },

    {
      path: "/student/list",
      name: "studentList",
      component: StudentList,
    },

    {
      path: "/student/create",
      name: "studentCreate",
      component: StudentCreate,
    },

    {
      path: "/student/profile/:leavedStatus/:id",
      name: "studentProfile",
      component: StudentProfile,
    },

    {
      path: "/student/edit/:id",
      name: "studentEdit",
      component: StudentEdit,
    },

    {
      path: "/student/pass/:id",
      name: "studentPass",
      component: StudentPass,
    },

    {
      path: "/student/fail/:id",
      name: "studentFail",
      component: StudentFail,
    },

    {
      path: "/teacher/list",
      name: "teacherList",
      component: TeacherList,
    },

    {
      path: "/teacher/create",
      name: "teacherCreate",
      component: TeacherCreate,
    },

    {
      path: "/teacher/profile/:id",
      name: "teacherProfile",
      component: TeacherProfile,
    },

    {
      path: "/teacher/edit/:id",
      name: "teacherEdit",
      component: TeacherEdit,
    },

    {
      path: "/subjectAssign/list",
      name: "subjectAssignList",
      component: SubjectAssignList,
    },

    {
      path: "/subjectAssign/detail/:id",
      name: "subjectAssignDetail",
      component: SubjectAssignDetail,
    },

    {
      path: "/subjectAssign/edit/:id",
      name: "subjectAssignEdit",
      component: SubjectAssignEdit,
    },

    {
      path: "/subjectAssign/create",
      name: "subjectAssignCreate",
      component: SubjectAssignCreate,
    },

    {
      path: "/timetable/list",
      name: "timetableList",
      component: TimetableList,
    },

    {
      path: "/timetable/create",
      name: "timetableCreate",
      component: TimetableCreate,
    },

    {
      path: "/timetable/detail/:id",
      name: "timetableDetail",
      component: TimetableDetail,
    },

    {
      path: "/timetable/edit/:id",
      name: "timetableEdit",
      component: TimetableEdit,
    },
    {
      path: "/grading/list",
      name: "gradingList",
      component: GradingList,
    },
    {
      path: "/grading/create",
      name: "gradingCreate",
      component: GradingCreate,
    },
    {
      path: "/grading/detail/:student_id/:time",
      name: "gradingDetail",
      component: GradingDetail,
    },
    {
      path: "/grading/edit/:student_id/:time",
      name: "gradingEdit",
      component: GradingEdit,
    },
    {
      path: "/subject/list",
      name: "subjectList",
      component: SubjectList,
    },
    {
      path: "/subject/create",
      name: "subjectCreate",
      component: SubjectCreate,
    },
    {
      path: "/subject/edit/:grade_id",
      name: "subjectEdit",
      component: SubjectEdit,
    },
    {
      path: "/subject/detail/:grade_id",
      name: "subjectDetail",
      component: SubjectDetail,
    },
    {
      path: "/grade/list",
      name: "gradeList",
      component: GradeList,
    },
    {
      path: "/grade/create",
      name: "gradeCreate",
      component: GradeCreate,
    },
    {
      path: "/grade/edit/:id",
      name: "gradeEdit",
      component: GradeEdit,
    }, 
    {
      path: "/grade/detail/:id",
      name: "gradeDetail",
      component: GradeDetail,
    },
    {
      path: "/driver/list",
      name: "driverList",
      component: DriverList,
    },
    {
      path: "/driver/create",
      name: "driverCreate",
      component: DriverCreate,
    },
    {
      path: "/driver/edit/:id",
      name: "driverEdit",
      component: DriverEdit,
    },
    {
      path: "/driver/detail/:id",
      name: "driverDetail",
      component: DriverDetail,
    },
    {
      path: "/ferry/create",
      name: "ferryCreate",
      component: FerryCreate,
    }, 
    {
      path: "/ferry/list",
      name: "ferryList",
      component: FerryList,
    },
    {
      path: "/ferry/edit/:id",
      name: "ferryEdit",
      component: FerryEdit,
    },
    {
      path: "/ferry/detail/:id",
      name: "ferryDetail",
      component: FerryDetail,
    },
    {
      path: "/route/list",
      name: "routeList",
      component: RouteList,
    },
    {
      path: "/route/create",
      name: "routeCreate",
      component: RouteCreate,
    },
    {
      path: "/route/edit/:id",
      name: "routeEdit",
      component: RouteEdit,
    },
    {
      path: "/route/detail/:id",
      name: "routeDetail",
      component: RouteDetail,
    },
    {
      path: "/routePoint/list",
      name: "routePointList",
      component: RoutePointList,
    },
    {
      path: "/routePoint/create",
      name: "routePointCreate",
      component: RoutePointCreate,
    },
    {
      path: "/routePoint/edit/:id",
      name: "routePointEdit",
      component: RoutePointEdit,
    },
    {
      path: "/routePoint/detail/:id",
      name: "routePointDetail",
      component: RoutePointDetail,
    },
    {
      path: "/dailyFerry/list",
      name: "dailyFerryList",
      component: DailyFerryList,
    },
    {
      path: "/dailyFerry/create",
      name: "dailyFerryCreate",
      component: DailyFerryCreate,
    },
    {
      path: "/dailyFerry/edit/:id",
      name: "dailyFerryEdit",
      component: DailyFerryEdit,
    },
    {
      path: "/dailyFerry/detail/:id",
      name: "dailyFerryDetail",
      component: DailyFerryDetail,
    },
    {
      path: "/ferryPickup/list",
      name: "ferryPickupList",
      component: FerryPickupList,
    },
    {
      path: "/ferryPickup/detail/:status/:id",
      name: "ferryPickupDetail",
      component: FerryPickupDetail,
      props: true,
    },

    {
      path: "/ferryPickup/edit/:status/:id",
      name: "ferryPickupEdit",
      component: FerryPickupEdit
    },
    {
      path: "*",
      name: "notFound",
      component: NotFound
    }
]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
  });

router.beforeEach((to, from, next) => {
  let authUser = store.state.user.admin_no;
  if (to.name !== 'login' && !authUser) next({ name: 'login' })
  else next()
})

router.beforeEach((to, from, next) => {
  let authUser = store.state.user.role;
  let adminUser = store.state.user.admin_no;
  if (to.meta.restricted) {
    if (authUser === 2) {
      if (to.meta.restricted == 'staff') {
        next({ name: 'dashboard' });
      } else {
        next();
      }
    } else if (adminUser != "0001") {
      if (to.meta.restricted === "admin") {
        next({ name: 'dashboard' });
      } else {
        next();
      }
    } else if (adminUser == "0001") {
      if (to.meta.restricted === "superAdmin") {
        next({ name: 'adminList' });
      } else {
        next();
      }
    }
    else {
      next();
    }
  } else {
    next();
  }
});

export default router;
