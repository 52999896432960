import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Select2Multiple from "v-select2-multiple-component";
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import { required, maxLength, minLength, email, numeric } from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters } from "vuex";
import { ConvertNumService } from '@/assets/js/convertNumberService.js';
import CONFIG from "@/assets/js/config.js";

const convertNumberService = new ConvertNumService();

export default {
  name: "TeacherCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      config : { 
        maxDate: new Date().getFullYear() + '-' + (new Date().getMonth() + 1)+ '-' + (new Date().getDate() -1)
      },
      dob: "",
      join_date: "",
      teacherImage: "",
      nrcFrontImage: "",
      nrcBackImage: "",
      teacherData: {},
      selected_value: 0,
      selectedTeacherType: 0,
      selectedReligion: 0,
      teacher_type: "",
      religion: "",
      religionArr: null,
      teacherTypeArr: null,
      nrcNameJson: null,
      naingJson: null,
      nrcno: null,
      nrc_number: "",
      nrc_only_number: "",
      nrc: "",
      nrckey: "",
      selectedNrcName: "",
      selectedNrcNaing: "",
      districtList: [],
      naingList: [],
      nrcName: [],
      submitted: false,
      disabled: false
    };
  },

  validations: {
    teacherImage: {},
    teacher_type: {
      required,
    },
    religion: {},
    teacherData: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
      confirm_password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
      dob: {
        required,
        maxLength: maxLength(100),
      },
      gender: {
        required,
      },
      father_name: {
        required,
        maxLength: maxLength(50),
      },
      marital_status: {
        required,
      },
      nrc_number: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      nationality: {
        required,
        maxLength: maxLength(100),
      },
      education_background: {
        maxLength: maxLength(1000)
      },
      other_certificate: {
        maxLength: maxLength(1000)
      },
      work_experience: {
        maxLength: maxLength(1000)
      },
      join_date: {
        required,
      },
      email: {
        maxLength: maxLength(50),
        email
      },
      phone1: {
        required,
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      phone2: {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(11)
      },
      contact_address: {
        required,
        maxLength: maxLength(1000)
      },
      permanent_address: {
        maxLength: maxLength(1000)
      },
    },
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcCity() {
      axios.get('getNrcCity')
        .then(res => {
          this.nrcno = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcNaing() {
      axios.get('getNrcNaing')
        .then(res => {
          this.naingJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getNrcTownship() {
      axios.get('getNrcTownship')
        .then(res => {
          this.nrcNameJson = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getTeacherType() {
      axios.get('getTeacherType')
        .then(res => {
          this.teacherTypeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    onChange(event) {
      var optionText = event.target.value;
      var convertNumberService = new ConvertNumService();
      var optionValue = convertNumberService.convertMm2Eng(optionText);
      if (optionValue) {
          this.nrckey = optionText;
          const placeholder = document.getElementById('nrc_placeholder');
          placeholder.style.display = 'none'
      } else {
          this.nrckey = optionValue;
      }
      this.selectedNrcName = "";
      this.districtList = this.changeNrcName(optionValue);
    },

    changeNrcName(value, nrcName) {
      nrcName = this.nrcNameJson[value];
      return nrcName;
    },

    onChangeNrcName(event) {
      var optionValue = event.target.value;
      if(!optionValue){
        this.selectedNrcNaing = ""
        this.teacherData.nrc_number = ""
      }
      this.naingList = this.naingJson;
    },

    checkNRC() {
      if (
          this.nrckey !== undefined &&
          this.selectedNrcName !== undefined &&
          this.selectedNrcNaing !== undefined &&
          this.teacherData.nrc_number !== ""
      ) {
          return true;
      } else if (
          this.nrckey == undefined &&
          this.selectedNrcName == undefined &&
          this.selectedNrcNaing == undefined &&
          this.teacherData.nrc_number == ""
      ) {
          return true;
      } else return false;
    },

    mergeNRC() {
      return convertNumberService.convertEng2Mm(
          this.nrckey +
          "/" +
          this.selectedNrcName +
          this.selectedNrcNaing +
          this.teacherData.nrc_number
      );
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img,'');
      document.getElementById(img).value = '';
    },

    confirmRegister() {
      this.submitted = true;
      if (
        this.$v.$invalid ||
        this.teacher_type == 0 ||
        this.teacher_type == "" || this.checkNRC() == false
      ) {
        return;
      } else if (
        this.teacherData.password != this.teacherData.confirm_password
      ) {
        this.$notification.error(
          "Password and confirm password do not match.",
          {
            timer: 3,
            position: "bottomCenter",
          }
        );
        return;
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        name: this.teacherData.name,
        teacher_type: this.teacher_type,
        profileImage: this.teacherImage,
        nrcFrontImage: this.nrcFrontImage,
        nrcBackImage: this.nrcBackImage,
        religion: this.religion,
        password: this.teacherData.password,
        dob: this.teacherData.dob,
        gender: this.teacherData.gender,
        father_name: this.teacherData.father_name,
        marital_status: this.teacherData.marital_status,
        nrc_number: this.mergeNRC(),
        nationality: this.teacherData.nationality,
        education_background: this.teacherData.education_background,
        other_certificate: this.teacherData.other_certificate,
        work_experience: this.teacherData.work_experience,
        join_date: this.teacherData.join_date,
        email: this.teacherData.email,
        phone1: this.teacherData.phone1,
        phone2: this.teacherData.phone2,
        contact_address: this.teacherData.contact_address,
        permanent_address: this.teacherData.permanent_address,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id,
      };

      axios
        .post("/teacher/create", request, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.success == true) {
            this.$notification.success("Teacher Added Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.$router.push({
              name: "teacherList",
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
          console.log("Teacher Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/teacher/list",
      });
    },
  },
  mounted() {
    this.getReligion();
    this.getNrcCity();
    this.getNrcNaing();
    this.getNrcTownship();
    this.getTeacherType();
  },
};
