import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  // maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';
import Loading from "vue-loading-overlay";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "ferryEdit",
  components: {
    MainLayout,
    Loading,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      ferryData: [],
      ferry_img: '',
      submitted: false,
      disabled: false,
      isLoading: false,
      inputError: null,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    ferryData: {
      status: {
        required
      },
      ferry_no: {
        required,
        // maxLength: maxLength(10)
      },
      total_seats: {
        required
      }
    },

  },

  computed: {
    isValidInput() {
      return this.ferryData.total_seats <= 44 && this.ferryData.total_seats > 0;
    },
  },

  methods: {
    checkLength(event) {
      if (event.target.value.length >= 7) {
        event.preventDefault();
      }
    },

    validateInput() {
      if (this.isValidInput) {
        this.inputError = null;
      } else {
        this.inputError = 'Please enter a valid number between 1 and 44.';
      }
    },

    getDetail() {
      axios.get(`ferry/detail/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.ferryData = res.data.data;
            this.ferry_img = this.ferryData.img_path;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    uploadImage(id) {
      const inputfile = document.getElementById(id).files[0];
      if (inputfile) {
        if (inputfile.type.includes("image/")) {
          if (inputfile.type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.readAsDataURL(inputfile);
          reader.onloadend = () => {
            if (reader.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.$set(this, id, reader.result);
            }
          };
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    removeImg(img) {
      this.$set(this, img, '');
      document.getElementById(img).value = '';
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      this.disabled = true;
      this.ferryData.loginId = this.$store.state.user.id;
      this.ferryData.ferryImg = this.ferry_img;
      this.ferryData.oldImg = this.ferryData.ferry_img;

      axios.post(`ferry/update/${this.$route.params.id}`, this.ferryData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Ferry Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "ferryList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },

};
