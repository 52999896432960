import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
  required,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import {
  mapGetters
} from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "classEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      disabled: false,
      classData: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      academicYearArr: null,
      gradeArr: [],
      selectedGrade: 0,
      selectedYear: 0,
      academic_year: '',
      grade_id: '',
      submitted: false,
      gradeNotExist:''
    };
  },

  validations: {
    grade_id: {
      required,
      numeric
    },
    academic_year: {
      required
    },
    classData: {
      section: {
        required,
        maxLength: maxLength(20)
      },
      remark: {
        maxLength: maxLength(1000)
      },
    },
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    getClassEdit() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/class/detail/" + this.$route.params.id, request)
        .then(response => {
          this.classData = response.data;
          this.selectedGrade = this.grade_id = this.classData.grade_id;
          this.academic_year = this.selectedYear = this.classData.academic_year;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class Detail API Error", error);
        });
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/grade", request).then((response) => {
          let $defaultSelectOption = [{
            "id": 0,
            "text": "Select"
          }];
          this.gradeArr = $defaultSelectOption.concat(response.data[0]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    confirmUpdate() {
      this.submitted = true;
      if (this.$v.$invalid || this.grade_id == '' || this.grade_id == 0 || this.academic_year == '' || this.academic_year == 0) {
        return;
      }
      this.update();
    },

    update() {
      this.disabled = true;
      var request = {
        grade_id: this.grade_id,
        academic_year: this.academic_year,
        section: this.classData.section,
        remark: this.classData.remark,
        updated_id: this.getUser.id,
        campus_id: this.getUser.campus_id
      }

      axios.post("/class/update/" + this.$route.params.id, request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data) {
            this.$notification.success("Class Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "classList"
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
          console.log("Class Edit API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/class/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getClassEdit();
    this.getAllGrade();
    this.getAcademicYear();
  },
};
