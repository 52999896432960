import MainLayout from "@/components/layout/admin/MainLayout.vue";
import VueSlickCarousel from 'vue-slick-carousel';
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "HomeIndex",
  components: {
    MainLayout,
    VueSlickCarousel,
    Loading
  },

  data() {
    return {
      setting: {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "arrows": false,
        "dots": true,
        "speed": 300,
        "infinite": true,
        "autoplaySpeed": 3000,
        "autoplay": true,
        "responsive": [{
            "breakpoint": 991,
            "settings": {
              "slidesToShow": 3,
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
            }
          }
        ]
      },

      settingStaff: {
        "slidesToShow": 5,
        "slidesToScroll": 1,
        "arrows": false,
        "dots": true,
        "speed": 300,
        "infinite": true,
        "autoplaySpeed": 3000,
        "autoplay": true,
        "responsive": [{
            "breakpoint": 991,
            "settings": {
              "slidesToShow": 3,
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
            }
          }
        ]
      },
      gradeArr: [],
      selectedGrade: 0,
      teacherList: {},
      studentList: {},
      payment: 0,
      income: 0,
      outcome: 0,
      balance: 0,
      slideCount: 0,
      noticeCount: 0,
      totalTeacherCount: 0,
      activeStudentCount: 0,
      leavedStudentCount: 0,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
      hasClass: false,
      isStaff: true,
    }
  },


  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getNoticecount () {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/notice/allNoticeCount", request)
        .then(response => {
          this.noticeCount = response.data;
        })
        .catch(error => {
          console.log("Notice Count API Error", error);
        });
    },

    getPaymentData() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/payment/totalPayment", request)
        .then((response) => {
          this.payment = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Payment API Error", error);
        });
    },

    getIncomeOutcomeData() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/inoutcome/info", request)
        .then((response) => {
          this.income = response.data.income;
          this.outcome = response.data.outcome;
          this.balance = this.income - this.outcome;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Income Outcome API Error", error);
        });
    },

    getLatestTeacherList() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/teacher/latestList", request)
        .then((response) => {
          this.teacherList = response.data;
          this.slideCount = this.teacherList.length;
          // for (let i = 0; i < this.teacherList.length; i++) {
          //   this.teacherList[i].class_list.length > 0 ? this.hasClass= true : null;
          // }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher List API Error", error);
        });
    },

    getTotalTeacherCount() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/teacher/getAllTeacherCount", request)
        .then((response) => {
          this.totalTeacherCount = response.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Count API Error", error);
        });
    },

    getTotalStudentCount() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/student/getAllStudentCount", request)
        .then((response) => {
          this.activeStudentCount = response.data.activeCount;
          this.leavedStudentCount = response.data.leavedCount;
        })
        .catch((error) => {
          if (error.response.status == 401) {
           this.$store.commit('logout');
           window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Count API Error", error);
        });
    },

    getLatestStudentList() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/student/latestList", request)
        .then(response => {
          this.studentList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student List API Error", error);
        });
    },

    showTeacherList() {
      this.$router.push({
        path: "/teacher/list",
      });
    },

    showStudentList() {
      this.$router.push({
        path: "/student/list"
      });
    },

    showTeacherProfile(id) {
      this.$router.push({
        name: "teacherProfile",
        params: {
          id: id
        }
      });
    },

    showStudentProfile(id, leavedStatus) {
      this.$router.push({
        name: "studentProfile",
        params: {
          id: id,
          leavedStatus: leavedStatus
        }
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getNoticecount();
    this.getPaymentData();
    this.getIncomeOutcomeData();
    this.getLatestTeacherList();
    this.getTotalTeacherCount();
    this.getTotalStudentCount();
    this.getLatestStudentList();
    this.getUser.role == 2 ? this.isStaff = true : this.isStaff = false;
  },
};
