<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
  @import '@/assets/css/style.css';
  @import '@/assets/fontawesome/css/all.min.css';
  @import '@/assets/fontawesome/css/fontawesome.css';
  @import '@/assets/fontawesome/css/brands.css';
  @import '@/assets/fontawesome/css/solid.css';
  @import 'https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;800&display=swap';
  @import '@/assets/slick/slick.css';
  @import '@/assets/slick/slick-theme.css';
  @import '@/assets/css/dashboard.css';
</style>

<script>
// import '@/assets/plugins/js/jquery.min.js';
// import '@/assets/plugins/js/slick.min.js';
// import '@/assets/js/slider.js';

// import '@/services/common.js';

export default {
  name: 'App',
  components: {
  }
}
</script>
