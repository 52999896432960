import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "noticeEdit",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      noticeData: [],
      classArr: [],
      allTea: '',
      submitted: false,
      isLoading: false,
      campusId: '',
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    noticeData: {
      title: {
        required,
        maxLength: maxLength(500)
      },
      message: {
        required,
        // maxLength: maxLength(1000)
      }
    }
  },

  methods: {
    getTeacher() {
      axios.get('teacher/getallTeacher',{ params: { campus_id: this.campusId } } )
      .then(res => {
        this.allTea = res.data[0];
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getClass() {
      axios.get('class/getGradeAndClass', { params: { campus_id: this.campusId } } )
      .then(res => {
        let $defaultSelectOption = [{
          'id': 0,
          'text': 'All'
        }];
        this.classArr = $defaultSelectOption.concat(res.data);
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      })
    },

    getDetail() {
      var request = {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      }
     axios.get(`notice/detail/${this.$route.params.id}`, request)
      .then(res => {
        if (res.data) {
          this.noticeData = res.data;
        }
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Notice Detail API Error", error);
      })
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      
      this.disabled = true;
      axios.post(`notice/update/${this.$route.params.id}`, { 
        title: this.noticeData.title, 
        message : this.noticeData.message, 
        campusId : this.campusId, 
        loginId: this.$store.state.user.id
      }) 
      .then(res => {
        if (res.data) {
          this.$notification.success("Notice Updated Successfully", {
            timer: 3,
            position: "bottomCenter"
          });
          this.$router.push({
            name: "noticeList"
          });
        }
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    }
  },

  mounted() {
    this.isLoading = true;
    this.campusId = this.$store.state.user.campus_id;
    this.getDetail();
    this.getClass();
    this.getTeacher();
  },
};
