<template>
  <footer class="footer">
      <a href="https://bib-mm.com/" class="color" target="_blank"> <p>Powered by BIB Co., Ltd.</p> </a>
  </footer>
</template>

<script>
export default {
  name: "FooterPage"
}
</script>
