import Vue from 'vue'
import App from './App.vue'
import './axios';
import router from './router/router.js'
import Vuelidate from 'vuelidate'
import VueNotification from '@kugatsu/vuenotification'
import store from './store'
import moment from 'moment'

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

Vue.config.productionTip = false
Vue.use(Vuelidate)

Vue.use(VueNotification, {
  success: {
    background: "green",
    color: "white"
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.filter('numFormat', function (value) {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return value;
  }
});

Vue.filter("nameFormat", function(value) {
  return value.length > 16 ? value.replace(/(<([^>]+)>)/gi, " ").substring(0, 16) + `...`: value;
});

Vue.filter("adminDisplayName", function(value) {
  return value.length > 16 ? value.replace(/(<([^>]+)>)/gi, " ").substring(0, 16) + `...`: value;
});

Vue.filter('yearPresent', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY')+ ' ~ ' + new Date().getFullYear();
  }
});

Vue.filter('concatSection', function (value) {
  return 'Section ' + (value.replace('Section','')).replace(' ','');
});

new Vue({
  store,
  router,
  $,
  render: h => h(App),
}).$mount('#app')
