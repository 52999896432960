import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "campusDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      campus: [],
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  mounted() {
    this.isLoading = true;
    let id = this.$route.params.id;
    var request = {
      params: {
        campus_id: this.$store.state.user.campus_id
      }
    }
    axios.get(`campus/detail/${id}`, request)
      .then(res => {
        if (res.data) {
          this.campus = res.data;
        }
          this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Campus Detail API Error", error);
      })
  },
};
