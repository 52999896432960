import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "subjectAssignDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      campusId: '',
      subjectTeacherInfo: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  methods: {
    getSubjectTeacherDetail () {
      var request = {
        params: {
          id: this.$route.params.id,
          campus_id: this.campusId
        }
      }
      axios.get("/subjectTeacher/detail", request)
        .then(response => {
          this.subjectTeacherInfo = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject Assign Detail API Error", error);
        });
    },

    showList() {
      this.$router.push({
        path: "/subjectAssign/list"
      });
    },
  },
  mounted() {
    this.campusId = this.$store.state.user.campus_id;
    this.isLoading = true;
    this.getSubjectTeacherDetail();
  },
};
