import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { maxLength, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "attendanceStudentCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      disabled: false,
      attendanceData: {},
      gradeAndClassArr: [],
      studentArr: [],
      selectedClass: 0,
      selectedStudent: 0,
      campusId:'',
      class_id:'',
      student:'',
      noStudentMsg:'',
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  validations: {
    attendanceData: {
      date: {
        required
      },
      status: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      }
    }
  },

  methods: {
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      if (fieldName == 'class_id') {
        // this.isLoading = true;
        if (val == 0) {
          this.selectedStudent = this.student = 0;
          this.noStudentMsg = '';
          this.isLoading = false;
        } else {
          this.getStudentByClass(val);
        }
      }
    },

    getStudentByClass(classId) {
      var request = {
        params: {
          class_id: classId,
          campus_id: this.getUser.campus_id
        }
      }

      axios.get("/student/getStudentByClass", request).then((response) => {
        if (response.data.length > 0) {
          this.studentArr = response.data;
          this.selectedStudent = this.student = this.studentArr[0].id;
          this.noStudentMsg = '';
          this.isLoading = false;
        } else {
          this.studentArr = [];
          this.selectedStudent = this.student = '';
          this.noStudentMsg = 'No student for this class.'
          this.isLoading = false;
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    getGradeAndClass() {
      axios.get('class/getGradeAndClass', { params: { campus_id: this.campusId } } )
      .then(response => {
        let $defaultSelectOption = [{
          'id': 0,
          'text': 'Select'
        }];
        this.gradeAndClassArr = $defaultSelectOption.concat(response.data);
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },

    confirmRegister() {
      this.submitted = true;
      if (this.$v.$invalid || this.class == '' || this.class == 0 || this.student == '' || this.student.length == 0) {
        return;
      }
      this.register();
    },

    register() {
      this.disabled = true;
      var request = {
        class_id: this.class_id,
        student_ids: this.student,
        date: this.attendanceData.date,
        status: this.attendanceData.status,
        remark: this.attendanceData.remark,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id
      }

      axios.post("/studentAttendance/create", request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Attendance for Student Added Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }          
          this.disabled = false;
          this.isLoading = false;
          console.log("Attendance for Student Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/attendanceStudent/list"
      });
    },
  },
  mounted() {
    this.campusId = this.$store.state.user.campus_id;
    this.isLoading = true;
    this.getGradeAndClass();
  },
};
