import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import { mapGetters } from "vuex";

export default {
  name: "attendanceTeacherList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading,
  },

  data() {
    return {
      name:'',
      status:'',
      start_date: '',
      end_date: '',
      attendanceStatusArr: null,
      attendanceTimeArr: null,
      selectedStatus: 2,
      attendanceList:{},
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      search_status: false,
      searchBtnStatus: false
    };
  },


  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getAttendanceStatus() {
      axios.get('getAttendanceStatus')
        .then(res => {
          this.attendanceStatusArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAttendanceTime() {
      axios.get('getAttendanceTime')
        .then(res => {
          this.attendanceTimeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      fieldName == 'status' && val == 2 ? this.$set(this, fieldName, '') : this.$set(this, fieldName, val);
    },

    getAttendanceList(page = 1) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/teacherAttendance/list?page=" + page , request)
        .then(response => {
          this.search_status = false;
          this.attendanceList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Attendance List API Error", error);
        });
    },

    search(page = 1) {
      this.searchBtnStatus = true;
      this.isLoading = true;
      var request = {
        params: {
          name: this.name,
          status: this.status,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/teacherAttendance/search/result?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.attendanceList = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Teacher Attendance Search API Error", error);
        });
    },

    reset() {
      this.name = '';
      this.selectedStatus = 2;
      this.status = '';
      this.start_date = '';
      this.end_date = '';
      this.academic_year = '';
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteClass(id);
      } else {
        console.log("no");
      }
    },

    deleteClass(id) {
      this.isLoading = true;
       var request = {
         params: {
           campus_id: this.getUser.campus_id
         }
       }
      axios.delete("/teacherAttendance/delete/" + id, request)
        .then(response => {
          if (response.data.success == true) {
            this.isLoading = true;
            this.getAttendanceList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance Delete Error->", error.response.data);
        });
    },

    showCreate() {
      this.$router.push({
        path: "/attendanceTeacher/create"
      });
    },

    showEdit(attendanceId) {
      this.$router.push({
        name: "attendanceTeacherEdit",
        params: {
          id: attendanceId
        }
      });
    },

    showDetail(attendanceId) {
      this.$router.push({
        name: "attendanceTeacherDetail",
        params: {
          id: attendanceId
        }
      });
    },

    excelDownload() {
      axios.get('/teacherAttendance/excelDownload', {
        params: {
          name: this.name,
          status: this.status,
          start_date: this.start_date,
          end_date: this.end_date,
          campus_id: this.getUser.campus_id,
          searchStatus: this.searchBtnStatus
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `teacher_attendance_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getAttendanceTime();
    this.getAttendanceStatus();
    this.getAttendanceList();
  },
};
