import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import store from '@/store'
export default {
  name: "inOutcomeList",
  components: {
    MainLayout,
    flatPickr,
    CONFIG,
    Select2Multiple,
    Loading,
    AdvancedLaravelVuePaginate,
  },

  data() {
    return {
      inData: {},
      outData: {},
      searchData: [],
      paymentMethodArr: null,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      start_date: '',
      end_date: '',
      status: '',
      academicYearArr: null,
      campusId: store.state.user.campus_id,
    };
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getin(page = 1) {
      axios.get('inoutcome/income/list?page=' + page, { params: { campus_id: this.campusId } })
        .then(res => {
          if (res.data) {
            this.inData = res.data;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getout(page = 1) {
      axios.get('inoutcome/outcome/list?page=' + page, { params: { campus_id: this.campusId } })
        .then(res => {
          if (res.data) {
            this.outData = res.data;
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id, status) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id, status);
      } else {
        console.log("no");
      }
    },
    delete(id, status) {
      this.isLoading = true;
      this.status = status;
      var request = {
        params: {
          status: this.status,
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.delete(`inoutcome/delete/${id}`, request)
        .then(res => {
          if (res.data)
            this.getin();
            this.getout();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = [];
    },

    search(page = 1) {
      this.isLoading = true;
      axios.get('inoutcome/search?page=' + page, {
        params: {
          title: this.searchData.title,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt,
          payMethod: this.searchData.payMethod,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          acaYear: this.searchData.acaYear,
          campus_id: this.campusId
        }
      })
        .then(res => {
          if (res.data) {
            this.inData = res.data.incomeResult;
            this.outData = res.data.outcomeResult;
          }
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    excelDownload() {
      axios.get('/inoutcome/excelDownload', {
        params: {
          title: this.searchData.title,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt,
          payMethod: this.searchData.payMethod,
          start_date: this.searchData.start_date,
          end_date: this.searchData.end_date,
          acaYear: this.searchData.acaYear,
          campus_id: this.campusId
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `inoutcome_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getin();
    this.getout();
    this.getPaymentMethod();
    this.getAcademicYear();
  },
};
