import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from "v-select2-multiple-component";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";

export default {
  name: "subjectAssignCreate",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      disabled: false,
      teacher: "",
      grade: "",
      section: "",
      subject: "",
      selectedTeacher: 0,
      selectedGrade: 0,
      selectedSection: 0,
      selectedSubject: "",
      teacherArr: [],
      gradeArr: [],
      sectionArr: [
        {
          id: 0,
          text: "Choose Section",
        },
      ],
      subjectArr: [],
      section_exist:''
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getAllTeacher() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/teacher/getallTeacher", request)
        .then((response) => {
          let $defaultSelectOption = [
            {
              id: 0,
              text: "Select Teacher Name",
            },
          ];
          this.teacherArr = $defaultSelectOption.concat(response.data[0]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    getAllGrade() {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/grade", request)
        .then((response) => {
          let $defaultSelectOption = [
            {
              id: 0,
              text: "Select",
            },
          ];
          this.gradeArr = $defaultSelectOption.concat(response.data[0]);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getSection($gradeId) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }

      axios
        .get("/class/getSection/" + $gradeId, request)
        .then((response) => {
          if (response.data[0].length > 0) {
            this.sectionArr = [
              {
                id: 0,
                text: "Choose Section",
              },
            ].concat(response.data[0]);
            this.selectedSection = this.section = 0;
          } else {
            this.sectionArr = [
              {
                id: 0,
                text: "Choose Section",
              },
            ];
            this.selectedSection = this.section = 0;
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Section API Error", error);
        });
    },

    getSubject($gradeId) {
      var request = {
        params: {
          campus_id: this.getUser.campus_id
        }
      }
      axios
        .get("/timetable/subjectBygrade/" + $gradeId, request)
        .then((response) => {
          this.subjectArr = response.data[0];
          this.subjectArr.length > 0
            ? (this.selectedSubject = this.subject = this.subjectArr[0].id)
            : null;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);

      if (fieldName == "grade") {
        // this.isLoading = true;
        this.getSection(val);
        this.getSubject(val);
      }
       if (fieldName == "section") {
         this.section_exist = '';
       }
    },

    confirmRegister() {
      this.submitted = true;
      if (
        this.teacher == 0 ||
        this.teacher == "" ||
        this.subject == 0 ||
        this.subject == "" ||
        this.section == 0 ||
        this.section == "" 
      ) {
        return;
      } else {
        this.register();
      }
    },

    register() {
      this.disabled = true;
      var request = {
        teacher: this.teacher,
        grade: this.grade,
        section: this.section,
        subject: this.subject,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id,
      };

      axios
        .post("/subjectTeacher/create", request, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.status == 204) {
            this.submitted = false;
            this.disabled = false;
            this.getSection(this.grade);
            this.section_exist = "Section doesn't exist. Please choose other section.";
            return;
          }
          if (response.data.success == true) {
            this.$notification.success("Subject Assign Added Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          console.log("Subject Assign Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/subjectAssign/list",
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAllTeacher();
    this.getAllGrade();
  },
};
