import Vue from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import axios from "axios";
import {
    required
} from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex";

export default {
    name: "GradeCreate",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            tmpResult: false,
            selectedGrade: 0,
            gradeArr: null,
            grade_id: '',
            old_grade_id: 0,
            inputData: {
                grade_id: 0,
                grade_name: ''
            },
            gradeInfo: [],
            hidden: false,
            firstname_ErrMsg: '',
            samecount: 0,
        };
    },

    validations: {
        inputData: {
            grade_id: {
                required
            },
        }
    },

    computed: {
        ...mapGetters(['getUser']),
    },

    methods: {
        getGradeName() {
            axios.get('getGradeName')
                .then(res => {
                    this.gradeArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        changeEvent(val, fieldName) {
            if (val == 0) {
                this.inputData.grade_id = '';
            }
            this.$set(this, fieldName, val);      
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        reset() {
            this.resetData();
        },

        showCreate() {
            this.$router.push({
                path: "/grade/create"
            });
        },

        resetEdit() {
            this.resetEditData();
            this.resetData();
            this.hidden = false;
        },

        resetEditData() {
            if (this.inputData.grade_id && this.inputData.grade_name) {
                this.gradeInfo.push(Vue.util.extend({}, this.inputData));
            }           
        },

        resetData() {
            this.inputData.grade_id = '';
            this.inputData.grade_name = '';
        },

        editClone(index) {
            this.hidden = true;
            this.inputData = this.gradeInfo[index];
            Vue.delete(this.gradeInfo, index);
        },

        removeClone(index) {
            Vue.delete(this.gradeInfo, index);
        },

        cloneForm() {
            this.tmpResult = true;
            if (this.inputData.grade_id == 0 || this.inputData.grade_id == '') {
                return;
            }
            this.firstname_ErrMsg = '';
            this.samecount = 0;
            this.gradeInfo.forEach((value) => {
                if (value.grade_id == this.inputData.grade_id) {                   
                    this.samecount++;
                }
            });

            if(this.samecount > 0){
                this.firstname_ErrMsg = 'Grade is already exist. Please choose another.';
                return;
            }

            if (this.gradeInfo.length < 13) {
                this.gradeArr.forEach((value) => {
                    if (value.id == this.inputData.grade_id) {
                        this.inputData.grade_name = value.text;
                    }
                });
                this.gradeInfo.push(Vue.util.extend({}, this.inputData));
                this.resetData();
                this.tmpResult = false;
            } else {
                alert('Grade can be created at most 13.');
            }
        },

        gradeCreate() {
            this.isLoading = true;
            this.firstname_ErrMsg = '';
            if (this.gradeInfo.length <= 0) {
                this.firstname_ErrMsg = 'Please Add Grade Data';
                this.isLoading = false;
                return;
            }
            axios.post('grade/create', {
                gradeInfo: this.gradeInfo,
                length: this.gradeInfo.length,
                login_id: store.state.user.id,
                campus_id: store.state.user.campus_id,
            })
            .then(res => {
                if (res.data.status == 200) {
                    this.$notification.success("Grade Added Successfully", {
                        timer: 3,
                        position: "bottomCenter"
                    });
                    this.$router.push({
                        name: 'gradeList'
                    })
                } else {
                    this.errors = res.data.errors;
                    this.isLoading = false;
                }
            })
            .catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            })
        }
    },
    mounted() {
        this.getGradeName();
    },
};
