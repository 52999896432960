import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";
import Loading from "vue-loading-overlay";
import Select2Multiple from 'v-select2-multiple-component';

export default {
  name: "RoutePointEdit",
  components: {
    MainLayout,
    Loading,
    Select2Multiple,
  },

  data() {
    return {
      routePointData: [],
      routeArr: [],
      submitted: false,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
    };
  },

  validations: {
    routePointData: {
      route_id: {
        required
      },
      latitude: {
        required,
        maxLength: maxLength(20)
      },
      longitude: {
        required,
        maxLength: maxLength(20)
      },
      address: {
        required,
        maxLength: maxLength(500)
      },
      status: {
        required
      },
    },
  },

  methods: {
    getRoute() {
      axios.get('route/getRouteForRoutePoint', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.routeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getDetail() {
      axios.get(`routePoint/detail/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.routePointData = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      this.disabled = true;
      this.routePointData.loginId = this.$store.state.user.id;
      axios.post(`routePoint/update/${this.$route.params.id}`, this.routePointData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Route Point Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "routePointList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getRoute();
    this.getDetail();
  },
};
