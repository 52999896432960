import { render, staticRenderFns } from "./RoutePointDetail.vue?vue&type=template&id=5b48b4f6&"
import script from "../../controllers/route_point/route-point-detail.js?vue&type=script&lang=js&"
export * from "../../controllers/route_point/route-point-detail.js?vue&type=script&lang=js&"
import style0 from "./RoutePointDetail.vue?vue&type=style&index=0&id=5b48b4f6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports