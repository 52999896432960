import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "RouteCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      routeData: {
        route_name: '',
        start_lat: '',
        start_long: '',
        start_address: '',
        end_lat: '',
        end_long: '',
        end_address: '',
        status: 0
      },
      submitted: false,
      disabled: false,
    };
  },

  validations: {
    routeData: {
      start_lat: {
        maxLength: maxLength(20)
      },
      start_long: {
        maxLength: maxLength(20)
      },
      start_address: {
        required,
        maxLength: maxLength(500)
      },
      end_lat: {
        maxLength: maxLength(20)
      },
      end_long: {
        maxLength: maxLength(20)
      },
      end_address: {
        required,
        maxLength: maxLength(500)
      },
      route_name: {
        required,
        maxLength: maxLength(100)
      }
    },
  },

  methods: {
    getEndAddress() {
      axios.get('route/getEndAddress', { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            let data = res.data[0];
            this.routeData.end_lat = data.latitude;
            this.routeData.end_long = data.longitude;
            this.routeData.end_address = data.address;
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.routeData.loginID = this.$store.state.user.id;
      this.routeData.campus_id = this.$store.state.user.campus_id;
      this.disabled = true;
      axios.post('route/create', this.routeData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Route Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: 'routeList' })
          }
          this.disabled = false;

        }).catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.getEndAddress();
  }
};
