import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";
import Loading from "vue-loading-overlay";

export default {
  name: "RouteEdit",
  components: {
    MainLayout,
    Loading,
  },

  data() {
    return {
      routeData: [],
      submitted: false,
      disabled: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
    };
  },

  validations: {
    routeData: {
      route_name: {
        required,
        maxLength: maxLength(100)
      },
      start_lat: {
        maxLength: maxLength(20)
      },
      start_long: {
        maxLength: maxLength(20)
      },
      start_address: {
        required,
        maxLength: maxLength(500)
      },
      end_lat: {
        maxLength: maxLength(20)
      },
      end_long: {
        maxLength: maxLength(20)
      },
      end_address: {
        required,
        maxLength: maxLength(500)
      },
      status: {
        required
      },
    },
  },

  methods: {
    getDetail() {
      axios.get(`route/detail/${this.$route.params.id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.routeData = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      this.disabled = true;
      this.routeData.loginId = this.$store.state.user.id;
      axios.post(`route/update/${this.$route.params.id}`, this.routeData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Route Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "routeList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getDetail();
  },
};
