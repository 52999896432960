import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
import store from '../../store';
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "timetableCreate",
  components: {
    MainLayout,
    Select2Multiple,
    Loading,
  },

  data() {
    return {
      submitted: false,
      selectedClass: 0,
      selectedYear: 0,
      msgCount: 0,
      invoices: {},
      isLoading: false,
      selectedSection: 0,
      count:0,
      academicYearArr: null,
      day: [
        'Mon','Tue','Wed','Thu','Fri'
      ],
      subjectCount: [
        1,2,3,4,5,6,7
      ],
      subjectCount1: [
        1, 2, 3, 4, 5, 6
      ],
      classArr: [],
      subjectArr: [],
      academic_year: 0,
      class_id: 0,
      grade_id: 0,
      class_name_required: '',
      academic_year_required: '',
      items: [
        [],
        [],
        [],
        [],
        [],
      ],
      firstname_ErrMsg: [
        [],
        [],
        [],
        [],
        [],
      ],
      section_exist: '',
    };
  },

  validations: {
    class_id: {
      required
    },
    academic_year: {
      required
    },
    selectedSubject01: {
      required
    },
    items: {
      $each: {
        0: {
          required
        },
        1: {
          required
        },
        2: {
          required
        },
        3: {
          required
        },
        4: {
          required
        },
        5: {
          required
        },
        6: {
          required
        }
      }
    }
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      this.class_name_required = '';
      this.academic_year_required = '';
      if (this.grade_id <= 3) {
        this.count = this.subjectCount1.length;
        this.items.forEach((value, index) => {
          this.items[index]['0'] = 0;
          this.items[index]['1'] = 0;
          this.items[index]['2'] = 0;
          this.items[index]['3'] = 0;
          this.items[index]['4'] = 0;
          this.items[index]['5'] = 0;
        });
      }  else {
        this.count = this.subjectCount.length;
        this.items.forEach((value, index) => {
          this.items[index]['0'] = 0;
          this.items[index]['1'] = 0;
          this.items[index]['2'] = 0;
          this.items[index]['3'] = 0;
          this.items[index]['4'] = 0;
          this.items[index]['5'] = 0;
          this.items[index]['6'] = 0;
        });
      }
      for (var i = 0; i < this.items.length; i++) {
        for (var j = 0; j < this.count; j++) {
          this.firstname_ErrMsg[i][j] = '';
        }
      }
      if (fieldName == "academic_year") {
        this.isLoading = true;
        this.subjectArr = [];
        this.classArr = [];
        this.class_id = 0;
        this.selectedClass = 0;
        this.getClass(val);
      }
      if (fieldName == "class_id") {
        this.section_exist = '';
        this.isLoading = true;
        this.subjectArr = [];
        this.getSubjectByClassId(val);
      }
    },

    changeEventSubject(val, fieldName, index, index1) {
       if (fieldName == 'Please select subject.' && val != null) {
          this.firstname_ErrMsg[index][index1] = '';     
       }
    },
    getClass() {
      var currentDate = new Date();
      const year = currentDate.getFullYear();
      currentDate.getMonth() < 3 ? this.academic_year = year - 1 : this.academic_year = year;
      axios.get("class/classList/" + this.academic_year, {
          params: {
            campus_id: store.state.user.campus_id
          }
        })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getSubjectByClassId($classId) {
       axios
         .get("/class/subjectByClassId/" + $classId, {
           params: {
             campus_id: store.state.user.campus_id
           }
         })         
         .then((response) => {
           let $defaultSelectOption = [{
             'id': 0,
             'text': 'Select Subject'
           }];
           this.subjectArr = $defaultSelectOption.concat(response.data.subjectArr[0]);
           this.grade_id = response.data.gradeId;
           this.isLoading = false;
         })
         .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
           this.isLoading = false;
           console.log("Subject API Error", error);
         });
     },

      confirmRegister() {
        this.isLoading = true;
        this.msgCount = 0;
        this.submitted = true;
        if (this.grade_id <= 3) {
          this.count = this.subjectCount1.length;
        } else {
          this.count = this.subjectCount.length;
        }
        if (this.class_id == '' || this.class_id == 0 || this.academic_year == '' || this.academic_year == 0) {
          for (var i = 0; i < this.items.length; i++) {
              for (var j = 0; j < this.count; j++) {
                if (this.items[i][j] == undefined || this.items[i][j] == 0) {
                  this.firstname_ErrMsg[i][j] = 'Please select subject.';
                  this.msgCount++;
                } else {
                  this.firstname_ErrMsg[i][j] = '';
                }
              }
          }
          if (this.class_id == '' || this.class_id == 0) {
            this.class_name_required = 'Class Name is required.';
          }
          if (this.academic_year == '' || this.academic_year == 0) {
            this.academic_year_required = 'Academic Year is required.';
          }
          
          if (this.msgCount > 0) {
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          return;
        } else {
          for (var m = 0; m < this.items.length; m++) {
            for (var n = 0; n < this.count; n++) {
              if (this.items[m][n] == undefined || this.items[m][n] == 0) {
                this.firstname_ErrMsg[m][n] = 'Please select subject.';
                this.msgCount++;
              }
              else {                  
                this.firstname_ErrMsg[m][n] = '';
              }
            }
          }
          if (this.msgCount > 0){
            this.isLoading = false;
            return;
          }
        }
        this.createTimeTable();
      },

      createTimeTable() {
        var request = {
          class_id: this.class_id,
          academic_year: this.academic_year,
          campus_id: this.getUser.campus_id,
          created_id: this.getUser.id,
          items: this.items,
        }

        axios.post("/timetable/create", request, {
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(response => {
            if (response.status == 204) {
              this.submitted = false;
              this.getClass();
              this.items.forEach((value, index) => {
                this.items[index]['0'] = 0;
                this.items[index]['1'] = 0;
                this.items[index]['2'] = 0;
                this.items[index]['3'] = 0;
                this.items[index]['4'] = 0;
                this.items[index]['5'] = 0;
              });
              this.section_exist = "Section doesn't exist. Please choose other section.";
              return;
            }
            if (response.data.status == 200) {
              this.$notification.success("Time Table Added Successfully", {
                timer: 3,
                position: "bottomCenter"
              });
              this.$router.push({
                name: "timetableList"
              });
            }
            if (response.data.status == 201) {
              this.$notification.error("Time Table Data Already Exist", {
                timer: 3,
                position: "bottomCenter"
              });
               this.isLoading = false;
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            console.log("Time Table Create API Error", error.errors);
          });
      },
  },
  mounted() {
    // this.isLoading = true;
    this.getClass();
    this.getAcademicYear();
  },
};
