import store from '@/store';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import Select2Multiple from 'v-select2-multiple-component';
import Footer from "@/components/layout/admin/FooterPage.vue";
import axios from 'axios';

export default {
  name: "login",

  components: {
    Footer,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      loginForm: {
        campus_id: 0,
        loginId: "",
        password: "",
      },
      campusName: [],
      submitted: false,
      validation_error: false,
      error: "",
      isLoading: false,
      disabled: false,
    };
  },

  validations: {
    loginForm: {
      campus_id: {
        required
      },
      loginId: {
        required
      },
      password: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(8)
      },
    },
  },

  methods: {
    campusNameAction() {
      axios.get('campus/loginCampus')
        .then(res => {
          this.isLoading = false;
          this.campusName = [{
            'id': 0,
            'text': "Select Campus"
          }].concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    idWithCampus() {
      if (this.loginForm.loginId == '0001') {
        this.loginForm.campus_id = 1;
      }
    },

    submitLogin() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      if (this.loginForm.loginId.startsWith('P')) {
        this.$notification.error("Parent can not login", {
          timer: 3,
          position: "bottomCenter"
        });
      } else if (this.loginForm.loginId.startsWith('T')) {
        this.$notification.error("Teacher can not login", {
          timer: 3,
          position: "bottomCenter"
        });
      } else {
        this.disabled = true;
        store.dispatch("login", this.loginForm)
          .then(() => {
            let adminNo = this.$store.state.user.admin_no;
            if (adminNo == '0001') {
              window.location.replace('/admin/list');
            } else {
              window.location.replace('/');
            }
          })
          .catch((error) => {
            // if (error.response.status == 401) {
            //   this.$store.commit('logout');
            //   window.location.replace('login');
            // }
            // this.isLoading = false;
            // this.disabled = false;
            // this.validation_error = true;
            // this.error = "Incorrect Login Number or Password";
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            this.disabled = false;
            this.validation_error = true;
            if (error.response.data.status == 400) {
              this.error = error.response.data.message;
            } else {
              this.error = "Incorrect Login Number or Password";
            }
          });
      }
    },
  },

  mounted() {
    this.isLoading = true;
    this.campusNameAction();
  },
};
