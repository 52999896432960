import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { required, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "attendanceTeacherList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      submitted: false,
      disabled: false,
      attendanceData: {},
      attendanceTimeArr: null,
      selectedTime: 0,
      time:'',
      times: '',
      noDataMsg: CONFIG.NO_DATA_EXIST,
      configDateTime: {
        enableTime: true,
        dateFormat: "Y-m-d H:i:S",  
        time_24hr: true,
        enableSeconds: true,
        minuteIncrement: 1,
        secondIncrement: 1,
        defaultHour: 8,
      },
    };
  },

  validations: {
    attendanceData: {
      date: {
        required
      },
      status: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      },
    }
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getAttendanceTime() {
      axios.get('getAttendanceTime')
        .then(res => {
          this.attendanceTimeArr = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    getAttendanceData () {
      var request = {
        params: {
          id: this.$route.params.id,
          campus_id: this.getUser.campus_id
        }
      }
      axios.get("/teacherAttendance/detail", request)
        .then(response => {
          this.attendanceData = response.data;
          this.selectedTime = this.times = this.attendanceData.times;
          this.attendanceData.date = this.attendanceData.date +' '+ this.attendanceData.time;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Attendance Teacher Detail API Error", error);
        });
    },

    confirmUpdate(){
      this.submitted = true;
      if (this.$v.$invalid || this.times == '' || this.times == 0) {
        return;
      }
      this.update();
    },

    update() {
      this.disabled = true;
      var request = {
        id: this.$route.params.id,
        teacher_id : this.attendanceData.teacher_id,
        date: this.attendanceData.date.split(' ')[0],
        time: this.attendanceData.date.split(' ')[1],
        times: this.times,
        status: this.attendanceData.status,
        remark: this.attendanceData.remark,
        campus_id: this.getUser.campus_id,
        created_id: this.getUser.id,
        updated_id: this.getUser.id
      }

      axios.post("/teacherAttendance/update", request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data.success == true) {
            this.$notification.success("Attendance Updated Successfully", {
              timer: 3,
              position: "bottomCenter",
            });
            this.showList();
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          console.log("Attendance Update API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/attendanceTeacher/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAttendanceTime();
    this.getAttendanceData();
  },
};
