import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength,
  integer
} from "vuelidate/lib/validators";
import upLoad from '@/assets/img/upload_photo3.jpg';
import axios from 'axios';
import store from "@/store";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "inOutcomeCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      acaYear: null,
      inOutData: {
        status: null,
        title: '',
        method: null,
        date: '',
        amount: '',
        ssImg: '',
        rorp: '',
        remark: '',
        loginID: '',
        campus_id: '',
        year: ''
      },
      academic_year: '',
      classArr: [],
      academicYearArr: null,
      paymentMethodArr: null,
      submitted: false,
      ssImage: '',
      upLoad,
      campusId: store.state.user.campus_id,
      disabled: false,
    };
  },

  validations: {
    inOutData: {
      status: {
        required
      },
      title: {
        required,
        maxLength: maxLength(100)
      },
      method: {
        required
      },
      date: {
        required
      },
      amount: {
        required,
        integer,
        maxLength: maxLength(9)
      },
      rorp: {
        required,
        maxLength: maxLength(100)
      },
      year: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      }
    },
    
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    removeImg() {
      this.ssImage = '';
      document.getElementById('image').value = '';
    },

    imgUpload(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return null;
      } else {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = (e) => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.ssImage = e.target.result;
            }
          }
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.inOutData.ssImg = this.ssImage;
      this.inOutData.loginID = this.$store.state.user.id;
      this.inOutData.campus_id = this.$store.state.user.campus_id;
      this.disabled = true;
      axios.post('inoutcome/create', this.inOutData)
      .then(res => {
        if (res.data) {
          this.$notification.success("Income/Outcome Added Successfully", {
            timer: 3,
            position: "bottomCenter"
          });
          this.$router.push({
            name: 'inOutcomeList'
          })
        }
      }).catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    }
  },

  mounted() {
    this.getPaymentMethod();
    this.getAcademicYear();
  }
};
