import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: "RoutePointCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
  },

  data() {
    return {
      routePointData: {
        route_id: null,
        latitude: '',
        longitude: '',
        address: '',
        status: 0
      },
      routeArr: [],
      submitted: false,
      disabled: false,
    };
  },

  validations: {
    routePointData: {
      route_id: {
        required
      },
      latitude: {
        required,
        maxLength: maxLength(20)
      },
      longitude: {
        required,
        maxLength: maxLength(20)
      },
      address: {
        required,
        maxLength: maxLength(500)
      },
    },
  },

  methods: {
    getRoute() {
      axios.get('route/getRouteForRoutePoint', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.routeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    create() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.routePointData.loginID = this.$store.state.user.id;
      this.routePointData.campus_id = this.$store.state.user.campus_id;
      this.disabled = true;
      axios.post('routePoint/create', this.routePointData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Route Point Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: 'routePointList' })
          }
          this.disabled = false;

        }).catch(error => {
          this.disabled = false;
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
     this.getRoute();
  }
};
